import {
  GET_ALL_CASHPOINTS_START,
  GET_ALL_CASHPOINTS_FAILURE,
  GET_ALL_CASHPOINTS_SUCCESS,
  SEARCH_CASHPOINT_OWNER_START,
  SEARCH_CASHPOINT_OWNER_SUCCESS,
  SEARCH_CASHPOINT_OWNER_FAILURE,
  SEARCH_CASHPOINT_OWNER_RESET,
} from "redux/actionTypes/cashpoints/index";
import { CashPointsInitialStateInterface } from "types/initialState";

const getCashpointsReducer = (
  state: CashPointsInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_ALL_CASHPOINTS_START:
      return {
        ...state,
        allCashPoints: { ...state.allCashPoints, loading: true },
      };

    case GET_ALL_CASHPOINTS_SUCCESS:
      return {
        ...state,
        allCashPoints: {
          ...state.allCashPoints,
          data: payload.merge
            ? state.allCashPoints.data.concat(payload.data)
            : payload.data,
          error: null,
          loading: false,
          metaData: {
            CurrentPage: payload.meta.CurrentPage,
            RecordsPerPage: payload.meta.RecordsPerPage,
            TotalPages: payload.meta.TotalPages,
            TotalRecords: payload.meta.TotalRecords,
          },
        },
      };

    case GET_ALL_CASHPOINTS_FAILURE:
      return {
        ...state,
        allCashPoints: {
          ...state.allCashPoints,
          loading: false,
          error: payload,
        },
      };

    case SEARCH_CASHPOINT_OWNER_START:
      return {
        ...state,
        searchCashpoints: { loading: true, data: [], error: null },
      };

    case SEARCH_CASHPOINT_OWNER_SUCCESS:
      return {
        ...state,
        searchCashpoints: { loading: false, data: payload, error: null },
      };

    case SEARCH_CASHPOINT_OWNER_FAILURE:
      return {
        ...state,
        searchCashpoints: { loading: false, data: [], error: payload },
      };

    case SEARCH_CASHPOINT_OWNER_RESET:
      return {
        ...state,
        searchCashpoints: { loading: false, data: [], error: null },
      };

    default:
      return null;
  }
};

export default getCashpointsReducer;
