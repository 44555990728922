import {
  FormattedNumberParts,
  FormattedNumber as FormattedNumberO,
} from "react-intl";
import { Typography } from "antd";

type FormattedNumberProps = React.ComponentProps<typeof FormattedNumberO>;

const FormattedNumber = (
  props: FormattedNumberProps & { textColor?: string }
) => {
  const { Text } = Typography;
  return (
    <FormattedNumberParts {...props}>
      {(parts) => (
        <Text style={{ color: props.textColor }}>
          {parts.map(({ type, value }) => {
            if (type === "currency" && /fcfa/gi.test(value)) {
              return "XAF";
            }
            return value;
          })}
        </Text>
      )}
    </FormattedNumberParts>
  );
};

export default FormattedNumber;
