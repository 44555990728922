export const ADD_PARTNER_START = 'ADD_PARTNER_START';
export const ADD_PARTNER_SUCCESS = 'ADD_PARTNER_SUCCESS';
export const ADD_PARTNER_FAILURE = 'ADD_PARTNER_FAILURE';
export const RESTORE_ADD_NEW_PARTNER = 'RESTORE_ADD_NEW_PARTNER';

export const GET_PARTNER_LIST_START = 'GET_PARTNER_LIST_START';
export const GET_PARTNER_LIST_SUCCESS = 'GET_PARTNER_LIST_SUCCESS';
export const GET_PARTNER_LIST_FAILURE = 'GET_PARTNER_LIST_FAILURE';

export const GET_TRANSFER_FEES_START = 'GET_TRANSFER_FEES_START';
export const GET_TRANSFER_FEES_SUCCESS = 'GET_TRANSFER_FEES_SUCCESS';
export const GET_TRANSFER_FEES_FAILURE = 'GET_TRANSFER_FEES_FAILURE';

export const UPDATE_TRANSFER_FEES_START = 'UPDATE_TRANSFER_FEES_START';
export const UPDATE_TRANSFER_FEES_SUCCESS = 'UPDATE_TRANSFER_FEES_SUCCESS';
export const UPDATE_TRANSFER_FEES_FAILURE = 'UPDATE_TRANSFER_FEES_FAILURE';

export const UPDATE_PARTNER_START = 'UPDATE_PARTNER_START';
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS';
export const UPDATE_PARTNER_FAILURE = 'UPDATE_PARTNER_FAILURE';
export const RESTORE_UPDATE_PARTNER = 'RESTORE_UPDATE_PARTNER';

export const REGISTER_AS_TWO_WAY_PARTNER_START =
  'REGISTER_AS_TWO_WAY_PARTNER_START';
export const REGISTER_AS_TWO_WAY_PARTNER_SUCCESS =
  'REGISTER_AS_TWO_WAY_PARTNER_SUCCESS';
export const REGISTER_AS_TWO_WAY_PARTNER_FAILURE =
  'REGISTER_AS_TWO_WAY_PARTNER_FAILURE';
