import {
  GET_TRANSFER_FEES_START,
  GET_TRANSFER_FEES_FAILURE,
  GET_TRANSFER_FEES_SUCCESS,
} from 'redux/actionTypes/finances/partnerTypes';
import FinancesInitialStateInterface from 'types/finances/financialInitialState';

const allPartnersReducer = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_TRANSFER_FEES_START:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          loading: true,
          error: null,
        },
      };

    case GET_TRANSFER_FEES_SUCCESS:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          loading: false,
          error: null,
          data: payload?.Data,
          success: true,
        },
      };

    case GET_TRANSFER_FEES_FAILURE:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          loading: false,
          error: payload,
          success: false,
        },
      };

    default:
      return null;
  }
};

export default allPartnersReducer;
