import {
	SELECT_USER_SUCCESS,
	RESET_SELECTED_USER,
} from 'redux/actionTypes/customers/selectUserActionTypes'
import {
	SEARCH_START,
	SEARCH_SUCCESS,
	SEARCH_FAILURE,
	SEARCH_END,
	GET_USER_START,
	GET_USER_FAILURE,
	GET_USER_SUCCESS,
} from 'redux/actionTypes/users/userActionTypes'
import initialState from '../../initialState'
import CustomersInitialStateInterface from 'types/Customer/CustomerInitialState'

const searchReducer = (
	state: CustomersInitialStateInterface,
	{ type, payload }: any,
) => {
	switch (type) {
		case SELECT_USER_SUCCESS:
			return {
				...state,
				selectedCustomer: {
					...state.selectedCustomer,
					data: state.search.results.Data.find(
						(customer) => customer.PID === payload.PID,
					),
					userPID: payload.PID,
				},
			}

		case RESET_SELECTED_USER:
			return {
				...state,
				selectedCustomer: {
					...initialState.customers.selectedCustomer,
				},
			}

		case SEARCH_START:
			return {
				...state,
				search: { ...state.search, loading: true, searchData: payload },
			}

		case SEARCH_SUCCESS:
			return {
				...state,
				search: {
					...state.search,
					loading: false,
					results: payload,
					fetchOnSelect: payload && (payload as any[]).length > 1,
				},
			}

		case SEARCH_FAILURE:
			return {
				...state,
				search: {
					...state.search,
					results: { Data: [], Meta: { CurrentPage: '1', RecordPerPage: '8' } },
					loading: false,
					error: payload,
				},
			}

		case SEARCH_END:
			return {
				...state,
				search: {
					...state.search,
					loading: false,
				},
			}

		case GET_USER_START:
			return {
				...state,
				selectedCustomer: {
					...state.selectedCustomer,
					loading: true,
				},
			}
		case GET_USER_SUCCESS:
			return {
				...state,
				selectedCustomer: {
					...state.selectedCustomer,
					data: payload.Data[0],
					loading: false,
				},
			}
		case GET_USER_FAILURE:
			return {
				...state,
				selectedCustomer: {
					...state.selectedCustomer,
					loading: false,
					error: payload,
				},
			}
		default:
			return null
	}
}

export default searchReducer
