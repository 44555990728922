import {
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  RESET_ADD_NEW_USER_START,
} from 'redux/actionTypes/users/userActionTypes';
import UsersInitialStateInterface from 'types/users/userInitialState';

const createUserReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case RESET_ADD_NEW_USER_START:
      return {
        ...state,
        newUser: {
          data: null,
          loading: false,
          error: null,
        },
      };
    
    case CREATE_USER_START:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          loading: true,
          error: null,
        },
      };

    case CREATE_USER_SUCCESS: {
      const users = state.allUsers.data;
      return {
        ...state,
        newUser: {
          data: payload,
          loading: false,
          error: null,
        },
        allUsers: {
          ...state.allUsers,
          data: [payload].concat(users),
        },
      };
    }

    case CREATE_USER_FAILURE:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          loading: false,
          error: payload,
        },
      };

    default:
      return null;
  }
};

export default createUserReducer;
