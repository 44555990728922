import React from "react";
import { Layout } from "antd";
import { Helmet } from "react-helmet";
import NavBarComponent from "components/NavBar";
import "./index.scss";

/**A high order component that wraps the page to add styling */
const PageHOCComponent: React.FC<{
  /**title displayed in the browser*/
  title?: string;
  /**title displayed on the page, top navbar*/
  displayTitle?: string;
  fixedNavbar?: boolean;
  onBack?: () => void;
  titleClassName?: string;
  pageClassName?: string;
  children: React.ReactNode;
}> = ({
  title,
  children,
  displayTitle,
  pageClassName,
  titleClassName,
  fixedNavbar = false,
  onBack,
}) => {
  const appName = process.env.REACT_APP_NAME;
  return (
    <Layout className={`page-wrapper ${pageClassName ? pageClassName : ""}`}>
      <Helmet title={`${title} | ${appName || ""}`} />
      <NavBarComponent
        onBack={onBack}
        title={displayTitle}
        fixedNavbar={fixedNavbar}
        className={`${pageClassName ? pageClassName + "__title" : ""}`}
      />
      {children}
    </Layout>
  );
};

export default PageHOCComponent;
