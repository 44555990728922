import {
  SET_SELECTED_OPS_COUNTRY,
  SET_CURRENT_USER_FROM_LOCAL_STORAGE,
} from "redux/actionTypes/users/getUserData";

export const setCurrentUserAction = (data: Record<string, unknown>) => (
  dispatchAction: React.Dispatch<Record<string, unknown>>
): void => {
  dispatchAction({
    type: SET_CURRENT_USER_FROM_LOCAL_STORAGE,
    payload: { ...data },
  });
};

export const setSelectedOpsCountryAction = (countryCode: string) => (
  dispatchAction: React.Dispatch<Record<string, unknown>>
): void =>
  dispatchAction({
    type: SET_SELECTED_OPS_COUNTRY,
    payload: countryCode,
  });
