import {
  UPDATE_USER_DATA_START,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAILURE,
} from "redux/actionTypes/users/updateData";
import { IntlShape } from "react-intl";
import apiAction from "utils/apiAction";
import NotificationTrigger from "components/Alert/Notification";

const saveUserDataAction = (
  formData: Record<string, unknown>,
  intl: IntlShape,
  actionType: String
) => {
  return (dispatchAction: any) => {
    dispatchAction(
      apiAction({
        method: "POST",
        url: "UpdateBOUserData",
        data: formData,
        onStart: () => (dispatch) => {
          return dispatch({
            type: UPDATE_USER_DATA_START,
          });
        },
        onSuccess: (_data) => (dispatch) => {
          let userData = JSON.parse(localStorage.getItem("user")!);
          userData = { ...userData, ...formData };
          localStorage.setItem("user", JSON.stringify(userData));

          if (actionType !== "update-language") {
            NotificationTrigger.success({
              message: intl?.formatMessage({
                id: "3538",
                defaultMessage: `Account data updated`,
              }),
              description: intl?.formatMessage({
                id: "3537",
                defaultMessage: `Your account details has been updated successfully`,
              }),
            });
          }

          dispatch({
            type: UPDATE_USER_DATA_SUCCESS,
            payload: userData,
          });
        },
        onFailure: (err) => (dispatch) => {
          const error = err || {};
          dispatch({
            type: UPDATE_USER_DATA_FAILURE,
            payload: error,
          });
          NotificationTrigger.error({
            message: intl.formatMessage({
              id: "3536",
              defaultMessage: "Failed to update your account data",
            }),
            description: error.Description || "Check details and try again",
          });
        },
      })
    );
  };
};

export default saveUserDataAction;
