import cashpointReducer from "./cashpointReducer";
import getCashpointsReducer from "./getCashpointsList";
import createCashpointReducer from "./createCashpointReducer";
import { CashPointsInitialStateInterface } from "types/initialState";
import cashPointDepositReducer from "./cashPointDepositReducer";
import addTellerReducer from "./addTellerReducer";
import getTellersReducer from "./getTellersReducer";
import addCashPintOfficeReducer from "./addCashPointOfficeReducer";

const cashPointsReducer = (
  state: CashPointsInitialStateInterface,
  action: any
) => {
  return {
    ...state,
    ...cashpointReducer(state, action),
    ...getCashpointsReducer(state, action),
    ...createCashpointReducer(state, action),
    ...cashPointDepositReducer(state, action),
    ...addTellerReducer(state, action),
    ...getTellersReducer(state, action),
    ...addCashPintOfficeReducer(state, action),
  };
};

export default cashPointsReducer;
