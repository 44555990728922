import {
  SET_WALLET_AUTO_PAY_STATUS_SUCCESS,
  SET_WALLET_AUTO_PAY_STATUS_FAILURE,
  SET_WALLET_AUTO_PAY_STATUS_START,
} from "redux/actionTypes/merchant";

const setWalletAutoPayStatusReducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case SET_WALLET_AUTO_PAY_STATUS_START:
      return {
        ...state,
        setWalletAutoPayStatus: { loading: true, data: null, error: null },
      };

    case SET_WALLET_AUTO_PAY_STATUS_SUCCESS: {
      return {
        ...state,
        setWalletAutoPayStatus: { loading: false, data: payload, error: null },
      };
    }

    case SET_WALLET_AUTO_PAY_STATUS_FAILURE:
      return {
        ...state,
        merchantList: { ...state.merchantList },
        setWalletAutoPayStatus: { loading: false, data: null, error: null },
      };

    default:
      return null;
  }
};

export default setWalletAutoPayStatusReducer;
