import IRealTimeServices from "types/IRealTimeServices";
import ActionType from "types/ActionType";
import {
  GET_CHAT_THREADS,
  GET_CHAT_THREADS_SUCCESS,
  DELETE_CHAT_THREAD_SUCCESS,
} from "../../actionTypes/real-time-services";

export default function chatThreadReducer(
  state: IRealTimeServices,
  { type, payload }: ActionType
): { [key: string]: any } | null {
  switch (type) {
    case GET_CHAT_THREADS:
      return {
        ...state,
        chat: {
          ...state.chat,
          threads: {
            ...state.chat.threads,
            meta: {},
            error: null,
            loading: true,
            success: false,
          },
        },
      };
    case GET_CHAT_THREADS_SUCCESS:
      return {
        ...state,
        chat: {
          ...state.chat,
          threads: {
            ...state.chat.threads,
            data: payload?.data || state.chat.threads.data,
            meta: payload?.meta || state.chat.threads.meta,
            error: null,
            loading: false,
            success: true,
          },
        },
      };
    case DELETE_CHAT_THREAD_SUCCESS:
      return {
        ...state,
        chat: {
          ...state.chat,
          threads: {
            ...state.chat.threads,
            data:
              state.chat.threads?.data?.filter(
                (thread: Record<string, number | string>) =>
                  Number(thread.id) !== Number(payload?.id)
              ) || [],
          },
          directMessages: {
            ...state.chat.directMessages,
            data: {
              ...state.chat.directMessages.data,
              [payload?.thread]: undefined,
            },
          },
        },
      };
    default:
      return null;
  }
}
