import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REDIRECT_TO_RESET,
  RESET_ERROR,
} from "redux/actionTypes/users/login";
import { PeerApprovalStatus } from "types/general.d";
import UsersInitialStateInterface from "types/users/userInitialState";
import { SET_CURRENT_USER_FROM_LOCAL_STORAGE } from "redux/actionTypes/users/getUserData";

const loginReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case LOGIN_START:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: undefined,
        },
        login: {
          ...state.login,
          loading: true,
          error: null,
          success: false,
        },
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          error: payload,
          loading: false,
          success: false,
        },
      };

    case REDIRECT_TO_RESET:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: payload,
        },
        login: {
          ...state.login,
          error: null,
          loading: false,
        },
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: payload,
          isLoggedIn: true,
          selectedOpsCountry: payload.CountryCode?.toUpperCase(),
          isAuth: payload.CanSignAlone.toLowerCase() === "yes",
          approvalStatus:
            payload.CanSignAlone.toLowerCase() === "yes"
              ? PeerApprovalStatus.APPROVAL_NONE
              : PeerApprovalStatus.APPROVAL_WAITING,
        },
        login: {
          ...state.login,
          error: null,
          loading: false,
          success: true,
        },
      };

    case RESET_ERROR:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          error: null,
          success: false,
        },
      };

    case SET_CURRENT_USER_FROM_LOCAL_STORAGE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isAuth: true,
          data: payload,
          isLoggedIn: true,
          selectedOpsCountry: payload.CountryCode?.toUpperCase(),
          // preferredLanguageCode: payload.Language
        },
      };

    default:
      return null;
  }
};

export default loginReducer;
