import FinancesInitialStateInterface from 'types/finances/financialInitialState';
import {
  UPDATE_OSSIX_BALANCE_START,
  UPDATE_OSSIX_BALANCE_SUCCESS,
  UPDATE_OSSIX_BALANCE_FAILURE,
} from 'redux/actionTypes/finances';

const updateOssixBalanceReducer = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case UPDATE_OSSIX_BALANCE_START:
      return {
        ...state,
        updateOssixBalance: {
          ...state.updateOssixBalance,
          loading: true,
          error: null,
        },
      };
    case UPDATE_OSSIX_BALANCE_FAILURE:
      return {
        ...state,
        updateOssixBalance: {
          ...state.updateOssixBalance,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case UPDATE_OSSIX_BALANCE_SUCCESS:
      let newPartnersData = state.partners.data;

      newPartnersData = state.partners.data.map((partner) => {
        if (partner.LoginName === payload.PartnerLoginName) {
          const OssixBalance =
            payload.OperationType === '+'
              ? Number(partner.OssixBalance) + Number(payload.Amount)
              : Number(partner.OssixBalance) - Number(payload.Amount);
          return {
            ...partner,
            OssixBalance: OssixBalance.toString(),
          };
        }
        return partner;
      });

      return {
        ...state,
        updateOssixBalance: {
          ...state.updateOssixBalance,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
        partners: {
          ...state.partners,
          data: newPartnersData,
        },
      };
    default:
      return null;
  }
};

export default updateOssixBalanceReducer;
