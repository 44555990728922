import { combineReducers } from "redux";
import usersReducer from "./users";
import eventsReducer from "./events";
import financesReducer from "./finances";
import customersReducer from "./customers";
import dashboardReducer from "./dashboard";
import contentReview from "./contentReview";
import cashPointsReducer from "./cashpoints";
import globalSettingsReducer from "./globalSettings";
import InitialStateInterface from "types/initialState";
import transactionsReportReducer from "./transactionsReport";
import transactionsForExportReducer from "./transactionsForExport";
import appStateReducer from "./app";
import sendEmail from "./email/sendEmail";
import bulkOrganizationReducer from "./organization";
import bankAccountsReducer from "./bankAccounts";
import operatorReducer from "./operators";
import merchantReducer from "./merchants";
import realTimeServicesReducer from "./realTimeServices";
import walletsWithRecentOpsReducer from "./walletsWithRecentOps";

// @ts-expect-error //TODO: Fix combine reducers types error
const rootReducer = combineReducers<InitialStateInterface>({
  app: appStateReducer,
  users: usersReducer,
  events: eventsReducer,
  finances: financesReducer,
  customers: customersReducer,
  dashboard: dashboardReducer,
  contentReview: contentReview,
  cashPoints: cashPointsReducer,
  globalSettings: globalSettingsReducer,
  transactionsReport: transactionsReportReducer,
  transactionsForExport: transactionsForExportReducer,
  sendEmail: sendEmail,
  bulkOrganization: bulkOrganizationReducer,
  bankAccounts: bankAccountsReducer,
  operators: operatorReducer,
  merchants: merchantReducer,
  realTimeServices: realTimeServicesReducer,
  walletsWithRecentOps: walletsWithRecentOpsReducer,
});

export default rootReducer;
