import RoutesComponent from "./Routes";
import { DndProvider } from "react-dnd";
import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { HTML5Backend } from "react-dnd-html5-backend";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import InitialStateInterface from "./types/initialState";
import getLanguage from "./redux/actions/users/getLanguage";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { InitializeAppAction } from "redux/actions/users/initializeAppAction";
import useChatThread from "services/socketIO/chats/chatThread";
import directMessage from "services/socketIO/chats/directMessage";

import "assets/styles/global.scss";
import {
  getDashboardKPIDataAction,
  getDashboardKPIGraphDataAction,
} from "redux/actions/dashboard";

const typedUseSelector: TypedUseSelectorHook<InitialStateInterface> =
  useSelector;

const queryClient = new QueryClient();

function App(): React.ReactElement {
  const dispatch = useDispatch();
  useChatThread();
  directMessage();
  const {
    currentUser: { preferredLanguageCode, selectedOpsCountry },
    language: { data: locale },
  } = typedUseSelector(({ users }) => ({
    currentUser: users.currentUser,
    language: users.language,
  }));

  const localesData: { [key: string]: Record<string, string> } = {};
  localesData[preferredLanguageCode] = locale;

  useEffect(() => {
    getLanguage(preferredLanguageCode)(dispatch);
  }, [dispatch, preferredLanguageCode]);
  useEffect(() => {
    if (selectedOpsCountry) {
      getDashboardKPIDataAction(selectedOpsCountry)(dispatch);
    }
  }, [dispatch, selectedOpsCountry]);

  useEffect(() => {
    InitializeAppAction()(dispatch);
  }, [dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider
        defaultLocale="en"
        locale={preferredLanguageCode}
        messages={localesData[preferredLanguageCode]}
      >
        <DndProvider backend={HTML5Backend}>
          <RoutesComponent />
        </DndProvider>
      </IntlProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
