import FinancesInitialStateInterface from 'types/finances/financialInitialState';
import {
  REGISTER_AS_TWO_WAY_PARTNER_START,
  REGISTER_AS_TWO_WAY_PARTNER_SUCCESS,
  REGISTER_AS_TWO_WAY_PARTNER_FAILURE,
} from 'redux/actionTypes/finances/partnerTypes';

const registerAsTwoWayPartnerReducer = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case REGISTER_AS_TWO_WAY_PARTNER_START:
      return {
        ...state,
        registerAsTwoWayPartner: {
          ...state.registerAsTwoWayPartner,
          loading: true,
          error: null,
        },
      };
    case REGISTER_AS_TWO_WAY_PARTNER_FAILURE:
      return {
        ...state,
        registerAsTwoWayPartner: {
          ...state.registerAsTwoWayPartner,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case REGISTER_AS_TWO_WAY_PARTNER_SUCCESS:
      const newPartnersData = state.partners.data.map((partner) => {
        if (partner.APIKey === payload.APIKey) {
          return {
            ...partner,
            TwoWayPartner: 'YES',
            TwoWayData: {
              ...partner.TwoWayData,
              AppID: payload.AppID,
            },
          };
        }
        return partner;
      });
      return {
        ...state,
        registerAsTwoWayPartner: {
          ...state.registerAsTwoWayPartner,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
        partners: {
          ...state.partners,
          data: newPartnersData,
        },
      };
    default:
      return null;
  }
};

export default registerAsTwoWayPartnerReducer;
