import getOperators from "./operators";
import addUpdateOperators from './addUpdateOperator'
import variousList from './variousList'

const operatorReducer = (
    state: any,
    action: any
  ) => {
    return {
      ...state,
      ...getOperators(state, action),
      ...addUpdateOperators(state, action),
      ...variousList(state, action),
    };
  };
  
  export default operatorReducer;