import {
  GET_NEW_POST_FAILURE,
  GET_NEW_POST_START,
  GET_NEW_POST_SUCCESS,
  INITIATE_NEW_POSTS,
  UPDATE_PS_FAILURE,
  UPDATE_PS_START,
  UPDATE_PS_SUCCESS,
} from 'redux/actionTypes/contentReview';
import ContentReviewInterface from 'types/contentReview/index.d';
import { ServiceData } from 'types/contentReview/newPost';

const removeServiceFormList = (
  servicesList: Array<ServiceData>,
  service: any
) => {
  return servicesList.filter((item: ServiceData) => {
    return item.ServiceID !== service;
  });
};

const newPostReducer = (
  state: ContentReviewInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_NEW_POST_START:
      return {
        ...state,
        newPosts: {
          ...state.newPosts,
          loading: true,
          error: null,
        },
      };
    case INITIATE_NEW_POSTS:
      return {
        ...state,
        newPosts: {
          data: {
            Services: [],
            Comments: [],
          },
        },
      };
    case GET_NEW_POST_SUCCESS:
      return {
        ...state,
        newPosts: {
          ...state.newPosts,
          data: {
            ...state.newPosts.data,
            Services: state?.newPosts?.data?.Services
              ? [payload.Data[0]].concat(state.newPosts?.data?.Services)
              : payload.Data,
          },
          loading: false,
        },
      };
    case GET_NEW_POST_FAILURE:
      return {
        ...state,
        newPosts: {
          ...state.newPosts,
          error: payload,
          loading: false,
        },
      };
    case UPDATE_PS_START:
      return {
        ...state,
        newPosts: {
          ...state.newPosts,
          error: null,
          loading: true,
        },
      };
    case UPDATE_PS_SUCCESS:
      return {
        ...state,
        newPosts: {
          data: {
            ...state.newPosts.data,
            Services: removeServiceFormList(
              state.newPosts.data.Services,
              payload.ServiceID
            ),
          },
        },
        error: null,
        loading: false,
      };
    case UPDATE_PS_FAILURE:
      return {
        ...state,
        newPosts: {
          ...state.newPosts,
          error: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};

export default newPostReducer;
