import FinancesInitialStateInterface from 'types/finances/financialInitialState';
import {
  UPDATE_PARAMS_FINANCE_CONFIG_START,
  UPDATE_PARAMS_FINANCE_CONFIG_SUCCESS,
  UPDATE_PARAMS_FINANCE_CONFIG_FAILURE,
} from 'redux/actionTypes/finances';

const updateParamsFinanceConfig = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case UPDATE_PARAMS_FINANCE_CONFIG_START:
      return {
        ...state,
        updateParamsFinanceConfig: {
          ...state.updateParamsFinanceConfig,
          loading: true,
          error: null,
        },
      };
    case UPDATE_PARAMS_FINANCE_CONFIG_FAILURE:
      return {
        ...state,
        updateParamsFinanceConfig: {
          ...state.updateParamsFinanceConfig,
          error: payload,
          loading: false,
        },
      };
    case UPDATE_PARAMS_FINANCE_CONFIG_SUCCESS:
      return {
        ...state,
        updateParamsFinanceConfig: {
          ...state.updateParamsFinanceConfig,
          data: payload,
          error: null,
          loading: false,
        },
      };
    default:
      return null;
  }
};

export default updateParamsFinanceConfig;
