

const operatorState = {
    operators: {
      data: null,
      loading: false,
      error: null,
    },
    addUpdateOperator: {
      data: null,
      loading: false,
      error: null,
    },
    variousList: {
      data: null,
      loading: false,
      error: null,
    },
  };
  
export default operatorState;