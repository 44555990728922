import React from "react";
import { PREFERRED_LANGUAGE } from "constants/general";
import { SET_LANGUAGE } from "redux/actionTypes/users/language";

const setLanguageAction = (language: { key: string; text: string }) => {
  return (dispatch: React.Dispatch<Record<string, unknown>>): void => {
    localStorage.setItem(
      PREFERRED_LANGUAGE,
      JSON.stringify({ language: language.text, code: language.key.trim() })
    );
    return dispatch({
      type: SET_LANGUAGE,
      payload: { language: language.text, code: language.key.trim() },
    });
  };
};

export default setLanguageAction;
