import {
  GET_USER_DATA_START,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
} from "redux/actionTypes/users/getUserData";
import UsersInitialStateInterface from "types/users/userInitialState";

const getUserData = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_USER_DATA_START:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          loading: true,
          error: null,
        },
      };

    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          error: null,
          data: payload,
          loading: false,
          selectedOpsCountry: payload.CountryCode?.toUpperCase(),
        },
      };

    case GET_USER_DATA_FAILURE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: [],
          loading: false,
          error: payload,
        },
      };

    default:
      return null;
  }
};

export default getUserData;
