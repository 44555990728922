import getWalletsWithRecentOpsToExport from "./getWalletsRecentOpsToExport";
import getWalletsRecentOpsReducer from "./getWalletsRecentOpsReducer";
import WalletsRecentOpsInterface from "types/finances/walletsRecentOps";

const walletsRecentOpsForExportReducer = (
  state: WalletsRecentOpsInterface,
  action: any
) => {
  return {
    ...state,
    ...getWalletsWithRecentOpsToExport(state, action),
    ...getWalletsRecentOpsReducer(state, action),
  };
};

export default walletsRecentOpsForExportReducer;
