const globalParametersInitialState = {
  globalSettings: {
    data: null,
    error: null,
    loading: false,
  },
  setGlobalSettings: {
    data: null,
    error: null,
    loading: false,
  },
  globalBuiltinConfigData: {
    data: null,
    error: null,
    loading: false,
  },
};

export default globalParametersInitialState;
