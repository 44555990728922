import { useIntl } from "react-intl";
import { useState } from "react";
import { MIN_OTP_LENGTH } from "constants/general";
import NotificationTrigger from "components/Alert/Notification";
import { Popconfirm, Input, Button, ButtonProps, Typography } from "antd";
import "./index.scss";

const { Text } = Typography;

interface PopConfirmButtonProps extends ButtonProps {
  label?: string;
  pinMode?: boolean;
  buttonClassName?: string;
  onConfirm: (OTP: string) => void;
  onValidateFields?: (arg: any) => void | any;
}

const PopConfirmButton = (props: PopConfirmButtonProps) => {
  const intl = useIntl();
  const [OTP, setOTP] = useState<string | undefined>(undefined);
  const {
    disabled,
    title,
    buttonClassName,
    pinMode = false,
    onConfirm,
    onValidateFields,
    ...restProps
  } = props;

  const onVerify = () => {
    if (!OTP || OTP.length < MIN_OTP_LENGTH) {
      NotificationTrigger.error({
        message: intl.formatMessage({
          id: "2771",
          defaultMessage: "OTP not provided or incorrect",
        }),
      });
    } else {
      onConfirm(OTP);
      setOTP(undefined);
    }
  };

  return (
    <Popconfirm
      disabled={disabled}
      onConfirm={onVerify}
      onCancel={() => setOTP(undefined)}
      okButtonProps={{ size: "middle", danger: restProps.danger }}
      cancelButtonProps={{ size: "middle" }}
      title={
        <div className="pop-confirm__body">
          <Text>
            {pinMode
              ? intl.formatMessage({
                  id: "2772.new",
                  defaultMessage: "Provide your PIN to confirm",
                })
              : title ||
                intl.formatMessage({
                  id: "2772",
                  defaultMessage: "Provide your OTP code to confirm",
                })}
          </Text>
          <Input
            value={OTP}
            maxLength={pinMode ? 4 : 6}
            autoFocus={true}
            autoComplete="off"
            placeholder={pinMode ? "PIN" : "OTP code"}
            className="pop-confirm__input"
            onChange={({ target: { value } }) => {
              if (value.length) {
                setOTP(value);
              } else setOTP("");
            }}
          />
        </div>
      }
    >
      <Button disabled={disabled} {...restProps} className={buttonClassName}>
        {props.label}
      </Button>
    </Popconfirm>
  );
};

export default PopConfirmButton;
