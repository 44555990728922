import {
  GET_BANK_BRANCHES_LIST_START,
  GET_BANK_BRANCHES_LIST_SUCCESS,
  GET_BANK_BRANCHES_LIST_FAILURE,
} from 'redux/actionTypes/finances/getBankBranchesList';
import FinancesInitialStateInterface from 'types/finances/financialInitialState';

const getBankBranchesList = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_BANK_BRANCHES_LIST_START:
      return {
        ...state,
        bankBranchesList: {
          ...state.bankBranchesList,
          loading: true,
          error: null,
        },
      };

    case GET_BANK_BRANCHES_LIST_SUCCESS:
      return {
        ...state,
        bankBranchesList: {
          loading: false,
          error: null,
          data: payload,
          success: true,
        },
      };

    case GET_BANK_BRANCHES_LIST_FAILURE:
      return {
        ...state,
        bankBranchesList: {
          ...state.bankBranchesList,
          loading: false,
          error: payload,
          success: false,
        },
      };

    default:
      return null;
  }
};

export default getBankBranchesList;
