import {
  GET_BUILTIN_CONFIG_DATA_START,
  GET_BUILTIN_CONFIG_DATA_END,
  GET_BUILTIN_CONFIG_DATA_FAILURE,
  GET_BUILTIN_CONFIG_DATA_SUCCESS,
} from "redux/actionTypes/globalSettings/getBuiltinConfigData";
import apiAction from "utils/apiAction";

const getBuiltinConfigDataAction = (countryCode: string) => {
  return (dispatchAction: any) =>
    dispatchAction(
      apiAction({
        method: "POST",
        url: "/GetBuiltInConfigData",
        data: { countryCode },
        onStart: () => (dispatch) => {
          dispatch({ type: GET_BUILTIN_CONFIG_DATA_START });
        },
        onSuccess: (data) => (dispatch) => {
          dispatch({
            type: GET_BUILTIN_CONFIG_DATA_SUCCESS,
            payload: Array.isArray(data) ? data[0] : [],
          });
        },
        onFailure: (err) => (dispatch) => {
          const error = Array.isArray(err) ? err[0] || {} : err || {};
          dispatch({
            type: GET_BUILTIN_CONFIG_DATA_FAILURE,
            payload: error,
          });
        },
        onEnd: () => (dispatch) => {
          dispatch({ type: GET_BUILTIN_CONFIG_DATA_END });
        },
      })
    );
};

export default getBuiltinConfigDataAction;
