const eventsInitialState = {
  allEvents: {
    metaData: {
      TotalPages: "0",
      CurrentPage: "0",
      TotalRecords: "0",
      RecordsPerPage: "12",
    },
    data: [],
    getAllEvents: {
      error: null,
      message: null,
      loading: false,
    },
    fetchedYears: [new Date().getFullYear()],
  },
  updateCreate: {
    error: null,
    loading: false,
    message: null,
  },
  updateImage: {
    error: null,
    message: null,
    loading: false,
  },
};

export default eventsInitialState;
