import {
  APPROVE_CASHPOINT_DEPOSIT_FAILURE,
  APPROVE_CASHPOINT_DEPOSIT_START,
  APPROVE_CASHPOINT_DEPOSIT_SUCCESS,
  GET_CASHPOINT_DEPOSIT_LIST_FAILURE,
  GET_CASHPOINT_DEPOSIT_LIST_START,
  GET_CASHPOINT_DEPOSIT_LIST_SUCCESS,
  INITIATE_CASHPOINT_DEPOSIT_FAILURE,
  INITIATE_CASHPOINT_DEPOSIT_START,
  INITIATE_CASHPOINT_DEPOSIT_SUCCESS,
} from "redux/actionTypes/cashpoints";
import { CashPointsInitialStateInterface } from "types/initialState";

const cashPointDepositReducer = (
  state: CashPointsInitialStateInterface,
  { type, payload }: Record<string, any>
) => {
  switch (type) {
    case INITIATE_CASHPOINT_DEPOSIT_START:
      return {
        ...state,
        initiateCashDeposit: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case INITIATE_CASHPOINT_DEPOSIT_SUCCESS:
      return {
        ...state,
        initiateCashDeposit: {
          loading: false,
          data: payload,
          error: null,
        },
        pendingCashDeposits: {
          ...state.pendingCashDeposits,
          data: state.pendingCashDeposits.data.concat([payload]),
        },
      };

    case INITIATE_CASHPOINT_DEPOSIT_FAILURE:
      return {
        ...state,
        initiateCashDeposit: {
          loading: false,
          data: null,
          error: payload,
        },
      };

    case GET_CASHPOINT_DEPOSIT_LIST_START:
      return {
        ...state,
        pendingCashDeposits: {
          data: [],
          error: null,
          loading: true,
        },
      };

    case GET_CASHPOINT_DEPOSIT_LIST_SUCCESS:
      return {
        ...state,
        pendingCashDeposits: {
          error: null,
          data: payload,
          loading: false,
        },
      };

    case GET_CASHPOINT_DEPOSIT_LIST_FAILURE:
      return {
        ...state,
        pendingCashDeposits: {
          data: [],
          error: payload,
          loading: false,
        },
      };

    case APPROVE_CASHPOINT_DEPOSIT_START:
      return {
        ...state,
        confirmCashDeposit: {
          data: payload,
          error: null,
          loading: true,
        },
      };

    case APPROVE_CASHPOINT_DEPOSIT_SUCCESS: {
      const deposits = state.pendingCashDeposits.data.filter(
        (deposit) => deposit.OperationID !== payload.OperationID
      );

      return {
        ...state,
        pendingCashDeposits: {
          ...state.pendingCashDeposits,
          data: Array.from(deposits),
        },
        confirmCashDeposit: {
          error: null,
          data: null,
          loading: false,
        },
      };
    }

    case APPROVE_CASHPOINT_DEPOSIT_FAILURE:
      return {
        ...state,
        confirmCashDeposit: {
          data: null,
          loading: false,
          error: payload,
        },
      };

    default:
      return null;
  }
};

export default cashPointDepositReducer;
