import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  ModalProps,
  Space,
  DatePicker,
  Typography,
} from "antd";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FileExcelOutlined } from "@ant-design/icons";
import moment from "moment";
import * as XLSX from "xlsx";
import { getTransactionForExportAction } from "redux/actions/transactionReports";
import { MAX_RECORDS_DOWNLOAD } from "constants/general";
import { TRANSACTIONS_FOR_EXPORT_RESET } from "redux/actionTypes/transactionReports";
import getTransactionTypeText from "utils/getTransactionTypeText";
const typedUseSelector: TypedUseSelectorHook<any> = useSelector;

const CheckboxGroup = Checkbox.Group;
const { RangePicker } = DatePicker;

interface Props extends Omit<ModalProps, "onCancel"> {
  onClose: () => void;
  initialValues: any;
  selectOptionForm: any;
  setSelectOptionForm: any;
  dateRange?: any;
  defaultFilters?: any;
}

const columnTitles = [
  { text: "Transaction ID", value: "TransactionID" },
  { text: "Transaction Type", value: "TransactionType" },
  { text: "Date", value: "Date" },
  { text: "Currency", value: "Currency" },
  { text: "Amount", value: "Amount" },
  { text: "Fees", value: "Fees" },
  { text: "Reference", value: "Reference" },
  { text: "Sender Email", value: "SenderEmail" },
  { text: "Contact Email", value: "ContactEmail" },
  { text: "Contact First Name", value: "ContactFirstName" },
  { text: "Contact Phone", value: "ContactPhone" },
  { text: "Contact Last Name", value: "ContactLastName" },
  { text: "Sender Last Name", value: "SenderLastName" },
  { text: "Sender Phone", value: "SenderPhone" },
  { text: "Wallet Number", value: "WalletNumber" },
  { text: "Previous balance", value: "PreviousBalance" },
  { text: "New balance", value: "NewBalance" },
  { text: "Cashpoint ID", value: "CashPointPID" },
  { text: "Cashpoint Manager PID", value: "CashPointManagerPID" },
  { text: "Teller PID", value: "TellerPID" },
  { text: "Teller First Name", value: "TellerFirstName" },
  { text: "Teller Last Name", value: "TellerLastName" },
  { text: "Teller Phone Number", value: "TellerPhoneNumber" },
  { text: "Data", value: "PartnerResponse" },
];

const options = columnTitles.map((column) => column.text);

const ExportToXLSX = ({
  visible,
  initialValues,
  onClose,
  selectOptionForm,
  defaultFilters,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(
    options.slice(0, 7)
  );
  const [filterForExport, setFilterForExport] = useState<any>();

  const maxRecordsPerPage =
    Number(initialValues?.Meta?.TotalRecords) <= MAX_RECORDS_DOWNLOAD
      ? Number(initialValues?.Meta?.TotalRecords)
      : MAX_RECORDS_DOWNLOAD;
  const maxPages = Math.ceil(
    Number(initialValues?.Meta?.TotalRecords) / maxRecordsPerPage
  );

  const { transactionsExport } = typedUseSelector(
    ({ transactionsForExport: { transactionsExport } }) => ({
      transactionsExport,
    })
  );

  const organizedCheckedList = useMemo(() => {
    return checkedList
      .map(
        (item: any) =>
          columnTitles.filter((option: any) =>
            option.text === item ? option : null
          )[0]
      )
      .filter(Boolean);
  }, [checkedList, columnTitles]);

  const values = useMemo(() => {
    return (
      transactionsExport?.success &&
      !transactionsExport?.loading &&
      transactionsExport?.data?.Data.map((item: any) =>
        organizedCheckedList?.map((option: any) => ({
          [option.text]: item[option.value],
        }))
      ).filter(Boolean)
    );
  }, [transactionsExport, organizedCheckedList]);

  const flattenedValues = useMemo(() => {
    return values?.map?.((subArray: any) => {
      return subArray.reduce(
        (acc: { [x: string]: any }, obj: { [x: string]: any }) => {
          for (const key in obj) {
            acc[key] = obj[key];
          }
          return acc;
        },
        {}
      );
    });
  }, [values]);

  useEffect(() => {
    if (
      transactionsExport?.success &&
      !transactionsExport?.loading &&
      Array.isArray(flattenedValues) &&
      flattenedValues.length
    ) {
      const dataToExport = flattenedValues.map((item: any) => ({
        ...item,
        "Transaction Type": getTransactionTypeText(item["Transaction Type"]),
        Data: item?.Data
          ? JSON.parse(String(item?.Data))
              ?.data?.values?.map?.(
                (item: { text: string; value: string }) =>
                  `${intl.formatMessage({
                    id: "dd.new",
                    defaultMessage: item?.text,
                  })}: ${item?.value} \r\n`
              )
              ?.join?.("")
              ?.trim?.()
          : "",
      }));
      const fileName =
        filterForExport?.AllPages === true
          ? `transactions_${filterForExport?.DateFrom}_${filterForExport?.DateTo}`
          : `transactions_${filterForExport?.DateFrom}_${filterForExport?.DateTo}_page${filterForExport?.PageNumber}`;
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
      dispatch({ type: TRANSACTIONS_FOR_EXPORT_RESET });
      onClose();
    }
  }, [flattenedValues, transactionsExport]);

  useEffect(() => {
    setFilterForExport({
      ...defaultFilters,
      AllPages: false,
      RecordPerPage: maxRecordsPerPage,
      MaxPages: maxPages,
      PageNumber:
        filterForExport?.PageNumber || initialValues?.Meta?.CurrentPage,
    });
  }, [initialValues, defaultFilters]);

  const onHandleChangeForm = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFilterForExport({ ...filterForExport, [name]: value });
  };

  const fetchDataAndExport = async () => {
    await getTransactionForExportAction(filterForExport, intl)(dispatch);
  };

  return (
    <Modal
      width={328}
      title={null}
      footer={null}
      visible={visible}
      onCancel={onClose}
      destroyOnClose
    >
      <Col>
        <Checkbox
          indeterminate={
            checkedList.length > 0 && checkedList.length < options.length
          }
          onChange={(e: CheckboxChangeEvent) =>
            setCheckedList(e.target.checked ? options : [])
          }
          checked={options.length === checkedList.length}
        >
          {intl.formatMessage({
            id: "dd.new",
            defaultMessage: "Select all",
          })}
        </Checkbox>
        <Divider />

        <CheckboxGroup
          options={options}
          value={checkedList}
          onChange={(list: CheckboxValueType[]) => setCheckedList(list)}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        />
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Typography.Text>
            {intl.formatMessage({
              id: "dd.new",
              defaultMessage: "Date",
            })}
          </Typography.Text>
          <RangePicker
            style={{ width: "100%", marginBottom: "10px" }}
            value={[
              moment(filterForExport?.DateFrom),
              moment(filterForExport?.DateTo),
            ]}
            disabledDate={(date) =>
              date.isBefore(moment()) || date.isAfter(moment())
            }
            disabled
          />
          <Typography.Text>
            {intl.formatMessage({
              id: "dd.new",
              defaultMessage: "Records",
            })}{" "}
            (Maximum: {initialValues?.Meta?.TotalRecords})
          </Typography.Text>
          <Input
            name="RecordPerPage"
            placeholder={intl.formatMessage({
              id: "dd.new",
              defaultMessage: "Records",
            })}
            value={maxRecordsPerPage}
            disabled
            type="number"
            min={1}
          />
          <Typography.Text style={{ paddingTop: 10 }}>
            {intl.formatMessage({
              id: "dd.new",
              defaultMessage: "Page",
            })}{" "}
            (Maximum: {maxPages})
          </Typography.Text>
          <Input
            name="PageNumber"
            placeholder={intl.formatMessage({
              id: "dd.new",
              defaultMessage: "Page",
            })}
            value={filterForExport?.PageNumber}
            onChange={(e) =>
              Number(e.target.value) <= maxPages && onHandleChangeForm(e)
            }
            type="number"
            disabled={filterForExport?.AllPages}
            min={1}
          />
          <Checkbox
            style={{ paddingTop: 10 }}
            onChange={(e) =>
              setFilterForExport({
                ...filterForExport,
                AllPages: e.target.checked,
                PageNumber: 1,
              })
            }
            checked={filterForExport?.AllPages}
          >
            {intl.formatMessage({
              id: "dd.new",
              defaultMessage: "All pages",
            })}
          </Checkbox>
        </div>

        <Divider />
        <Space>
          <Button
            icon={<FileExcelOutlined />}
            htmlType="submit"
            type="primary"
            disabled={transactionsExport?.loading}
            loading={transactionsExport?.loading}
            onClick={() => {
              fetchDataAndExport();
            }}
          >
            {intl.formatMessage({
              id: "dd.new",
              defaultMessage: "Export to XLSX",
            })}
          </Button>
          <Button type="text" onClick={onClose}>
            {intl.formatMessage({ id: "dd.new", defaultMessage: "Cancel" })}
          </Button>
        </Space>
      </Col>
    </Modal>
  );
};

export default ExportToXLSX;
