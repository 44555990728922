import axios from "./axios";
import { History } from "history";

/**
 * redirect to a new pathname while saving current pathname and search params as 'next' query for the new url
 * @param path pathname to redirect to
 * @param history browser history object
 */
export const redirectWithNext = (path: string, history?: History) => {
  // extract current path, to set next query for redirection
  const { pathname, search } = window.location;
  const encodedURI = encodeURIComponent(pathname.concat(search));
  const nextURL = `${path}?next=${encodedURI}`;
  typeof history?.push === "function"
    ? history.push(nextURL)
    : window.location.assign(nextURL);
};

/**
 * @param path original redirection pathname
 * @param history browser history object
 */
export const applyRedirectionWithNext = (path: string, history?: History) => {
  // extract next query for redirection
  let nextURL = path;
  const { search } = window.location;
  const queryParams = new URLSearchParams(search);
  if (queryParams.has("next")) {
    nextURL = decodeURIComponent(queryParams.get("next")!);
  }
  typeof history?.push === "function"
    ? history.push(nextURL)
    : window.location.assign(nextURL);
};

const logout = async (history?: History) => {
  try {
    await axios.post("/LogOff", {
      LoginName: process.env.REACT_APP_NAME,
      APIKey: process.env.REACT_APP_API_KEY,
      AppID: process.env.REACT_APP_KEY,
    });
  } catch (e) {
  } finally {
    // force logout action
    // since the user is trying to logout and the LiveToken has been deleted on the backend
    // there is no practical use of refreshing the token to logout
    localStorage.PID = "";
    localStorage.user = "";
    localStorage.token = "";
    localStorage.userWasIdle = "";
    localStorage.refreshToken = "";
    localStorage.MAX_USER_IDLE_TIME = "";
    return redirectWithNext("/login", history);
  }
};

export default logout;
