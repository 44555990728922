import {
  UPLOAD_KYC_DOC_FAILURE,
  UPLOAD_KYC_DOC_START,
  UPLOAD_KYC_DOC_SUCCESS,
} from "redux/actionTypes/customers/uploadKYCDocs";
import CustomersInitialStateInterface from "types/Customer/CustomerInitialState";

const setSafeLimitReducer = (
  state: CustomersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case UPLOAD_KYC_DOC_START: {
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          uploadKYCDocs: {
            message: null,
            loading: true,
            error: null,
          },
        },
      };
    }
    case UPLOAD_KYC_DOC_SUCCESS: {
      const customerData = state.selectedCustomer.data!;
      const { UserIDURL, UserIDURLBack, UserProofOfAddressURL } = customerData;
      const { Type, MediaSourceURL } = payload.data;
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          data: {
            ...state.selectedCustomer.data,
            UserIDURL: Type === "1" ? MediaSourceURL : UserIDURL,
            UserIDURLBack: Type === "0" ? MediaSourceURL : UserIDURLBack,
            UserProofOfAddressURL:
              Type === "2" ? MediaSourceURL : UserProofOfAddressURL,
          },
          uploadKYCDocs: {
            message: payload.message,
            loading: false,
            error: null,
            success: true,
          },
        },
      };
    }

    case UPLOAD_KYC_DOC_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          uploadKYCDocs: {
            ...state.selectedCustomer.uploadKYCDocs,
            error: payload,
            loading: false,
          },
        },
      };
  }
};
export default setSafeLimitReducer;
