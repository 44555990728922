import { Method } from "axios";
import axios from "utils/axios";
import { API_REQUEST, CONCURRENT_API_REQUEST } from "constants/apiActions";
import handleAxiosError from "utils/handleAxiosError";
import checkExpiredTokenError from "utils/checkExpiredTokenError";
import refreshTokenAction from "redux/actions/users/refreshTokenAction";

interface HttpOptions {
  token: string;
  baseURL: string;
  headers: Record<string, string>;
}

type APIActionPayload = {
  onStart: any;
  onSuccess: any;
  onFailure: any;
  onEnd: any;
  method: Method;
  data: Record<string, string>;
  requests?: Array<any>;
  queries: [string];
  url: string;
  httpOptions: HttpOptions;
};

type Action = {
  type: string;
  payload: APIActionPayload;
};

function apiMiddleware({ dispatch, getState }: any) {
  return (next: (action: Action) => void) =>
    async ({ type = "", payload }: Action) => {
      //handle singular API requests
      if (type === API_REQUEST) {
        try {
          if (typeof payload.onStart === "function") {
            await payload.onStart()(dispatch);
          }

          const { data } = await axios.request({
            url: payload.url,
            data: payload.data,
            method: payload.method,
            params: payload.queries,
          });

          if (typeof payload.onSuccess === "function") {
            await payload.onSuccess(data)(dispatch);
          }
        } catch (e) {
          const error = handleAxiosError(e);
          if (checkExpiredTokenError(error) && localStorage.refreshToken) {
            refreshTokenAction({ type, payload })(dispatch);
          } else if (typeof payload.onFailure === "function") {
            await payload.onFailure(error)(dispatch);
          }
        }
      }

      // handle concurrent API requests
      if (type === CONCURRENT_API_REQUEST) {
        try {
          if (typeof payload.onStart === "function") {
            await payload.onStart()(dispatch);
          }
          const constructedRequests: Array<Promise<any>> = [];
          for (
            let requestIndex = 0;
            requestIndex < payload.requests!.length;
            requestIndex++
          ) {
            const element = payload.requests![requestIndex];
            const { token, headers = {} } = payload.httpOptions || {};
            if (token) {
              headers.From = token;
            }

            constructedRequests.push(
              axios.request({
                method: element.payload.method,
                url: element.payload.url,
                data: element.payload.data,
                params: element.payload.queries,
              })
            );
          }

          const requestsResponse = await Promise.all(constructedRequests);
          for (let index = 0; index < requestsResponse.length; index++) {
            const element = payload.requests![index];
            const { data } = requestsResponse[index];
            if (typeof element.payload.onSuccess === "function") {
              await element.payload.onSuccess(data)(dispatch);
            }
          }

          if (typeof payload.onSuccess === "function") {
            await payload.onSuccess()(dispatch);
          }
        } catch (e) {
          const error = handleAxiosError(e);
          if (checkExpiredTokenError(error) && localStorage.refreshToken) {
            refreshTokenAction({ type, payload })(dispatch);
          } else if (typeof payload.onFailure === "function") {
            await payload.onFailure(error)(dispatch);
          }
        }
      }

      if (type !== API_REQUEST) {
        return next({ type, payload });
      }
      if (typeof payload.onEnd === "function") {
        await payload.onEnd()(dispatch);
      }
      return getState();
    };
}

export default apiMiddleware;
