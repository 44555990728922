import {
  Form,
  Modal,
  Space,
  Button,
  ModalProps,
  DatePicker,
  Typography,
  InputNumber,
} from "antd";
import moment from "moment";
import { useIntl } from "react-intl";
import { FileExcelOutlined } from "@ant-design/icons";
import { TransactionsFilters } from "components/TransactionsTable/utils";
import { useDownloadTransactions } from "components/TransactionsTable/useDownloadTransactions";

const { Title } = Typography;
const { RangePicker } = DatePicker;

interface Props extends Omit<ModalProps, "onCancel"> {
  onClose: () => void;
  initialValues: TransactionsFilters;
}

const DownloadConfigModal = ({ visible, initialValues, onClose }: Props) => {
  const intl = useIntl();
  const [configForm] = Form.useForm();
  const { downloadTransactions, isDownloading } = useDownloadTransactions();

  return (
    <Modal
      width={328}
      title={null}
      footer={null}
      destroyOnClose
      visible={visible}
      onCancel={onClose}
    >
      <Form
        form={configForm}
        requiredMark={false}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{
          RecordPerPage: 250,
          Period: [
            moment(initialValues.DateFrom),
            moment(initialValues.DateTo),
          ],
        }}
        onFinish={async () => {
          try {
            const { Period, RecordPerPage } = await configForm.validateFields();

            downloadTransactions(
              {
                ...initialValues,
                PageNumber: "1",
                RecordPerPage: (RecordPerPage as number).toString(),
                DateTo: (Period as moment.Moment[])[1].toISOString(),
                DateFrom: (Period as moment.Moment[])[0].toISOString(),
              },
              `transactions-report-${moment().format("MMM-YYYY")}`,
              onClose
            );
          } catch (error) {}
        }}
      >
        <Form.Item style={{ marginBottom: 8 }}>
          <Title level={5}>
            {intl.formatMessage({
              id: "dd.new",
              defaultMessage: "Configure your download",
            })}
          </Title>
        </Form.Item>

        <Form.Item rules={[{ required: true, message: "" }]} name="Period">
          <RangePicker
            style={{ width: "100%" }}
            disabledDate={(date) => date.isAfter(moment())}
          />
        </Form.Item>

        <Form.Item
          name="RecordPerPage"
          rules={[{ required: true, min: 1, type: "number", message: "" }]}
        >
          <InputNumber
            style={{ width: "100%" }}
            placeholder={intl.formatMessage({
              id: "dd.new",
              defaultMessage: "Number of records",
            })}
          />
        </Form.Item>

        <Space>
          <Button
            icon={<FileExcelOutlined />}
            htmlType="submit"
            type="primary"
            loading={isDownloading}
          >
            {intl.formatMessage({
              id: "1465",
              defaultMessage: "Download",
            })}
          </Button>

          <Button type="text" onClick={onClose} disabled={isDownloading}>
            {intl.formatMessage({ id: "86", defaultMessage: "Cancel" })}
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default DownloadConfigModal;
