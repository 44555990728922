export const getMonthName = (monthIndex: number) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[monthIndex - 1];
};

export function formatTimeAgo(d: number) {
  const days = Math.abs(d);
  if (days === 0) {
    return "Today";
  } else if (days === 1) {
    return "1 day ago";
  } else if (days < 30) {
    return days + " days ago";
  } else if (days < 59) {
    return "1 month ago";
  } else if (days < 365) {
    const months = Math.floor(days / 30);
    return months + " months ago";
  } else {
    const years = Math.floor(days / 365);
    return years + " years ago";
  }
}
