import IRealTimeServices from "types/IRealTimeServices";
import chatThread from "../realTimeServices/chatThread";
import directMessage from "../realTimeServices/directMessage";
import setToken from "../realTimeServices/setToken";
const realTimeServicesReducer = (state: IRealTimeServices, action: any) => {
  return {
    ...state,
    ...chatThread(state, action),
    ...directMessage(state, action),
    ...setToken(state, action),
  };
};

export default realTimeServicesReducer;
