import ApplicationStateInterface from "types/applicationState";
import appGlobalReducer from "./globalReducer";

const appStateReducer = (
  state: ApplicationStateInterface,
  action: Record<string, unknown>
) => {
  return {
    ...state,
    ...appGlobalReducer(state, action),
  };
};

export default appStateReducer;
