import {
  TRANSACTIONS_REPORT_SEARCH_START,
  TRANSACTIONS_REPORT_SEARCH_SUCCESS,
  TRANSACTIONS_REPORT_SEARCH_FAILURE,
} from 'redux/actionTypes/transactionReports';
import TransactionsReportInterface from 'types/transactionsReport';

const transactionsSearchReducer = (
  state: TransactionsReportInterface,
  { type, payload }: any
) => {
  switch (type) {
    case TRANSACTIONS_REPORT_SEARCH_START:
      return {
        ...state,
        transactions: {
          data: [],
          searchData: null,
          loading: true,
          error: null,
        },
      };
    case TRANSACTIONS_REPORT_SEARCH_SUCCESS: {
      return {
        ...state,
        transactions: {
          data: payload,
          loading: false,
          error: null,
        },
      };
    }
    case TRANSACTIONS_REPORT_SEARCH_FAILURE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          error: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};

export default transactionsSearchReducer;
