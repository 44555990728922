import {
  API_REQUEST_START,
  API_REQUEST_SUCCESS,
  API_REQUEST_FAILURE,
  API_REQUEST_END,
  API_REQUEST,
} from "constants/apiActions";

const { REACT_APP_LOGIN_NAME, REACT_APP_API_KEY, REACT_APP_ID } = process.env;

const apiActions = ({
  url = "",
  method = "GET",
  data = {},
  queries = {},
  httpOptions = {},
  onStart = () =>
    (dispatch: any): void =>
      dispatch({
        type: API_REQUEST_START,
        payload: { loading: true },
      }),
  onSuccess = (data: any) => (dispatch: React.Dispatch<any>) =>
    dispatch({
      type: API_REQUEST_SUCCESS,
      payload: { loading: false },
    }),
  onFailure = (error: any) =>
    (dispatch: React.Dispatch<Record<string, unknown>>) =>
      dispatch({
        type: API_REQUEST_FAILURE,
        payload: { loading: false },
      }),
  onEnd = () => (dispatch: React.Dispatch<unknown>) =>
    dispatch({
      type: API_REQUEST_END,
      payload: { loading: false },
    }),
}): Record<string, unknown> => {
  const urlQueries: { [Key: string]: string } = queries;
  if (queries) {
    Object.keys(queries).forEach((key) => {
      urlQueries[key] =
        typeof urlQueries[key] === "string"
          ? urlQueries[key].trim()
          : urlQueries[key];
      return urlQueries[key] || delete urlQueries[key];
    });
  }

  return {
    type: API_REQUEST,
    payload: {
      url,
      method,
      httpOptions,
      data: {
        LoginName: REACT_APP_LOGIN_NAME,
        APIKey: REACT_APP_API_KEY,
        AppID: REACT_APP_ID,
        ...data,
      },
      queries: urlQueries,
      onStart,
      onSuccess,
      onFailure,
      onEnd,
    },
  };
};

export default apiActions;
