import zipcelx from "zipcelx";
import { message } from "antd";
import { useIntl } from "react-intl";
import { TransactionsFilters } from "./utils";
import { useDownloadTransactionsMutation } from "queries/useFetchTransactions";

export const useDownloadTransactions = () => {
  const intl = useIntl();
  const { mutate, isLoading: isDownloading } =
    useDownloadTransactionsMutation();

  const downloadTransactions = (
    filters: TransactionsFilters,
    filename: string,
    onSuccess?: () => void
  ) => {
    return mutate(filters, {
      onSuccess: (response) => {
        onSuccess?.();
        if (response.Data.length) {
          zipcelx({
            filename,
            sheet: {
              data: [
                [
                  { type: "string", value: "Transaction ID" },
                  { type: "string", value: "Operation Type" },
                  { type: "string", value: "Transaction Type" },
                  { type: "string", value: "Date" },
                  { type: "string", value: "Currency" },
                  { type: "string", value: "Amount" },
                  { type: "string", value: "Fees" },
                  { type: "string", value: "Source Wallet" },
                  { type: "string", value: "Target Wallet" },
                  { type: "string", value: "Reference" },
                  { type: "string", value: "Description" },
                ],
                // @ts-expect-error
                ...response.Data.map((t) => [
                  { value: t.TransactionNumber, type: "string" },
                  { value: t.OpsType, type: "string" },
                  { value: t.TransactionType, type: "string" },
                  { value: t.Date, type: "string" },
                  { value: t.Currency, type: "string" },
                  { value: t.Amount, type: "string" },
                  { value: t.Fees, type: "string" },
                  { value: t.WalletNumber, type: "string" },
                  { value: t.TargetAccount, type: "string" },
                  { value: t.Reference, type: "string" },
                  { value: t.Description, type: "string" },
                ]),
              ],
            },
          });
        } else {
          message.error(
            intl.formatMessage({
              id: "dd.new",
              defaultMessage: "No records found!",
            })
          );
        }
      },
    });
  };

  return {
    isDownloading,
    downloadTransactions,
  };
};
