export const SET_CUSTOMER_STATUS_START = "SET_CUSTOMER_STATUS_START";
export const SET_CUSTOMER_STATUS_SUCCESS = "SET_CUSTOMER_STATUS_SUCCESS";
export const SET_CUSTOMER_STATUS_FAILURE = "SET_CUSTOMER_STATUS_FAILURE";
export const SET_CUSTOMER_STATUS_END = "SET_CUSTOMER_STATUS_END";

export const UNLOCK_CUSTOMER_START = "UNLOCK_CUSTOMER_START";
export const UNLOCK_CUSTOMER_SUCCESS = "UNLOCK_CUSTOMER_SUCCESS";
export const UNLOCK_CUSTOMER_FAILURE = "UNLOCK_CUSTOMER_FAILURE";
export const UNLOCK_CUSTOMER_END = "UNLOCK_CUSTOMER_END";

export const RESET_CUSTOMER_START = "RESET_CUSTOMER_START";
export const RESET_CUSTOMER_SUCCESS = "RESET_CUSTOMER_SUCCESS";
export const RESET_CUSTOMER_FAILURE = "RESET_CUSTOMER_FAILURE";


export const REDEEM_REFERRAL_PAYMENT_START = "REDEEM_REFERRAL_PAYMENT_START";
export const REDEEM_REFERRAL_PAYMENT_SUCCESS = "REDEEM_REFERRAL_PAYMENT_SUCCESS";
export const REDEEM_REFERRAL_PAYMENT_FAILURE = "REDEEM_REFERRAL_PAYMENT_FAILURE";
export const REDEEM_REFERRAL_PAYMENT_CLEAR = "REDEEM_REFERRAL_PAYMENT_CLEAR";