import {
  GET_TRANSACTIONS_START,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILURE,
} from "redux/actionTypes/customers/transactionsActionTypes";
import CustomersInitialStateInterface from "types/Customer/CustomerInitialState";

const transactionsReducer = (
  state: CustomersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_TRANSACTIONS_START:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          transactions: { loading: true, data: [], error: null },
        },
      };

    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          transactions: { loading: false, data: payload, error: null },
        },
      };

    case GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          transactions: { loading: false, error: payload, data: [] },
        },
      };

    default:
      return null;
  }
};

export default transactionsReducer;
