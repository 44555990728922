import {
  REDEEM_REFERRAL_PAYMENT_START,
  REDEEM_REFERRAL_PAYMENT_SUCCESS,
  REDEEM_REFERRAL_PAYMENT_FAILURE,
} from "redux/actionTypes/customers/customerStatusType";

const payCommission = (state: any, { type, payload }: any) => {
  switch (type) {
    case REDEEM_REFERRAL_PAYMENT_START:
      return {
        ...state,
        payReferralCommission: { loading: true, data: null, error: null },
      };

    case REDEEM_REFERRAL_PAYMENT_SUCCESS: {
      const newOrgData = Object.assign({}, payload);
      const listData = Array.from(state.payReferralCommission.data);
      const oldOrgIndex = listData.findIndex(
        ({ OrganizationID }: any) => OrganizationID === payload.OrganizationID
      );

      if (oldOrgIndex !== -1) {
        listData.splice(oldOrgIndex, 1, newOrgData);
      } else {
        listData.unshift(newOrgData);
      }

      return {
        ...state,
        payReferralCommission: {
          ...state.payReferralCommission,
          data: Array.from(listData),
        },
      };
    }

    case REDEEM_REFERRAL_PAYMENT_FAILURE:
      return {
        ...state,
        payReferralCommission: {
          ...state.payReferralCommission,
          loading: false,
        },
      };

    default:
      return null;
  }
};

export default payCommission;
