import {
  CLEAR_NOTIFICATION,
  GET_REPORT_LIST_FAILURE,
  GET_REPORT_LIST_START,
  GET_REPORT_LIST_SUCCESS,
  REJECT_REPORT_FAILURE,
  REJECT_REPORT_START,
  REJECT_REPORT_SUCCESS,
  UPDATE_REPORTED_PS_FAILURE,
  UPDATE_REPORTED_PS_START,
  UPDATE_REPORTED_PS_SUCCESS,
} from "redux/actionTypes/contentReview";
import { ReportedItem } from "types/contentReview/reportList";
import ContentReviewInterface from "types/contentReview/index.d";

const removeServiceFormList = (
  servicesList: Array<ReportedItem>,
  itemID: string,
  itemNumber?: string
) => {
  return servicesList.filter((item: ReportedItem) => {
    return itemNumber?.length
      ? !(item.ItemID === itemID && item.ItemNumber === itemNumber)
      : item.ItemID !== itemID;
  });
};

const reportedContentReducer = (
  state: ContentReviewInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_REPORT_LIST_START:
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          loading: true,
          data: {
            Data: [],
            Meta: {},
          },
          error: null,
          serviceUpdate: null,
          updateComment: null,
        },
      };

    case GET_REPORT_LIST_SUCCESS:
      const Data = payload.Data.map((Data: any) => {
        Data.Details = Data.Details[0];
        return Data;
      });
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          data: {
            ...payload,
            Meta: { ...payload.Meta, TotalRecords: Data.length },
            Data: Data,
          },
          loading: false,
          error: null,
        },
      };

    case GET_REPORT_LIST_FAILURE:
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          error: payload,
          loading: false,
        },
      };

    case UPDATE_REPORTED_PS_START:
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          loading: true,
          serviceUpdate: null,
          updateComment: null,
        },
      };

    case UPDATE_REPORTED_PS_SUCCESS:
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          data: {
            ...state.reportedContent.data,
            Data:
              payload.Suspend?.toLowerCase() === "yes"
                ? removeServiceFormList(
                    state.reportedContent.data.Data,
                    payload.ItemID
                    // payload.ItemNumber
                  )
                : state.reportedContent.data.Data,
          },
          loading: false,
          serviceUpdate: { ...payload },
        },
      };

    case REJECT_REPORT_START:
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          loading: true,
          serviceUpdate: null,
          updateComment: null,
          rejectReport: null,
        },
      };

    case REJECT_REPORT_SUCCESS:
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          loading: false,
          serviceUpdate: null,
          updateComment: null,
          rejectReport: null,
          data: {
            ...state.reportedContent.data,
            Data: removeServiceFormList(
              state.reportedContent.data.Data,
              payload.ItemID,
              payload.isService ? undefined : payload.ItemNumber
            ),
          },
        },
      };

    case REJECT_REPORT_FAILURE:
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          loading: false,
          rejectReport: payload,
        },
      };

    case UPDATE_REPORTED_PS_FAILURE:
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          loading: false,
          error: payload,
        },
      };

    case CLEAR_NOTIFICATION:
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          serviceUpdate: null,
          updateComment: null,
          error: null,
        },
      };
    default:
      return null;
  }
};

export default reportedContentReducer;
