import { Button, Checkbox, Modal, ModalProps, Row, Space } from "antd";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

import allPartners from "constants/memberships";
import { TransactionsFilters } from "components/TransactionsTable/utils";

interface TransactionPartnersFiltersProps {
  onSaveFilters: (v: TransactionsFilters) => void;
  onCancel: () => void;
  countryCode: string;
}
export const TransactionPartnersFilters = (
  props: TransactionPartnersFiltersProps
) => {
  const intl = useIntl();
  const [checkedList, setCheckedList]: any = useState<CheckboxValueType[]>();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const transactionsTypeOptions = allPartners?.filter((item) =>
    item?.countries?.includes(props.countryCode?.toUpperCase())
  );

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(
      !!list.length && list.length < transactionsTypeOptions.length
    );
    setCheckAll(list.length === transactionsTypeOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(
      e.target.checked ? transactionsTypeOptions?.map((item) => item.value) : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <Row
      wrap={false}
      justify="space-between"
      style={{ flexDirection: "column", height: "100%" }}
    >
      <div style={{ paddingTop: 24, marginBottom: 24 }}>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {intl.formatMessage({
            id: "2782",
            defaultMessage: "All",
          })}
        </Checkbox>

        <Checkbox.Group
          options={transactionsTypeOptions}
          value={checkedList}
          onChange={onChange}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        />
      </div>

      <div style={{ paddingTop: 24, marginBottom: 24 }}>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              props.onSaveFilters({ Membership: checkedList });
              props.onCancel();
            }}
          >
            {intl.formatMessage({ id: "dd.new", defaultMessage: "Save" })}
          </Button>
          <Button type="text" onClick={props.onCancel}>
            {intl.formatMessage({ id: "dd.new", defaultMessage: "Cancel" })}
          </Button>
        </Space>
      </div>
    </Row>
  );
};

interface Props
  extends Omit<ModalProps, "onCancel">,
    TransactionPartnersFiltersProps {}

export const TransactionsPartnersFiltersModal = (props: Props) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: "dd.new",
        defaultMessage: "Partners",
      })}
      width={328}
      {...props}
      footer={null}
      closable={false}
      maskClosable={false}
      wrapClassName="drawer-modal"
    >
      <TransactionPartnersFilters
        onCancel={props.onCancel}
        onSaveFilters={props.onSaveFilters}
        countryCode={props.countryCode}
      />
    </Modal>
  );
};
