import {
  ADD_UPDATE_MERCHANT_START,
  ADD_UPDATE_MERCHANT_SUCCESS,
  ADD_UPDATE_MERCHANT_FAILURE,
} from "redux/actionTypes/merchant";

const addUpdateMerchant = (state: any, { type, payload }: any) => {
  switch (type) {
    case ADD_UPDATE_MERCHANT_START:
      return {
        ...state,
        addUpdateMerchant: { loading: true, data: null, error: null },
      };

    case ADD_UPDATE_MERCHANT_SUCCESS: {
      return {
        ...state,
        addUpdateMerchant: { loading: false, data: payload, error: null },
      };
    }

    case ADD_UPDATE_MERCHANT_FAILURE:
      return {
        ...state,
        addUpdateMerchant: { loading: false, data: null, error: null },
      };

    default:
      return null;
  }
};

export default addUpdateMerchant;
