import { CONCURRENT_API_REQUEST } from '../constants/apiActions';

const concurrentAPIAction = ({
  requests,
  onStart,
  onSuccess,
  onFailure,
  onEnd,
}: {
  requests: Array<any>;
  onStart: () => (dispatch: React.Dispatch<any>) => void;
  onSuccess: () => (dispatch: React.Dispatch<any>) => void;
  onFailure: () => (dispatch: React.Dispatch<any>) => void;
  onEnd: () => (dispatch: React.Dispatch<any>) => void;
}) => {
  return {
    type: CONCURRENT_API_REQUEST,
    payload: {
      data: {},
      requests,
      onStart,
      onSuccess,
      onFailure,
      onEnd,
    },
  };
};

export default concurrentAPIAction;
