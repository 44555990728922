import FinancesInitialStateInterface from 'types/finances/financialInitialState';
import {
  GET_CROWDFUNDING_SERVICE_RATE_START,
  GET_CROWDFUNDING_SERVICE_RATE_SUCCESS,
  GET_CROWDFUNDING_SERVICE_RATE_FAILURE,
} from 'redux/actionTypes/finances';

const currencyListReducer = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_CROWDFUNDING_SERVICE_RATE_START:
      return {
        ...state,
        getCrowdfundingFeeRate: {
          ...state.getCrowdfundingFeeRate,
          loading: true,
          error: null,
        },
      };
    case GET_CROWDFUNDING_SERVICE_RATE_FAILURE:
      return {
        ...state,
        getCrowdfundingFeeRate: {
          ...state.getCrowdfundingFeeRate,
          error: payload,
          loading: false,
        },
      };
    case GET_CROWDFUNDING_SERVICE_RATE_SUCCESS:
      return {
        ...state,
        getCrowdfundingFeeRate: {
          ...state.getCrowdfundingFeeRate,
          data: payload,
          error: null,
          loading: false,
        },
      };
    default:
      return null;
  }
};

export default currencyListReducer;
