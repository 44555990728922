import assetMap from "asset-map";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import Icon, {
  BellOutlined,
  SolutionOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { Menu, Layout, Button, Space } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import { ReactComponent as HomeIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as SupportIcon } from "assets/icons/headset.svg";
import { ReactComponent as FinanceIcon } from "assets/icons/finance.svg";
import { ReactComponent as EventsIconSVG } from "assets/icons/megaphone.svg";
import { ReactComponent as CompanySettingsIcon } from "assets/icons/company.svg";
import { ReactComponent as LeftChevronIcon } from "assets/icons/left-chevron.svg";
import { ReactComponent as ReportsIconSVG } from "assets/icons/analytics-pie.svg";
import { changeSidebarStateAction } from "redux/actions/app/globalAppStateActions";
import { ReactComponent as RightChevronIcon } from "assets/icons/right-chevron.svg";
import { ReactComponent as CashpointsIcon } from "assets/icons/cashpoint-store.svg";
import { ReactComponent as ContentIconSVG } from "assets/icons/content-management-1.svg";
import { ReactComponent as MerchantIcon } from "assets/icons/merchant.svg";

import "./index.scss";

const { Sider } = Layout;

const SideBarComponent: React.FC<{
  isCollapsed: boolean;
  appName: string | undefined;
  setIsCollapsed: (collapsed: boolean) => void;
}> = ({ appName = "", isCollapsed, setIsCollapsed }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const appLogo = assetMap.get("logo");
  const [activeMenu, setActiveMenu] = useState(["home"]);

  useEffect(() => {
    const { pathname } = location;
    if (pathname === "/") {
      setActiveMenu(["home"]);
    } else if (pathname.includes("/roles")) {
      setActiveMenu(["roles"]);
    } else if (pathname.includes("/transactions-report")) {
      setActiveMenu(["transactions-report"]);
    } else {
      setActiveMenu([
        pathname
          .split(/\//)
          .filter((path) => path.length)[0]
          .replace(/\//, ""),
      ]);
    }
  }, [location, dispatch]);

  const navigate = ({ key }: any) => {
    if (key !== location.pathname.replace(/\//, "")) {
      if (key === "home") {
        history.push("/");
      } else if (key === "roles") {
        history.push("/user-management/roles");
      } else history.push(`/${key}`);
    }
  };

  return (
    <Sider
      collapsible
      trigger={null}
      collapsed={isCollapsed}
      className="sidebar__container"
    >
      <div className="sidebar__wrapper">
        <Link to="/">
          <Space
            className={`logo-wrapper ${
              isCollapsed ? "is_collapsed" : ""
            } app-${appName}`}
          >
            <div className="logo">
              <img src={appLogo} alt="bo logo" />
            </div>
          </Space>
        </Link>

        <Menu
          theme="dark"
          mode="inline"
          onClick={navigate}
          className="sidebar__menu"
          selectedKeys={activeMenu}
          defaultSelectedKeys={["home"]}
        >
          <Menu.Item key="home" icon={<Icon component={HomeIcon} />}>
            {intl.formatMessage({
              id: "1239",
              defaultMessage: "Dashboard",
            })}
          </Menu.Item>

          <Menu.Item
            key="customer-support"
            icon={<Icon component={SupportIcon} />}
          >
            {intl.formatMessage({
              id: "1504",
              defaultMessage: "Customer support",
            })}
          </Menu.Item>
          <Menu.Item
            key="cashpoints"
            icon={<Icon component={CashpointsIcon} />}
          >
            {intl.formatMessage({
              id: "1278",
              defaultMessage: "Distributors",
            })}
          </Menu.Item>

          <Menu.Item
            key="content-review"
            icon={<Icon component={ContentIconSVG} />}
          >
            {intl.formatMessage({
              id: "2566",
              defaultMessage: "Content review",
            })}
          </Menu.Item>

          <Menu.Item
            hidden
            key="events"
            icon={<Icon component={EventsIconSVG} />}
          >
            {intl.formatMessage({
              id: "2773",
              defaultMessage: "Marketing events",
            })}
          </Menu.Item>

          <Menu.Item key="notification-center" icon={<BellOutlined />}>
            {intl.formatMessage({
              id: "2791",
              defaultMessage: "Notifications center",
            })}
          </Menu.Item>
          <Menu.Item key="Organizations" icon={<SolutionOutlined />}>
            {intl.formatMessage({
              id: "4532",
              defaultMessage: "Organizations",
            })}
          </Menu.Item>
          <Menu.Item key="Operators" icon={<ContainerOutlined />}>
            {intl.formatMessage({
              id: "4532",
              defaultMessage: "Operators",
            })}
          </Menu.Item>

          <Menu.Item key="Merchants" icon={<Icon component={MerchantIcon} />}>
            {intl.formatMessage({
              id: "8.new",
              defaultMessage: "Merchants",
            })}
          </Menu.Item>
          <Menu.Item
            key="transactions-report"
            icon={<Icon component={ReportsIconSVG} />}
          >
            {intl.formatMessage({
              id: "2567",
              defaultMessage: "Transactions report",
            })}
          </Menu.Item>

          <Menu.Item key="finances" icon={<Icon component={FinanceIcon} />}>
            {intl.formatMessage({
              id: "2568",
              defaultMessage: "Financial settings",
            })}
          </Menu.Item>

          <Menu.Item
            key="user-management"
            icon={<Icon component={UsersIcon} />}
          >
            {intl.formatMessage({
              id: "2570",
              defaultMessage: "User management",
            })}
          </Menu.Item>

          <Menu.Item
            hidden
            key="company-settings"
            icon={<Icon component={CompanySettingsIcon} />}
          >
            {intl.formatMessage({
              id: "2569",
              defaultMessage: "Company settings",
            })}
          </Menu.Item>
        </Menu>

        <div className="sidebar__btn-collapse">
          <Button
            type="ghost"
            shape="circle"
            icon={
              <Icon
                style={{ fontSize: 24 }}
                component={isCollapsed ? RightChevronIcon : LeftChevronIcon}
              />
            }
            onClick={() => {
              const sideBarState = !!isCollapsed;
              setIsCollapsed(!sideBarState);
              changeSidebarStateAction(sideBarState)(dispatch);
            }}
          />
        </div>
      </div>
    </Sider>
  );
};

export default SideBarComponent;
