export interface ActionPayloadInterface {
  type: string;
  payload: any;
}

export interface PeerUserInterface {
  PictureURL: string;
  FirstName: string;
  LastName: string;
  Prefix: string;
  Phone: string;
}

export enum PeerApprovalStatus {
  APPROVAL_NONE = 'APPROVAL_NONE',
  APPROVAL_REQUESTED = 'APPROVAL_REQUESTED',
  APPROVAL_ACCEPTED = 'APPROVAL_ACCEPTED',
  APPROVAL_REJECTED = 'APPROVAL_REJECTED',
  APPROVAL_WAITING = 'APPROVAL_WAITING',
  APPROVAL_TIME_OUT = 'APPROVAL_TIME_OUT',
}

export interface Language {
  key: string;
  text: string;
  flag: string;
  code: string;
}
