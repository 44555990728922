import {
  CREATE_BULK_ORGANIZATION_START,
  CREATE_BULK_ORGANIZATION_SUCCESS,
  CREATE_BULK_ORGANIZATION_FAILURE,
} from "redux/actionTypes/organization";

const createBulkOrganizationReducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case CREATE_BULK_ORGANIZATION_START:
      return {
        ...state,
        createBulkOrganization: { loading: true, data: null, error: null },
      };

    case CREATE_BULK_ORGANIZATION_SUCCESS: {
      const newOrgData = Object.assign({}, payload);
      const listData = Array.from(state.bulkOrganizationList.data);
      const oldOrgIndex = listData.findIndex(
        ({ OrganizationID }: any) => OrganizationID === payload.OrganizationID
      );

      if (oldOrgIndex !== -1) {
        listData.splice(oldOrgIndex, 1, newOrgData);
      } else {
        listData.unshift(newOrgData);
      }

      return {
        ...state,
        bulkOrganizationList: {
          ...state.bulkOrganizationList,
          data: Array.from(listData),
        },
        createBulkOrganization: { loading: false, data: payload, error: null },
      };
    }

    case CREATE_BULK_ORGANIZATION_FAILURE:
      return {
        ...state,
        bulkOrganizationList: { ...state.bulkOrganizationList },
        createBulkOrganization: { loading: false, data: null, error: null },
      };

    default:
      return null;
  }
};

export default createBulkOrganizationReducer;
