import ContentReviewInterface from 'types/contentReview/index.d';
import getAllNotificationsReducer from './getAllNotificationsReducer';
import notificationsCenterReducer from './notificationsCenterReducer';
import commentReducer from 'redux/reducers/contentReview/commentReducer';
import newPostReducer from 'redux/reducers/contentReview/newPostReducer';
import reportedContentReducer from 'redux/reducers/contentReview/reportedContentReducer';

const contentReviewReducer = (state: ContentReviewInterface, action: any) => {
  return {
    ...state,
    ...commentReducer(state, action),
    ...newPostReducer(state, action),
    ...reportedContentReducer(state, action),
    ...notificationsCenterReducer(state, action),
    ...getAllNotificationsReducer(state, action),
  };
};

export default contentReviewReducer;
