export const GET_BANK_LIST_START = "GET_BANK_LIST_START";
export const GET_BANK_LIST_SUCCESS = "GET_BANK_LIST_SUCCESS";
export const GET_BANK_LIST_FAILURE = "GET_BANK_LIST_FAILURE";

export const APPROVE_OR_REJECT_BANK_START = "APPROVE_OR_REJECT_BANK_START";
export const APPROVE_OR_REJECT_BANK_SUCCESS = "APPROVE_OR_REJECT_BANK_SUCCESS";
export const APPROVE_OR_REJECT_BANK_FAILURE = "APPROVE_OR_REJECT_BANK_FAILURE";
export const CLEAR_APPROVE_OR_REJECT_BANK = "CLEAR_APPROVE_OR_REJECT_BANK";

export const UNLINK_BANK_START = "UNLINK_BANK_START";
export const UNLINK_BANK_SUCCESS = "UNLINK_BANK_SUCCESS";
export const UNLINK_BANK_FAILURE = "UNLINK_BANK_FAILURE";
export const CLEAR_UNLINK_BANK = "CLEAR_UNLINK_BANK";

export const REMOVE_UNLINKED_ACCOUNT = "REMOVE_UNLINKED_ACCOUNT";
export const UPDATE_LINKED_BANK_LIST = "UPDATE_LINKED_BANK_LIST";

export const LINK_BANK_ACCOUNT_START = "LINK_BANK_ACCOUNT_START";
export const LINK_BANK_ACCOUNT_SUCCESS = "LINK_BANK_ACCOUNT_SUCCESS";
export const LINK_BANK_ACCOUNT_FAILURE = "LINK_BANK_ACCOUNT_FAILURE";
export const CLEAR_LINK_BANK_ACCOUNT = "CLEAR_LINK_BANK_ACCOUNT";

export const LINK_BANK_ACCOUNT_REQUEST_START =
  "LINK_BANK_ACCOUNT_REQUEST_START";
export const LINK_BANK_ACCOUNT_REQUEST_SUCCESS =
  "LINK_BANK_ACCOUNT_REQUEST_SUCCESS";
export const LINK_BANK_ACCOUNT_REQUEST_FAILURE =
  "LINK_BANK_ACCOUNT_REQUEST_FAILURE";
export const CLEAR_LINK_ACCOUNT_REQUEST = "CLEAR_LINK_ACCOUNT_REQUEST";
