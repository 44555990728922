export const GET_ALL_CASHPOINTS_START = "GET_ALL_CASHPOINTS_START";
export const GET_ALL_CASHPOINTS_SUCCESS = "GET_ALL_CASHPOINTS_SUCCESS";
export const GET_ALL_CASHPOINTS_FAILURE = "GET_ALL_CASHPOINTS_FAILURE";

export const GET_CASHPOINTS_REQUESTS_START = "GET_CASHPOINTS_REQUESTS_START";
export const GET_CASHPOINTS_REQUESTS_SUCCESS =
  "GET_CASHPOINTS_REQUESTS_SUCCESS";
export const GET_CASHPOINTS_REQUESTS_FAILURE =
  "GET_CASHPOINTS_REQUESTS_FAILURE";

export const APPROVE_CASHPOINT_REQUEST_START =
  "APPROVE_CASHPOINT_REQUEST_START";
export const APPROVE_CASHPOINT_REQUEST_SUCCESS =
  "APPROVE_CASHPOINT_REQUEST_SUCCESS";
export const APPROVE_CASHPOINT_REQUEST_FAILURE =
  "APPROVE_CASHPOINT_REQUEST_FAILURE";

export const REJECT_CASHPOINT_REQUEST_START = "REJECT_CASHPOINT_REQUEST_START";
export const REJECT_CASHPOINT_REQUEST_SUCCESS =
  "REJECT_CASHPOINT_REQUEST_SUCCESS";
export const REJECT_CASHPOINT_REQUEST_FAILURE =
  "REJECT_CASHPOINT_REQUEST_FAILURE";

export const CONFIGURE_CASHPOINT_START = "CONFIGURE_CASHPOINT_START";
export const CONFIGURE_CASHPOINT_SUCCESS = "CONFIGURE_CASHPOINT_SUCCESS";
export const CONFIGURE_CASHPOINT_FAILURE = "CONFIGURE_CASHPOINT_FAILURE";

export const CREATE_CASHPOINT_OWNER_START = "CREATE_CASHPOINT_OWNER_START";
export const CREATE_CASHPOINT_OWNER_SUCCESS = "CREATE_CASHPOINT_OWNER_SUCCESS";
export const CREATE_CASHPOINT_OWNER_FAILURE = "CREATE_CASHPOINT_OWNER_FAILURE";
export const CREATE_CASHPOINT_OWNER_RESET = "CREATE_CASHPOINT_OWNER_RESET";

export const SEARCH_CASHPOINT_OWNER_START = "SEARCH_CASHPOINT_OWNER_START";
export const SEARCH_CASHPOINT_OWNER_SUCCESS = "SEARCH_CASHPOINT_OWNER_SUCCESS";
export const SEARCH_CASHPOINT_OWNER_FAILURE = "SEARCH_CASHPOINT_OWNER_FAILURE";
export const SEARCH_CASHPOINT_OWNER_RESET = "SEARCH_CASHPOINT_OWNER_RESET";

export const INITIATE_CASHPOINT_DEPOSIT_START =
  "INITIATE_CASHPOINT_DEPOSIT_START";
export const INITIATE_CASHPOINT_DEPOSIT_SUCCESS =
  "INITIATE_CASHPOINT_DEPOSIT_SUCCESS";
export const INITIATE_CASHPOINT_DEPOSIT_FAILURE =
  "INITIATE_CASHPOINT_DEPOSIT_FAILURE";
export const CASHPOINT_DEPOSIT_RESET = "CASHPOINT_DEPOSIT_RESET";

export const GET_CASHPOINT_DEPOSIT_LIST_START =
  "GET_CASHPOINT_DEPOSIT_LIST_START";
export const GET_CASHPOINT_DEPOSIT_LIST_SUCCESS =
  "GET_CASHPOINT_DEPOSIT_LIST_SUCCESS";
export const GET_CASHPOINT_DEPOSIT_LIST_FAILURE =
  "GET_CASHPOINT_DEPOSIT_LIST_FAILURE";

export const APPROVE_CASHPOINT_DEPOSIT_START =
  "APPROVE_CASHPOINT_DEPOSIT_START";
export const APPROVE_CASHPOINT_DEPOSIT_SUCCESS =
  "APPROVE_CASHPOINT_DEPOSIT_SUCCESS";
export const APPROVE_CASHPOINT_DEPOSIT_FAILURE =
  "APPROVE_CASHPOINT_DEPOSIT_FAILURE";

export const CREATE_TELLER_START = "CREATE_TELLER_START";
export const CREATE_TELLER_SUCCESS = "CREATE_TELLER_SUCCESS";
export const CREATE_TELLER_FAILURE = "CREATE_TELLER_FAILURE";
export const CREATE_TELLER_RESET = "CREATE_TELLER_RESET";

export const GET_TELLER_START = "GET_TELLER_START";
export const GET_TELLER_SUCCESS = "GET_TELLER_SUCCESS";
export const GET_TELLER_FAILURE = "GET_TELLER_FAILURE";
export const GET_TELLER_RESET = "GET_TELLER_RESET";

export const SAVE_TELLERS = "SAVE_TELLERS";
export const SAVE_CASHPOINTS = "SAVE_CASHPOINTS";

export const CREATE_CASHPOINT_START = "CREATE_CASHPOINT_START";
export const CREATE_CASHPOINT_SUCCESS = "CREATE_CASHPOINT_SUCCESS";
export const CREATE_CASHPOINT_FAILURE = "CREATE_CASHPOINT_FAILURE";
export const CREATE_CASHPOINT_RESET = "CREATE_CASHPOINT_RESET";

export const RESET_TELLER_START = "RESET_TELLER_START";
export const RESET_TELLER_SUCCESS = "RESET_TELLER_SUCCESS";
export const RESET_TELLER_FAILURE = "RESET_TELLER_FAILURE";

export const UNLOCK_TELLER_START = "UNLOCK_TELLER_START";
export const UNLOCK_TELLER_SUCCESS = "UNLOCK_TELLER_SUCCESS";
export const UNLOCK_TELLER_FAILURE = "UNLOCK_TELLER_FAILURE";
