import {
  CONNECT_USER,
  RECONNECT,
  CONNECT_USER_SUCCESS,
  CONNECT_USER_ERROR,
} from "constants/events";
import { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import InitialStateInterface from "types/initialState";
import UserInterface from "types/user";
import setToken from "redux/actions/real-time-services/setToken";

import socketIOClient from "..";

const typedUseSelectorHook: TypedUseSelectorHook<InitialStateInterface> =
  useSelector;

const useSocketIO = () => {
  const dispatch = useDispatch();
  const { data: userData, loading: loadingUser } = typedUseSelectorHook(
    ({ users }) => ({
      data: users.currentUser.data,
      loading: users.login.loading,
    })
  );

  const connectUser = (data: UserInterface) => {
    if (data?.PID) {
      // const appName =
      //   process.env?.REACT_APP_NAME?.toUpperCase()?.split(/\s/)?.[0];
      const rtsPID = `Customer support (${data?.CountryCode?.toUpperCase()})`;
      // const rtsPID = `${appName}-${data?.CountryCode?.toUpperCase()}`;
      localStorage.setItem("rtsPID", rtsPID);
      socketIOClient.emit(CONNECT_USER, {
        ...data,
        PID: rtsPID,
        clientName: "Admin Web App",
      });
    }
  };

  const connectAllUsers = (data: UserInterface) => {
    // socketIOClient.emit(CONNECT_USER, {
    //   PID: `m-${data.CountryCode?.toLowerCase() || "cm"}-all`,
    //   Country: data.CountryCode,
    //   clientName: "Admin Web App",
    // });
  };

  useEffect(() => {
    if (!loadingUser && userData && Object.keys(userData).length) {
      socketIOClient.off(CONNECT_USER_SUCCESS);
      socketIOClient.off(CONNECT_USER_ERROR);
      connectUser(userData);
      connectAllUsers(userData);
      socketIOClient.off(RECONNECT);
      socketIOClient.on(RECONNECT, () => {
        connectUser(userData);
        connectAllUsers(userData);
      });
      socketIOClient.on(
        CONNECT_USER_SUCCESS,
        async (response: { [key: string]: string }): Promise<void | Error> => {
          localStorage.setItem("rtsToken", response.token);
          return setToken(response.token)(dispatch);
        }
      );
      socketIOClient.on(CONNECT_USER_ERROR, (error: Error) => {
        return error;
      });
    }

    return () => {
      socketIOClient.off(CONNECT_USER_SUCCESS);
      socketIOClient.off(CONNECT_USER_ERROR);
      socketIOClient.off(RECONNECT);
    };
  }, [userData, loadingUser]);
};

export default useSocketIO;
