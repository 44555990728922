import {
  VERIFY_DOCUMENTS_START,
  VERIFY_DOCUMENTS_SUCCESS,
  VERIFY_DOCUMENTS_FAILURE,
} from 'redux/actionTypes/customers/verifyDocumentsActionsTypes';

const verifyDocumentsReducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case VERIFY_DOCUMENTS_START: {
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          verifyKYDoc: {
            DocType: null,
            loading: true,
            error: null,
          },
        },
      };
    }
    case VERIFY_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          data: {
            ...state.selectedCustomer.data,
            ...payload.data,
          },
          verifyKYDoc: {
            DocType: payload.DocType,
            loading: false,
            error: null,
          },
        },
      };
    }

    case VERIFY_DOCUMENTS_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          verifyKYDoc: {
            ...state.selectedCustomer.verifyKYDoc,
            error: payload,
            loading: false,
          },
        },
      };
  }
};
export default verifyDocumentsReducer;
