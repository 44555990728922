import {
  APPROVAL_REQUEST_START,
  APPROVAL_REQUEST_APPROVED,
  APPROVAL_REQUEST_REJECTED,
  APPROVAL_REQUEST_FAILED,
  APPROVAL_REQUEST_SENT,
  APPROVAL_REQUEST_TIMEOUT,
} from 'redux/actionTypes/users/loginApproval';
import { PeerApprovalStatus } from 'types/general.d';
import UsersInitialStateInterface from 'types/users/userInitialState';

const loginApprovalReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case APPROVAL_REQUEST_START:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          approvalStatus: PeerApprovalStatus.APPROVAL_REQUESTED,
        },
      };

    case APPROVAL_REQUEST_APPROVED:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isAuth: true,
          authUser: payload,
          approvalStatus: PeerApprovalStatus.APPROVAL_ACCEPTED,
        },
      };

    case APPROVAL_REQUEST_REJECTED:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          approvalStatus: PeerApprovalStatus.APPROVAL_REJECTED,
        },
      };

    case APPROVAL_REQUEST_SENT:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          approvalStatus: PeerApprovalStatus.APPROVAL_WAITING,
        },
      };

    case APPROVAL_REQUEST_TIMEOUT:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          approvalStatus: PeerApprovalStatus.APPROVAL_TIME_OUT,
        },
      };

    case APPROVAL_REQUEST_FAILED:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          approvalStatus: PeerApprovalStatus.APPROVAL_NONE,
          approvalRequestError: payload,
        },
      };

    default:
      return null;
  }
};

export default loginApprovalReducer;
