import {
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SET_USER_STATUS_START,
  SET_USER_STATUS_SUCCESS,
  SET_USER_STATUS_FAILURE,
} from "redux/actionTypes/users/accountActionsTypes";
import UsersInitialStateInterface from "types/users/userInitialState";

const updateUserReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case UPDATE_USER_START:
      return {
        ...state,
        editUser: {
          data: payload,
          loading: true,
          error: null,
        },
      };

    case UPDATE_USER_SUCCESS: {
      const data = {
        PID: payload.UserPID,
        Email: payload.Email,
        CountryCode: payload.Country,
        MasterAccount: payload.MasterAccount,
        PhoneCountryCode: payload.PhoneCountry,
        PhoneNumber: payload.Phone,
        Prefix: payload.Prefix,
        Phone: payload.PhoneNumber,
      };

      const allUsers = Array.from(state.allUsers.data);

      const userIndex = allUsers.findIndex(
        (user) => user.PID === payload.UserPID
      );

      if (userIndex >= 0) {
        const user = { ...allUsers[userIndex], ...data };
        allUsers[userIndex] = user;
      }

      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          data: allUsers,
        },
        editUser: {
          error: null,
          data: payload,
          loading: false,
        },
      };
    }

    case UPDATE_USER_FAILURE:
      return {
        ...state,
        editUser: {
          data: null,
          loading: false,
          error: payload,
        },
      };

    case SET_USER_STATUS_START:
      return {
        ...state,
        editUserStatus: {
          data: payload,
          loading: true,
          error: null,
        },
      };

    case SET_USER_STATUS_SUCCESS: {
      const allUsers = state.allUsers.data;
      const userIndex = allUsers.findIndex((user) => user.PID === payload.PID);
      const user = allUsers[userIndex];

      if (user) {
        user.UserAccountDisabled =
          (payload.Enable as string).toLowerCase() === "yes" ? "No" : "Yes";
        allUsers[userIndex] = user;
      }

      return {
        ...state,
        allUsers: { ...state.allUsers, data: allUsers },
        editUserStatus: {
          error: null,
          data: payload,
          loading: false,
        },
      };
    }

    case SET_USER_STATUS_FAILURE:
      return {
        ...state,
        editUserStatus: {
          data: null,
          error: payload,
          loading: false,
        },
      };

    default:
      return null;
  }
};

export default updateUserReducer;
