// GET BULK ORGANIZATION LIST
export const GET_MERCHANTS_START = "GET_MERCHANTS_START";
export const GET_MERCHANTS_END = "GET_MERCHANTS_END";
export const GET_MERCHANTS_SUCCESS = "GET_MERCHANTS_SUCCESS";
export const GET_MERCHANTS_FAILURE = "GET_MERCHANTS_FAILURE";

// CREATE BULK ORGANIZATION
export const ADD_UPDATE_MERCHANT_START = "ADD_UPDATE_MERCHANT_START";
export const ADD_UPDATE_MERCHANT_END = "ADD_UPDATE_MERCHANT_END";
export const ADD_UPDATE_MERCHANT_SUCCESS = "ADD_UPDATE_MERCHANT_SUCCESS";
export const ADD_UPDATE_MERCHANT_FAILURE = "ADD_UPDATE_MERCHANT_FAILURE";

export const SET_WALLET_AUTO_PAY_STATUS_START =
  "SET_WALLET_AUTO_PAY_STATUS_START";
export const SET_WALLET_AUTO_PAY_STATUS_END = "SET_WALLET_AUTO_PAY_STATUS_END";
export const SET_WALLET_AUTO_PAY_STATUS_SUCCESS =
  "SET_WALLET_AUTO_PAY_STATUS_SUCCESS";
export const SET_WALLET_AUTO_PAY_STATUS_FAILURE =
  "SET_WALLET_AUTO_PAY_STATUS_FAILURE";
