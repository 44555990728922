import KPIDataReducer from './KPIDataReducer';
import DashboardInitialStateInterface from 'types/dashboard';
import accountBalanceReducer from './accountBalanceReducer';
import KPIGraphDataReducer from './KPIDataGraphReducer'

const dashboardReducer = (
  state: DashboardInitialStateInterface,
  action: any
) => {
  return {
    ...state,
    ...KPIDataReducer(state, action),
    ...accountBalanceReducer(state, action),
    ...KPIGraphDataReducer(state, action)
  };
};

export default dashboardReducer;
