/**
 * utility to get name initials from users names
 */
const getInitials = (...names: Array<string | undefined>) => {
  return names.filter((value) => typeof value === "string" && value.length)
    .length
    ? names
        .filter((value) => typeof value === "string" && value.length)
        .map((name) => name?.trim().charAt(0).toUpperCase())
        .concat("")
    : "+";
};

export const getAvatarColor = () => {
  const characters = "0123456789ABCDEF";
  let colorCode = "#";
  for (let j = 0; j < 6; j++) {
    colorCode += characters[Math.floor(Math.random() * 16)];
  }

  return colorCode;
};

export default getInitials;
