export const kpiGraphTabs: Record<string, string> = {
    "3": "CashCountTab",
    "4": "CashAmountTab",
    "5": "TransferCountTab",
    "6": "TransferAmountTab",
    "7": "PaymentCountTab",
    "8": "PaymentAmountTab",
    "9": "CommissionCountTab",
    "10": "CommissionAmountTab",
}
