const transactionsReportInitialState = {
  transactionsSummary: {
    data: [],
    searchData: null,
    loading: false,
    error: null,
  },
  transactions: {
    data: [],
    searchData: null,
    loading: false,
    error: null,
  },
};

export default transactionsReportInitialState;
