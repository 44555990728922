import userReducer from "./userReducer";
import getUserData from "./getUserData";
import loginReducer from "./loginReducer";
import rolesReducer from "./rolesReducer";
import resetReducer from "./resetReducer";
import logoutReducer from "./logoutReducer";
import updateUserData from "./updateUserData";
import countryReducer from "./countryReducer";
import languageReducer from "./languageReducer";
import editUserReducer from "./editUserReducer";
import locationReducer from "./locationReducer";
import loginApprovalReducer from "./loginApproval";
import updateUserReducer from "./updateUserReducer";
import createUserReducer from "./createUserReducer";
import permissionsReducer from "./permissionsReducer";
import supportedLanguageReducer from "./supportedLanguage";
import UsersInitialStateInterface from "types/users/userInitialState";
import loginStatusReducer from "./loginStatusReducer";

const usersReducer = (state: UsersInitialStateInterface, action: any) => {
  return {
    ...state,
    ...userReducer(state, action),
    ...createUserReducer(state, action),
    ...countryReducer(state, action),
    ...languageReducer(state, action),
    ...loginReducer(state, action),
    ...resetReducer(state, action),
    ...supportedLanguageReducer(state, action),
    ...loginApprovalReducer(state, action),
    ...logoutReducer(state, action),
    ...locationReducer(state, action),
    ...rolesReducer(state, action),
    ...updateUserReducer(state, action),
    ...updateUserData(state, action),
    ...permissionsReducer(state, action),
    ...getUserData(state, action),
    ...editUserReducer(state, action),
    ...loginStatusReducer(state, action),
  };
};

export default usersReducer;
