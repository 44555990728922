import { useIntl } from "react-intl";
import Icon from "@ant-design/icons";
import InitialState from "types/initialState";
import { Row, Col, Typography, Space } from "antd";
import { PermissionLevel } from "constants/enumerators";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import { ReactComponent as PadLockIcon } from "assets/icons/pad-lock.svg";

const { Text } = Typography;
const typedUserSelector: TypedUseSelectorHook<InitialState> = useSelector;

const PageAccessControl: React.FC<{
  children?: React.ReactNode;
  permID?: string;
}> = ({ children, permID }) => {
  const intl = useIntl();
  // get user roles from the store

  const [writePerm, setWritePerm] = useState<boolean>(false);
  const [readPerm, setReadPerm] = useState<boolean>(false);

  const { data: currentUserData, loading } = typedUserSelector(
    ({ users }) => users?.currentUser
  );

  const checkPermission = useCallback(
    (roles: any[]) => {
      for (const role of roles) {
        for (const permission of role.Permissions) {
          // check if user has permissions for a specific task
          if (permission.PermID === permID) {
            // check if user has read only level permission
            if (permission.PermType === PermissionLevel.READ_ONLY) {
              setReadPerm(true);

              // check if user has write level permission
            } else if (permission.PermType === PermissionLevel.WRITE) {
              setWritePerm(true);
            }
            return;
          }
        }
      }
    },
    [permID]
  );

  useEffect(() => {
    if (!loading && currentUserData?.Roles) {
      checkPermission(currentUserData.Roles);
    }
  }, [checkPermission, currentUserData?.Roles, loading]);

  return (
    <>
      {!readPerm && !writePerm ? (
        <Row
          align="middle"
          gutter={[24, 24]}
          justify="space-around"
          style={{
            textAlign: "center",
            minHeight: "70%",
          }}
        >
          <Col>
            <Space direction="vertical" size="large">
              <Icon component={PadLockIcon} style={{ fontSize: "96px" }} />
              <Text className="text-h3">
                {intl.formatMessage({
                  id: "2801",
                  defaultMessage: "You don't have permission to view this page",
                })}
              </Text>
            </Space>
          </Col>
        </Row>
      ) : (
        children
      )}
    </>
  );
};

export default PageAccessControl;
