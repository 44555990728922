import TransactionsReportInterface from "types/transactionsReport";
import getTransactionsForExportReducer from "./getTransactionsForExportReducer";

const transactionsForExportReducer = (
  state: TransactionsReportInterface,
  action: any
) => {
  return {
    ...state,
    ...getTransactionsForExportReducer(state, action),
  };
};

export default transactionsForExportReducer;
