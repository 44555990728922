import { AxiosResponse, default as axiosStatic } from "axios";
import logout from "./logout";

const { REACT_APP_API_URL } = process.env;

interface RefreshTokenResponse {
  LiveToken: string;
  RefreshToken: string;
}

const refreshAccessToken = async () => {
  const response = await axios.post<
    Error,
    AxiosResponse<RefreshTokenResponse[], Error>
  >("RefreshToken", {
    RefreshToken: localStorage.refreshToken,
  });
  const { LiveToken, RefreshToken } = response.data[0];
  if (LiveToken && RefreshToken) {
    localStorage.token = LiveToken;
    localStorage.refreshToken = RefreshToken;
  }
  return LiveToken;
};

const axios = axiosStatic.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.defaults.headers.common["From"] = localStorage.token;
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercept requests to add token
// TODO: verify url before adding token
axios.interceptors.request.use((config) => {
  // TODO: refactor backend to handled token properly
  if (["/OssixCountries"].includes(String(config.url))) {
    config.headers.From = undefined;
  } else {
    config.headers.From = localStorage.token;
  }
  // if (config.method === "post") {}
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.response.data[0].Error === "2034" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      axios.defaults.headers.common["From"] = access_token;
      originalRequest.headers.From = access_token;
      return axios(originalRequest);
    }
    if (
      error.response.status === 401 &&
      error.response.data[0].Error === "2032"
    ) {
      logout();
    }
    return Promise.reject(error);
  }
);

export default axios;
