import UsersInitialStateInterface from "types/users/userInitialState";
import {
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  UPDATE_ROLE_START,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  UPDATE_USER_ROLES_START,
  UPDATE_USER_ROLES_SUCCESS,
  UPDATE_USER_ROLES_FAILURE,
} from "redux/actionTypes/users/roleTypes";

const rolesReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_ROLES_START:
      return {
        ...state,
        allRoles: {
          data: [],
          error: null,
          loading: true,
        },
      };

    case GET_ROLES_SUCCESS:
      return {
        ...state,
        allRoles: {
          error: null,
          data: payload,
          loading: false,
        },
      };

    case GET_ROLES_FAILURE:
      return {
        ...state,
        allRoles: {
          data: [],
          loading: false,
          error: payload,
        },
      };

    case UPDATE_USER_ROLES_START:
      return {
        ...state,
        allRoles: {
          ...state.allRoles,
          loading: true,
        },
      };

    case UPDATE_USER_ROLES_SUCCESS: {
      const users = state.allUsers.data;
      const userIndex = users.findIndex((user) => user.PID === payload.userPID);
      const user = users[userIndex];
      const roles = [];
      for (let index = 0; index < payload.roles.length; index++) {
        const role = state.allRoles.data.find(
          (role) => role.RoleID === payload.roles[index]
        );
        if (role) roles.push(role);
      }
      if (user) {
        user.Roles = roles;
        users[userIndex] = user;
      }
      return {
        ...state,
        allRoles: {
          ...state.allRoles,
          loading: false,
        },
        allUsers: {
          ...state.allUsers,
          data: users,
        },
      };
    }

    case UPDATE_USER_ROLES_FAILURE:
      return {
        ...state,
        allRoles: {
          ...state.allRoles,
          loading: false,
        },
      };

    case UPDATE_ROLE_START:
      return {
        ...state,
        editRole: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case UPDATE_ROLE_SUCCESS:
      const roleAction = payload.action;
      delete payload.action;
      const roles = Array.from(state.allRoles.data);
      const roleIndex = roles.findIndex(
        (role) => role.RoleID === payload.RoleID
      );
      if (roleIndex >= 0) {
        roles[roleIndex] = { ...payload };
      } else {
        roles.push({ ...payload });
      }
      return {
        ...state,
        editRole: {
          loading: false,
          data: { ...payload, action: roleAction },
          error: null,
        },
        allRoles: { ...state.allRoles, data: roles },
      };

    case UPDATE_ROLE_FAILURE:
      return {
        ...state,
        editRole: {
          loading: false,
          data: null,
          error: payload,
        },
      };

    default:
      return null;
  }
};

export default rolesReducer;
