import {
    SEND_EMAIL_START,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAILURE,
    SEND_EMAIL_RESET,
  } from "redux/actionTypes/email/index";
  import { CashPointsInitialStateInterface } from "types/initialState";
  
  const sendEmail = (
    state: CashPointsInitialStateInterface,
    { type, payload }: Record<string, any>
  ) => {
    switch (type) {
      case SEND_EMAIL_START:
        return {
          ...state,
          sendEmailRequest: { loading: true, data: null, error: null },
        };
  
      case SEND_EMAIL_SUCCESS:
        return {
          ...state,
          sendEmailRequest: { loading: false, data: payload, error: null },
        };
      case SEND_EMAIL_FAILURE:
        return {
          ...state,
          sendEmailRequest: { loading: false, data: null, error: null },
        };
  
      case SEND_EMAIL_RESET:
        return {
          ...state,
          sendEmailRequest: { loading: false, data: null, error: null },
        };
  
      default:
        return null;
    }
  };
  
  export default sendEmail;
  