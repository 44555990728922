import {
  UPDATE_USER_DATA_START,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAILURE,
} from 'redux/actionTypes/users/updateData';
import UsersInitialStateInterface from 'types/users/userInitialState';

const updateUserReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case UPDATE_USER_DATA_START:
      return {
        updateUserData: {
          loading: true,
        },
      };
    case UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: payload,
        },
        updateUserData: {
          loading: false,
          message: 'Data updated',
        },
      };
    case UPDATE_USER_DATA_FAILURE:
      return {
        updateUserData: {
          ...state,
          loading: false,
        },
      };
    default:
      return null;
  }
};

export default updateUserReducer;
