import {
  GET_PERMISSIONS_START,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILURE,
} from 'redux/actionTypes/users/roleTypes';
import UsersInitialStateInterface from 'types/users/userInitialState.d';

const permissionsReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_PERMISSIONS_START:
      return {
        ...state,
        allPermissions: { data: [], error: null, loading: true },
      };
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        allPermissions: { data: payload.data, error: null, loading: false },
      };
    case GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        allPermissions: { data: [], error: payload, loading: false },
      };

    default:
      return null;
  }
};

export default permissionsReducer;
