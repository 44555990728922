import { GlobalSettingsInitialInterface } from 'types/initialState';
import getGlobalSettingReducer from './getGlobalSettingsReducer';
import getBuiltinConfigData from './getBuiltinConfigData';
import setGlobalSettingsReducer from './setGlobalSettingReducer';

const globalSettingsReducer = (
  state: GlobalSettingsInitialInterface,
  action: any
) => {
  return {
    ...state,
    ...getGlobalSettingReducer(state, action),
    ...getBuiltinConfigData(state, action),
    ...setGlobalSettingsReducer(state, action),
  };
};

export default globalSettingsReducer;
