import {
  SAVE_USER_ID_DATA_START,
  SAVE_USER_ID_DATA_SUCCESS,
  SAVE_USER_ID_DATA_FAILURE,
} from "redux/actionTypes/customers/saveUserIdDataActionTypes";
import CustomersInitialStateInterface from "types/Customer/CustomerInitialState";

const saveUserIDData = (
  state: CustomersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case SAVE_USER_ID_DATA_START: {
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          saveUserIDData: {
            messag: null,
            loading: true,
            error: null,
          },
        },
      };
    }
    case SAVE_USER_ID_DATA_SUCCESS: {
      let { selectedCustomer } = state;
      selectedCustomer = {
        ...selectedCustomer,
        //@ts-expect-error
        data: {
          ...selectedCustomer.data,
          KYCDocReceived: "YES",
          IDCardInfo: {
            ...selectedCustomer.data!.IDCardInfo,
            ...payload.requestData,
            IssueDate: payload.requestData.DOIssue,
          },
        },
      };

      return {
        ...state,
        selectedCustomer: {
          ...selectedCustomer,
          saveUserIDData: {
            message: payload.message,
            loading: false,
            error: null,
            success: true,
          },
        },
      };
    }

    case SAVE_USER_ID_DATA_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          saveUserIDData: {
            ...state.selectedCustomer.saveUserIDData,
            error: payload,
            loading: false,
          },
        },
      };
  }
};
export default saveUserIDData;
