export type Country = {
  countryName: string;
  countryCode: string;
  callingCode: number;
  flag: string;
};

export default [
  {
    countryName: "Afghanistan",
    countryCode: "AF",
    callingCode: 93,
    flag: require("assets/images/country-flags/24/af.png").default,
  },
  {
    countryName: "Albania",
    countryCode: "AL",
    callingCode: 355,
    flag: require("assets/images/country-flags/24/al.png").default,
  },
  {
    countryName: "Algeria",
    countryCode: "DZ",
    callingCode: 213,
    flag: require("assets/images/country-flags/24/dz.png").default,
  },
  {
    countryName: "American Samoa",
    countryCode: "AS",
    callingCode: 1684,
    flag: require("assets/images/country-flags/24/as.png").default,
  },
  {
    countryName: "Andorra",
    countryCode: "AD",
    callingCode: 376,
    flag: require("assets/images/country-flags/24/ad.png").default,
  },
  {
    countryName: "Angola",
    countryCode: "AO",
    callingCode: 244,
    flag: require("assets/images/country-flags/24/ao.png").default,
  },
  {
    countryName: "Anguilla",
    countryCode: "AI",
    callingCode: 1264,
    flag: require("assets/images/country-flags/24/ai.png").default,
  },

  {
    countryName: "Antigua and Barbuda",
    countryCode: "AG",
    callingCode: 1268,
    flag: require("assets/images/country-flags/24/ag.png").default,
  },
  {
    countryName: "Argentina",
    countryCode: "AR",
    callingCode: 54,
    flag: require("assets/images/country-flags/24/ar.png").default,
  },
  {
    countryName: "Armenia",
    countryCode: "AM",
    callingCode: 374,
    flag: require("assets/images/country-flags/24/am.png").default,
  },
  {
    countryName: "Aruba",
    countryCode: "AW",
    callingCode: 297,
    flag: require("assets/images/country-flags/24/aw.png").default,
  },
  {
    countryName: "Australia",
    countryCode: "AU",
    callingCode: 61,
    flag: require("assets/images/country-flags/24/au.png").default,
  },
  {
    countryName: "Austria",
    countryCode: "AT",
    callingCode: 43,
    flag: require("assets/images/country-flags/24/at.png").default,
  },
  {
    countryName: "Azerbaijan",
    countryCode: "AZ",
    callingCode: 994,
    flag: require("assets/images/country-flags/24/az.png").default,
  },
  {
    countryName: "Bahamas",
    countryCode: "BS",
    callingCode: 1242,
    flag: require("assets/images/country-flags/24/bs.png").default,
  },
  {
    countryName: "Bahrain",
    countryCode: "BH",
    callingCode: 973,
    flag: require("assets/images/country-flags/24/bh.png").default,
  },
  {
    countryName: "Bangladesh",
    countryCode: "BD",
    callingCode: 880,
    flag: require("assets/images/country-flags/24/bd.png").default,
  },
  {
    countryName: "Barbados",
    countryCode: "BB",
    callingCode: 1246,
    flag: require("assets/images/country-flags/24/bb.png").default,
  },
  {
    countryName: "Belarus",
    countryCode: "BY",
    callingCode: 375,
    flag: require("assets/images/country-flags/24/by.png").default,
  },
  {
    countryName: "Belgium",
    countryCode: "BE",
    callingCode: 32,
    flag: require("assets/images/country-flags/24/be.png").default,
  },
  {
    countryName: "Belize",
    countryCode: "BZ",
    callingCode: 501,
    flag: require("assets/images/country-flags/24/bz.png").default,
  },
  {
    countryName: "Benin",
    countryCode: "BJ",
    callingCode: 229,
    flag: require("assets/images/country-flags/24/bj.png").default,
  },
  {
    countryName: "Bermuda",
    countryCode: "BM",
    callingCode: 1441,
    flag: require("assets/images/country-flags/24/bm.png").default,
  },
  {
    countryName: "Bhutan",
    countryCode: "BT",
    callingCode: 975,
    flag: require("assets/images/country-flags/24/bt.png").default,
  },
  {
    countryName: "Bolivia",
    countryCode: "BO",
    callingCode: 591,
    flag: require("assets/images/country-flags/24/bo.png").default,
  },
  {
    countryName: "Bosnia and Herzegovina",
    countryCode: "BA",
    callingCode: 387,
    flag: require("assets/images/country-flags/24/ba.png").default,
  },
  {
    countryName: "Botswana",
    countryCode: "BW",
    callingCode: 267,
    flag: require("assets/images/country-flags/24/bw.png").default,
  },
  {
    countryName: "Brazil",
    countryCode: "BR",
    callingCode: 55,
    flag: require("assets/images/country-flags/24/br.png").default,
  },
  {
    countryName: "British Indian Ocean Territory",
    countryCode: "IO",
    callingCode: 246,
    flag: require("assets/images/country-flags/24/io.png").default,
  },
  {
    countryName: "Brunei",
    countryCode: "BN",
    callingCode: 673,
    flag: require("assets/images/country-flags/24/bn.png").default,
  },
  {
    countryName: "Bulgaria",
    countryCode: "BG",
    callingCode: 359,
    flag: require("assets/images/country-flags/24/bg.png").default,
  },
  {
    countryName: "Burkina Faso",
    countryCode: "BF",
    callingCode: 226,
    flag: require("assets/images/country-flags/24/bf.png").default,
  },
  {
    countryName: "Burundi",
    countryCode: "BI",
    callingCode: 257,
    flag: require("assets/images/country-flags/24/bi.png").default,
  },
  {
    countryName: "Cambodia",
    countryCode: "KH",
    callingCode: 855,
    flag: require("assets/images/country-flags/24/kh.png").default,
  },
  {
    countryName: "Cameroon",
    countryCode: "CM",
    callingCode: 237,
    flag: require("assets/images/country-flags/24/cm.png").default,
  },
  {
    countryName: "Canada",
    countryCode: "CA",
    callingCode: 1,
    flag: require("assets/images/country-flags/24/ca.png").default,
  },
  {
    countryName: "Cape Verde",
    countryCode: "CV",
    callingCode: 238,
    flag: require("assets/images/country-flags/24/cv.png").default,
  },
  {
    countryName: "Cayman Islands",
    countryCode: "KY",
    callingCode: 1345,
    flag: require("assets/images/country-flags/24/ky.png").default,
  },
  {
    countryName: "Central African Republic",
    countryCode: "CF",
    callingCode: 236,
    flag: require("assets/images/country-flags/24/cf.png").default,
  },
  {
    countryName: "Chad",
    countryCode: "TD",
    callingCode: 235,
    flag: require("assets/images/country-flags/24/td.png").default,
  },
  {
    countryName: "Chile",
    countryCode: "CL",
    callingCode: 56,
    flag: require("assets/images/country-flags/24/cl.png").default,
  },
  {
    countryName: "China",
    countryCode: "CN",
    callingCode: 86,
    flag: require("assets/images/country-flags/24/cn.png").default,
  },
  {
    countryName: "Christmas Island",
    countryCode: "CX",
    callingCode: 61,
    flag: require("assets/images/country-flags/24/cx.png").default,
  },
  {
    countryName: "Cocos (Keeling) Islands",
    countryCode: "CC",
    callingCode: 61,
    flag: require("assets/images/country-flags/24/cc.png").default,
  },
  {
    countryName: "Colombia",
    countryCode: "CO",
    callingCode: 57,
    flag: require("assets/images/country-flags/24/co.png").default,
  },
  {
    countryName: "Comoros",
    countryCode: "KM",
    callingCode: 269,
    flag: require("assets/images/country-flags/24/km.png").default,
  },
  {
    countryName: "Congo",
    countryCode: "CG",
    callingCode: 242,
    flag: require("assets/images/country-flags/24/cg.png").default,
  },
  {
    countryName: "Cook Islands",
    countryCode: "CK",
    callingCode: 682,
    flag: require("assets/images/country-flags/24/ck.png").default,
  },
  {
    countryName: "Costa Rica",
    countryCode: "CR",
    callingCode: 506,
    flag: require("assets/images/country-flags/24/cr.png").default,
  },
  {
    countryName: "Croatia",
    countryCode: "HR",
    callingCode: 385,
    flag: require("assets/images/country-flags/24/hr.png").default,
  },
  {
    countryName: "Cuba",
    countryCode: "CU",
    callingCode: 53,
    flag: require("assets/images/country-flags/24/cu.png").default,
  },
  {
    countryName: "Cyprus",
    countryCode: "CY",
    callingCode: 357,
    flag: require("assets/images/country-flags/24/cy.png").default,
  },
  {
    countryName: "Czech Republic",
    countryCode: "CZ",
    callingCode: 420,
    flag: require("assets/images/country-flags/24/cz.png").default,
  },
  {
    countryName: "Denmark",
    countryCode: "DK",
    callingCode: 45,
    flag: require("assets/images/country-flags/24/dk.png").default,
  },
  {
    countryName: "Djibouti",
    countryCode: "DJ",
    callingCode: 253,
    flag: require("assets/images/country-flags/24/dj.png").default,
  },
  {
    countryName: "Dominica",
    countryCode: "DM",
    callingCode: 1767,
    flag: require("assets/images/country-flags/24/dm.png").default,
  },
  {
    countryName: "Dominican Republic",
    countryCode: "DO",
    callingCode: 1849,
    flag: require("assets/images/country-flags/24/do.png").default,
  },
  {
    countryName: "East Timor",
    countryCode: "TP",
    callingCode: 670,
  },
  {
    countryName: "Ecuador",
    countryCode: "EC",
    callingCode: 593,
    flag: require("assets/images/country-flags/24/ec.png").default,
  },
  {
    countryName: "Egypt",
    countryCode: "EG",
    callingCode: 20,
    flag: require("assets/images/country-flags/24/eg.png").default,
  },
  {
    countryName: "El Salvador",
    countryCode: "SV",
    callingCode: 503,
    flag: require("assets/images/country-flags/24/sv.png").default,
  },
  {
    countryName: "Equatorial Guinea",
    countryCode: "GQ",
    callingCode: 240,
    flag: require("assets/images/country-flags/24/gq.png").default,
  },
  {
    countryName: "Eritrea",
    countryCode: "ER",
    callingCode: 291,
    flag: require("assets/images/country-flags/24/er.png").default,
  },
  {
    countryName: "Estonia",
    countryCode: "EE",
    callingCode: 372,
    flag: require("assets/images/country-flags/24/ee.png").default,
  },
  {
    countryName: "Ethiopia",
    countryCode: "ET",
    callingCode: 251,
    flag: require("assets/images/country-flags/24/et.png").default,
  },
  {
    countryName: "Falkland Islands",
    countryCode: "FK",
    callingCode: 500,
    flag: require("assets/images/country-flags/24/fk.png").default,
  },
  {
    countryName: "Faroe Islands",
    countryCode: "FO",
    callingCode: 298,
    flag: require("assets/images/country-flags/24/fo.png").default,
  },
  {
    countryName: "Fiji Islands",
    countryCode: "FJ",
    callingCode: 679,
    flag: require("assets/images/country-flags/24/fj.png").default,
  },
  {
    countryName: "Finland",
    countryCode: "FI",
    callingCode: 358,
    flag: require("assets/images/country-flags/24/fi.png").default,
  },
  {
    countryName: "France",
    countryCode: "FR",
    callingCode: 33,
    flag: require("assets/images/country-flags/24/fr.png").default,
  },
  {
    countryName: "French Guiana",
    countryCode: "GF",
    callingCode: 594,
    flag: require("assets/images/country-flags/24/gf.png").default,
  },
  {
    countryName: "French Polynesia",
    countryCode: "PF",
    callingCode: 689,
    flag: require("assets/images/country-flags/24/pf.png").default,
  },

  {
    countryName: "Gabon",
    countryCode: "GA",
    callingCode: 241,
    flag: require("assets/images/country-flags/24/ga.png").default,
  },
  {
    countryName: "Gambia",
    countryCode: "GM",
    callingCode: 220,
    flag: require("assets/images/country-flags/24/gm.png").default,
  },
  {
    countryName: "Georgia",
    countryCode: "GE",
    callingCode: 995,
    flag: require("assets/images/country-flags/24/ge.png").default,
  },
  {
    countryName: "Germany",
    countryCode: "DE",
    callingCode: 49,
    flag: require("assets/images/country-flags/24/de.png").default,
  },
  {
    countryName: "Ghana",
    countryCode: "GH",
    callingCode: 233,
    flag: require("assets/images/country-flags/24/gh.png").default,
  },
  {
    countryName: "Gibraltar",
    countryCode: "GI",
    callingCode: 350,
    flag: require("assets/images/country-flags/24/gi.png").default,
  },
  {
    countryName: "Greece",
    countryCode: "GR",
    callingCode: 30,
    flag: require("assets/images/country-flags/24/gr.png").default,
  },
  {
    countryName: "Greenland",
    countryCode: "GL",
    callingCode: 299,
    flag: require("assets/images/country-flags/24/gl.png").default,
  },
  {
    countryName: "Grenada",
    countryCode: "GD",
    callingCode: 1473,
    flag: require("assets/images/country-flags/24/gd.png").default,
  },
  {
    countryName: "Guadeloupe",
    countryCode: "GP",
    callingCode: 590,
    flag: require("assets/images/country-flags/24/gp.png").default,
  },
  {
    countryName: "Guam",
    countryCode: "GU",
    callingCode: 1671,
    flag: require("assets/images/country-flags/24/gu.png").default,
  },
  {
    countryName: "Guatemala",
    countryCode: "GT",
    callingCode: 502,
    flag: require("assets/images/country-flags/24/gt.png").default,
  },
  {
    countryName: "Guernsey",
    countryCode: "GG",
    callingCode: 44,
    flag: require("assets/images/country-flags/24/gg.png").default,
  },
  {
    countryName: "Guinea",
    countryCode: "GN",
    callingCode: 224,
    flag: require("assets/images/country-flags/24/gn.png").default,
  },
  {
    countryName: "Guinea-Bissau",
    countryCode: "GW",
    callingCode: 245,
    flag: require("assets/images/country-flags/24/gw.png").default,
  },
  {
    countryName: "Guyana",
    countryCode: "GY",
    callingCode: 592,
    flag: require("assets/images/country-flags/24/gy.png").default,
  },
  {
    countryName: "Haiti",
    countryCode: "HT",
    callingCode: 509,
    flag: require("assets/images/country-flags/24/ht.png").default,
  },
  {
    countryName: "Heard Island and McDonald Islands",
    countryCode: "HM",
    callingCode: 672,
    flag: require("assets/images/country-flags/24/hm.png").default,
  },
  {
    countryName: "Holy See (Vatican City State)",
    countryCode: "VA",
    callingCode: 379,
    flag: require("assets/images/country-flags/24/va.png").default,
  },
  {
    countryName: "Honduras",
    countryCode: "HN",
    callingCode: 504,
    flag: require("assets/images/country-flags/24/hn.png").default,
  },
  {
    countryName: "Hong Kong",
    countryCode: "HK",
    callingCode: 852,
    flag: require("assets/images/country-flags/24/hk.png").default,
  },
  {
    countryName: "Hungary",
    countryCode: "HU",
    callingCode: 36,
    flag: require("assets/images/country-flags/24/hu.png").default,
  },
  {
    countryName: "Iceland",
    countryCode: "IS",
    callingCode: 354,
    flag: require("assets/images/country-flags/24/is.png").default,
  },
  {
    countryName: "India",
    countryCode: "IN",
    callingCode: 91,
    flag: require("assets/images/country-flags/24/in.png").default,
  },
  {
    countryName: "Indonesia",
    countryCode: "ID",
    callingCode: 62,
    flag: require("assets/images/country-flags/24/id.png").default,
  },
  {
    countryName: "Iran",
    countryCode: "IR",
    callingCode: 98,
    flag: require("assets/images/country-flags/24/ir.png").default,
  },
  {
    countryName: "Iraq",
    countryCode: "IQ",
    callingCode: 964,
    flag: require("assets/images/country-flags/24/iq.png").default,
  },
  {
    countryName: "Ireland",
    countryCode: "IE",
    callingCode: 353,
    flag: require("assets/images/country-flags/24/ie.png").default,
  },
  {
    countryName: "Isle of Man",
    countryCode: "IM",
    callingCode: 44,
    flag: require("assets/images/country-flags/24/im.png").default,
  },
  {
    countryName: "Israel",
    countryCode: "IL",
    callingCode: 972,
    flag: require("assets/images/country-flags/24/il.png").default,
  },
  {
    countryName: "Italy",
    countryCode: "IT",
    callingCode: 39,
    flag: require("assets/images/country-flags/24/it.png").default,
  },
  {
    countryName: "Ivory Coast",
    countryCode: "CI",
    callingCode: 225,
    flag: require("assets/images/country-flags/24/ci.png").default,
  },
  {
    countryName: "Jamaica",
    countryCode: "JM",
    callingCode: 1876,
    flag: require("assets/images/country-flags/24/jm.png").default,
  },
  {
    countryName: "Japan",
    countryCode: "JP",
    callingCode: 81,
    flag: require("assets/images/country-flags/24/jp.png").default,
  },
  {
    countryName: "Jersey",
    countryCode: "JE",
    callingCode: 44,
    flag: require("assets/images/country-flags/24/je.png").default,
  },
  {
    countryName: "Jordan",
    countryCode: "JO",
    callingCode: 962,
    flag: require("assets/images/country-flags/24/jo.png").default,
  },
  {
    countryName: "Kazakhstan",
    countryCode: "KZ",
    callingCode: 7,
    flag: require("assets/images/country-flags/24/kz.png").default,
  },
  {
    countryName: "Kenya",
    countryCode: "KE",
    callingCode: 254,
    flag: require("assets/images/country-flags/24/ke.png").default,
  },
  {
    countryName: "Kiribati",
    countryCode: "KI",
    callingCode: 686,
    flag: require("assets/images/country-flags/24/ki.png").default,
  },
  {
    countryName: "Kuwait",
    countryCode: "KW",
    callingCode: 965,
    flag: require("assets/images/country-flags/24/kw.png").default,
  },
  {
    countryName: "Kyrgyzstan",
    countryCode: "KG",
    callingCode: 996,
    flag: require("assets/images/country-flags/24/kg.png").default,
  },
  {
    countryName: "Laos",
    countryCode: "LA",
    callingCode: 856,
    flag: require("assets/images/country-flags/24/la.png").default,
  },
  {
    countryName: "Latvia",
    countryCode: "LV",
    callingCode: 371,
    flag: require("assets/images/country-flags/24/lv.png").default,
  },
  {
    countryName: "Lebanon",
    countryCode: "LB",
    callingCode: 961,
    flag: require("assets/images/country-flags/24/lb.png").default,
  },
  {
    countryName: "Lesotho",
    countryCode: "LS",
    callingCode: 266,
    flag: require("assets/images/country-flags/24/ls.png").default,
  },
  {
    countryName: "Liberia",
    countryCode: "LR",
    callingCode: 231,
    flag: require("assets/images/country-flags/24/lr.png").default,
  },
  {
    countryName: "Libyan Arab Jamahiriya",
    countryCode: "LY",
    callingCode: 218,
    flag: require("assets/images/country-flags/24/ly.png").default,
  },
  {
    countryName: "Liechtenstein",
    countryCode: "LI",
    callingCode: 423,
    flag: require("assets/images/country-flags/24/li.png").default,
  },
  {
    countryName: "Lithuania",
    countryCode: "LT",
    callingCode: 370,
    flag: require("assets/images/country-flags/24/lt.png").default,
  },
  {
    countryName: "Luxembourg",
    countryCode: "LU",
    callingCode: 352,
    flag: require("assets/images/country-flags/24/lu.png").default,
  },
  {
    countryName: "Macao",
    countryCode: "MO",
    callingCode: 853,
    flag: require("assets/images/country-flags/24/mo.png").default,
  },
  {
    countryName: "North Macedonia",
    countryCode: "MK",
    callingCode: 389,
    flag: require("assets/images/country-flags/24/mk.png").default,
  },
  {
    countryName: "Madagascar",
    countryCode: "MG",
    callingCode: 261,
    flag: require("assets/images/country-flags/24/mg.png").default,
  },
  {
    countryName: "Malawi",
    countryCode: "MW",
    callingCode: 265,
    flag: require("assets/images/country-flags/24/mw.png").default,
  },
  {
    countryName: "Malaysia",
    countryCode: "MY",
    callingCode: 60,
    flag: require("assets/images/country-flags/24/my.png").default,
  },
  {
    countryName: "Maldives",
    countryCode: "MV",
    callingCode: 960,
    flag: require("assets/images/country-flags/24/mv.png").default,
  },
  {
    countryName: "Mali",
    countryCode: "ML",
    callingCode: 223,
    flag: require("assets/images/country-flags/24/ml.png").default,
  },
  {
    countryName: "Malta",
    countryCode: "MT",
    callingCode: 356,
    flag: require("assets/images/country-flags/24/mt.png").default,
  },
  {
    countryName: "Marshall Islands",
    countryCode: "MH",
    callingCode: 692,
    flag: require("assets/images/country-flags/24/mh.png").default,
  },
  {
    countryName: "Martinique",
    countryCode: "MQ",
    callingCode: 596,
    flag: require("assets/images/country-flags/24/mq.png").default,
  },
  {
    countryName: "Mauritania",
    countryCode: "MR",
    callingCode: 222,
    flag: require("assets/images/country-flags/24/mr.png").default,
  },
  {
    countryName: "Mauritius",
    countryCode: "MU",
    callingCode: 230,
    flag: require("assets/images/country-flags/24/mu.png").default,
  },
  {
    countryName: "Mayotte",
    countryCode: "YT",
    callingCode: 262,
    flag: require("assets/images/country-flags/24/yt.png").default,
  },
  {
    countryName: "Mexico",
    countryCode: "MX",
    callingCode: 52,
    flag: require("assets/images/country-flags/24/mx.png").default,
  },
  {
    countryName: "Micronesia, Federated States of",
    countryCode: "FM",
    callingCode: 691,
    flag: require("assets/images/country-flags/24/fm.png").default,
  },
  {
    countryName: "Moldova",
    countryCode: "MD",
    callingCode: 373,
    flag: require("assets/images/country-flags/24/md.png").default,
  },
  {
    countryName: "Monaco",
    countryCode: "MC",
    callingCode: 377,
    flag: require("assets/images/country-flags/24/mc.png").default,
  },
  {
    countryName: "Mongolia",
    countryCode: "MN",
    callingCode: 976,
    flag: require("assets/images/country-flags/24/mn.png").default,
  },
  {
    countryName: "Montenegro",
    countryCode: "ME",
    callingCode: 382,
    flag: require("assets/images/country-flags/24/me.png").default,
  },
  {
    countryName: "Montserrat",
    countryCode: "MS",
    callingCode: 1664,
    flag: require("assets/images/country-flags/24/ms.png").default,
  },
  {
    countryName: "Morocco",
    countryCode: "MA",
    callingCode: 212,
    flag: require("assets/images/country-flags/24/ma.png").default,
  },
  {
    countryName: "Mozambique",
    countryCode: "MZ",
    callingCode: 258,
    flag: require("assets/images/country-flags/24/mz.png").default,
  },
  {
    countryName: "Myanmar",
    countryCode: "MM",
    callingCode: 95,
    flag: require("assets/images/country-flags/24/mm.png").default,
  },
  {
    countryName: "Namibia",
    countryCode: "NA",
    callingCode: 264,
    flag: require("assets/images/country-flags/24/na.png").default,
  },
  {
    countryName: "Nauru",
    countryCode: "NR",
    callingCode: 674,
    flag: require("assets/images/country-flags/24/nr.png").default,
  },
  {
    countryName: "Nepal",
    countryCode: "NP",
    callingCode: 977,
    flag: require("assets/images/country-flags/24/np.png").default,
  },
  {
    countryName: "Netherlands",
    countryCode: "NL",
    callingCode: 31,
    flag: require("assets/images/country-flags/24/nl.png").default,
  },
  {
    countryName: "Netherlands Antilles",
    countryCode: "AN",
    callingCode: 599,
    flag: require("assets/images/country-flags/24/an.png").default,
  },
  {
    countryName: "New Caledonia",
    countryCode: "NC",
    callingCode: 687,
    flag: require("assets/images/country-flags/24/nc.png").default,
  },
  {
    countryName: "New Zealand",
    countryCode: "NZ",
    callingCode: 64,
    flag: require("assets/images/country-flags/24/nz.png").default,
  },
  {
    countryName: "Nicaragua",
    countryCode: "NI",
    callingCode: 505,
    flag: require("assets/images/country-flags/24/ni.png").default,
  },
  {
    countryName: "Niger",
    countryCode: "NE",
    callingCode: 227,
    flag: require("assets/images/country-flags/24/ne.png").default,
  },
  {
    countryName: "Nigeria",
    countryCode: "NG",
    callingCode: 234,
    flag: require("assets/images/country-flags/24/ng.png").default,
  },
  {
    countryName: "Niue",
    countryCode: "NU",
    callingCode: 683,
    flag: require("assets/images/country-flags/24/nu.png").default,
  },
  {
    countryName: "Norfolk Island",
    countryCode: "NF",
    callingCode: 672,
    flag: require("assets/images/country-flags/24/nf.png").default,
  },
  {
    countryName: "North Korea",
    countryCode: "KP",
    callingCode: 850,
    flag: require("assets/images/country-flags/24/kp.png").default,
  },
  {
    countryName: "Northern Ireland",
    countryCode: "GB",
    callingCode: 44,
    flag: require("assets/images/country-flags/24/gb.png").default,
  },
  {
    countryName: "Northern Mariana Islands",
    countryCode: "MP",
    callingCode: 1670,
    flag: require("assets/images/country-flags/24/mp.png").default,
  },
  {
    countryName: "Norway",
    countryCode: "NO",
    callingCode: 47,
    flag: require("assets/images/country-flags/24/no.png").default,
  },
  {
    countryName: "Oman",
    countryCode: "OM",
    callingCode: 968,
    flag: require("assets/images/country-flags/24/om.png").default,
  },
  {
    countryName: "Pakistan",
    countryCode: "PK",
    callingCode: 92,
    flag: require("assets/images/country-flags/24/pk.png").default,
  },
  {
    countryName: "Palau",
    countryCode: "PW",
    callingCode: 680,
    flag: require("assets/images/country-flags/24/pw.png").default,
  },
  {
    countryName: "Palestine",
    countryCode: "PS",
    callingCode: 970,
    flag: require("assets/images/country-flags/24/ps.png").default,
  },
  {
    countryName: "Panama",
    countryCode: "PA",
    callingCode: 507,
    flag: require("assets/images/country-flags/24/pa.png").default,
  },
  {
    countryName: "Papua New Guinea",
    countryCode: "PG",
    callingCode: 675,
    flag: require("assets/images/country-flags/24/pg.png").default,
  },
  {
    countryName: "Paraguay",
    countryCode: "PY",
    callingCode: 595,
    flag: require("assets/images/country-flags/24/py.png").default,
  },
  {
    countryName: "Peru",
    countryCode: "PE",
    callingCode: 51,
    flag: require("assets/images/country-flags/24/pe.png").default,
  },
  {
    countryName: "Philippines",
    countryCode: "PH",
    callingCode: 63,
    flag: require("assets/images/country-flags/24/ph.png").default,
  },
  {
    countryName: "Pitcairn",
    countryCode: "PN",
    callingCode: 64,
    flag: require("assets/images/country-flags/24/pn.png").default,
  },
  {
    countryName: "Poland",
    countryCode: "PL",
    callingCode: 48,
    flag: require("assets/images/country-flags/24/pl.png").default,
  },
  {
    countryName: "Portugal",
    countryCode: "PT",
    callingCode: 351,
    flag: require("assets/images/country-flags/24/pt.png").default,
  },
  {
    countryName: "Puerto Rico",
    countryCode: "PR",
    callingCode: 1939,
    flag: require("assets/images/country-flags/24/pr.png").default,
  },
  {
    countryName: "Qatar",
    countryCode: "QA",
    callingCode: 974,
    flag: require("assets/images/country-flags/24/qa.png").default,
  },
  {
    countryName: "Reunion",
    countryCode: "RE",
    callingCode: 262,
    flag: require("assets/images/country-flags/24/re.png").default,
  },
  {
    countryName: "Romania",
    countryCode: "RO",
    callingCode: 40,
    flag: require("assets/images/country-flags/24/ro.png").default,
  },
  {
    countryName: "Russian Federation",
    countryCode: "RU",
    callingCode: 7,
    flag: require("assets/images/country-flags/24/ru.png").default,
  },
  {
    countryName: "Rwanda",
    countryCode: "RW",
    callingCode: 250,
    flag: require("assets/images/country-flags/24/rw.png").default,
  },
  {
    countryName: "Saint Helena",
    countryCode: "SH",
    callingCode: 290,
    flag: require("assets/images/country-flags/24/sh.png").default,
  },
  {
    countryName: "Saint Kitts and Nevis",
    countryCode: "KN",
    callingCode: 1869,
    flag: require("assets/images/country-flags/24/kn.png").default,
  },
  {
    countryName: "Saint Lucia",
    countryCode: "LC",
    callingCode: 1758,
    flag: require("assets/images/country-flags/24/lc.png").default,
  },
  {
    countryName: "Saint Pierre and Miquelon",
    countryCode: "PM",
    callingCode: 508,
    flag: require("assets/images/country-flags/24/pm.png").default,
  },
  {
    countryName: "Saint Vincent and the Grenadines",
    countryCode: "VC",
    callingCode: 1784,
    flag: require("assets/images/country-flags/24/vc.png").default,
  },
  {
    countryName: "Samoa",
    countryCode: "WS",
    callingCode: 685,
    flag: require("assets/images/country-flags/24/ws.png").default,
  },
  {
    countryName: "San Marino",
    countryCode: "SM",
    callingCode: 378,
    flag: require("assets/images/country-flags/24/sm.png").default,
  },
  {
    countryName: "Sao Tome and Principe",
    countryCode: "ST",
    callingCode: 239,
    flag: require("assets/images/country-flags/24/st.png").default,
  },
  {
    countryName: "Saudi Arabia",
    countryCode: "SA",
    callingCode: 966,
    flag: require("assets/images/country-flags/24/sa.png").default,
  },
  {
    countryName: "Senegal",
    countryCode: "SN",
    callingCode: 221,
    flag: require("assets/images/country-flags/24/sn.png").default,
  },
  {
    countryName: "Serbia",
    countryCode: "RS",
    callingCode: 381,
    flag: require("assets/images/country-flags/24/rs.png").default,
  },
  {
    countryName: "Seychelles",
    countryCode: "SC",
    callingCode: 248,
    flag: require("assets/images/country-flags/24/sc.png").default,
  },
  {
    countryName: "Sierra Leone",
    countryCode: "SL",
    callingCode: 232,
    flag: require("assets/images/country-flags/24/sl.png").default,
  },
  {
    countryName: "Singapore",
    countryCode: "SG",
    callingCode: 65,
    flag: require("assets/images/country-flags/24/sg.png").default,
  },
  {
    countryName: "Slovakia",
    countryCode: "SK",
    callingCode: 421,
    flag: require("assets/images/country-flags/24/sk.png").default,
  },
  {
    countryName: "Slovenia",
    countryCode: "SI",
    callingCode: 386,
    flag: require("assets/images/country-flags/24/si.png").default,
  },
  {
    countryName: "Solomon Islands",
    countryCode: "SB",
    callingCode: 677,
    flag: require("assets/images/country-flags/24/sb.png").default,
  },
  {
    countryName: "Somalia",
    countryCode: "SO",
    callingCode: 252,
    flag: require("assets/images/country-flags/24/so.png").default,
  },
  {
    countryName: "South Africa",
    countryCode: "ZA",
    callingCode: 27,
    flag: require("assets/images/country-flags/24/za.png").default,
  },
  {
    countryName: "South Georgia and the South Sandwich Islands",
    countryCode: "GS",
    callingCode: 500,
    flag: require("assets/images/country-flags/24/gs.png").default,
  },
  {
    countryName: "South Korea",
    countryCode: "KR",
    callingCode: 82,
    flag: require("assets/images/country-flags/24/kr.png").default,
  },
  {
    countryName: "South Sudan",
    countryCode: "SS",
    callingCode: 211,
    flag: require("assets/images/country-flags/24/ss.png").default,
  },
  {
    countryName: "Spain",
    countryCode: "ES",
    callingCode: 34,
    flag: require("assets/images/country-flags/24/es.png").default,
  },
  {
    countryName: "Sri Lanka",
    countryCode: "LK",
    callingCode: 94,
    flag: require("assets/images/country-flags/24/lk.png").default,
  },
  {
    countryName: "Sudan",
    countryCode: "SD",
    callingCode: 249,
    flag: require("assets/images/country-flags/24/sd.png").default,
  },
  {
    countryName: "Suriname",
    countryCode: "SR",
    callingCode: 597,
    flag: require("assets/images/country-flags/24/sr.png").default,
  },
  {
    countryName: "Svalbard and Jan Mayen",
    countryCode: "SJ",
    callingCode: 47,
    flag: require("assets/images/country-flags/24/sj.png").default,
  },
  {
    countryName: "Swaziland",
    countryCode: "SZ",
    callingCode: 268,
    flag: require("assets/images/country-flags/24/sz.png").default,
  },
  {
    countryName: "Sweden",
    countryCode: "SE",
    callingCode: 46,
    flag: require("assets/images/country-flags/24/se.png").default,
  },
  {
    countryName: "Switzerland",
    countryCode: "CH",
    callingCode: 41,
    flag: require("assets/images/country-flags/24/ch.png").default,
  },
  {
    countryName: "Syria",
    countryCode: "SY",
    callingCode: 963,
    flag: require("assets/images/country-flags/24/sy.png").default,
  },
  {
    countryName: "Tajikistan",
    countryCode: "TJ",
    callingCode: 992,
    flag: require("assets/images/country-flags/24/tj.png").default,
  },
  {
    countryName: "Tanzania",
    countryCode: "TZ",
    callingCode: 255,
    flag: require("assets/images/country-flags/24/tz.png").default,
  },
  {
    countryName: "Thailand",
    countryCode: "TH",
    callingCode: 66,
    flag: require("assets/images/country-flags/24/th.png").default,
  },
  {
    countryName: "The Democratic Republic of Congo",
    countryCode: "CD",
    callingCode: 243,
    flag: require("assets/images/country-flags/24/cd.png").default,
  },
  {
    countryName: "Timor-Leste",
    countryCode: "TL",
    callingCode: 670,
    flag: require("assets/images/country-flags/24/tl.png").default,
  },
  {
    countryName: "Togo",
    countryCode: "TG",
    callingCode: 228,
    flag: require("assets/images/country-flags/24/tg.png").default,
  },
  {
    countryName: "Tokelau",
    countryCode: "TK",
    callingCode: 690,
    flag: require("assets/images/country-flags/24/tk.png").default,
  },
  {
    countryName: "Tonga",
    countryCode: "TO",
    callingCode: 676,
    flag: require("assets/images/country-flags/24/to.png").default,
  },
  {
    countryName: "Trinidad and Tobago",
    countryCode: "TT",
    callingCode: 1868,
    flag: require("assets/images/country-flags/24/tt.png").default,
  },
  {
    countryName: "Tunisia",
    countryCode: "TN",
    callingCode: 216,
    flag: require("assets/images/country-flags/24/tn.png").default,
  },
  {
    countryName: "Turkey",
    countryCode: "TR",
    callingCode: 90,
    flag: require("assets/images/country-flags/24/tr.png").default,
  },
  {
    countryName: "Turkmenistan",
    countryCode: "TM",
    callingCode: 993,
    flag: require("assets/images/country-flags/24/tm.png").default,
  },
  {
    countryName: "Turks and Caicos Islands",
    countryCode: "TC",
    callingCode: 1649,
    flag: require("assets/images/country-flags/24/tc.png").default,
  },
  {
    countryName: "Tuvalu",
    countryCode: "TV",
    callingCode: 688,
    flag: require("assets/images/country-flags/24/tv.png").default,
  },
  {
    countryName: "Uganda",
    countryCode: "UG",
    callingCode: 256,
    flag: require("assets/images/country-flags/24/ug.png").default,
  },
  {
    countryName: "Ukraine",
    countryCode: "UA",
    callingCode: 380,
    flag: require("assets/images/country-flags/24/ua.png").default,
  },
  {
    countryName: "United Arab Emirates",
    countryCode: "AE",
    callingCode: 971,
    flag: require("assets/images/country-flags/24/ae.png").default,
  },
  {
    countryName: "United Kingdom",
    countryCode: "GB",
    callingCode: 44,
    flag: require("assets/images/country-flags/24/gb.png").default,
  },
  {
    countryName: "United States",
    countryCode: "US",
    callingCode: 1,
    flag: require("assets/images/country-flags/24/us.png").default,
  },
  {
    countryName: "United States Minor Outlying Islands",
    countryCode: "UM",
    callingCode: 246,
    flag: require("assets/images/country-flags/24/um.png").default,
  },
  {
    countryName: "Uruguay",
    countryCode: "UY",
    callingCode: 598,
    flag: require("assets/images/country-flags/24/uy.png").default,
  },
  {
    countryName: "Uzbekistan",
    countryCode: "UZ",
    callingCode: 998,
    flag: require("assets/images/country-flags/24/uz.png").default,
  },
  {
    countryName: "Vanuatu",
    countryCode: "VU",
    callingCode: 678,
    flag: require("assets/images/country-flags/24/vu.png").default,
  },
  {
    countryName: "Venezuela",
    countryCode: "VE",
    callingCode: 58,
    flag: require("assets/images/country-flags/24/ve.png").default,
  },
  {
    countryName: "Vietnam",
    countryCode: "VN",
    callingCode: 84,
    flag: require("assets/images/country-flags/24/vn.png").default,
  },
  {
    countryName: "Virgin Islands, British",
    countryCode: "VG",
    callingCode: 1,
    flag: require("assets/images/country-flags/24/vg.png").default,
  },
  {
    countryName: "Virgin Islands, U.S.",
    countryCode: "VI",
    callingCode: 1,
    flag: require("assets/images/country-flags/24/vi.png").default,
  },
  {
    countryName: "Wallis and Futuna",
    countryCode: "WF",
    callingCode: 681,
    flag: require("assets/images/country-flags/24/wf.png").default,
  },
  {
    countryName: "Western Sahara",
    countryCode: "EH",
    callingCode: 212,
    flag: require("assets/images/country-flags/24/eh.png").default,
  },
  {
    countryName: "Yemen",
    countryCode: "YE",
    callingCode: 967,
    flag: require("assets/images/country-flags/24/ye.png").default,
  },
  {
    countryName: "Yugoslavia",
    countryCode: "YU",
    callingCode: 38,
    flag: require("assets/images/country-flags/24/yu.png").default,
  },
  {
    countryName: "Zambia",
    countryCode: "ZM",
    callingCode: 260,
    flag: require("assets/images/country-flags/24/zm.png").default,
  },
  {
    countryName: "Zimbabwe",
    countryCode: "ZW",
    callingCode: 263,
    flag: require("assets/images/country-flags/24/zw.png").default,
  },
] as Array<Country>;
