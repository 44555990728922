import { GlobalSettingsInitialInterface } from 'types/initialState';
import {
  SET_GLOBAL_SETTINGS_START,
  SET_GLOBAL_SETTINGS_FAILURE,
  SET_GLOBAL_SETTINGS_SUCCESS,
} from 'redux/actionTypes/globalSettings/setGlobalSettingsTypes';

const setGlobalSettingsReducer = (
  state: GlobalSettingsInitialInterface,
  { type, payload }: any
) => {
  switch (type) {
    case SET_GLOBAL_SETTINGS_START:
      return {
        ...state,
        setGlobalSettings: {
          ...state.setGlobalSettings,
          loading: true,
          data: [],
          error: null,
        },
      };

    case SET_GLOBAL_SETTINGS_SUCCESS:
      return {
        ...state,
        setGlobalSettings: {
          ...state.setGlobalSettings,
          loading: false,
          data: payload,
          error: null,
        },
      };

    case SET_GLOBAL_SETTINGS_FAILURE:
      return {
        ...state,
        setGlobalSettings: {
          ...state.setGlobalSettings,
          loading: false,
          error: payload,
          data: [],
        },
      };

    default:
      return null;
  }
};

export default setGlobalSettingsReducer;
