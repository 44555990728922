import IRealTimeServices from "types/IRealTimeServices";
import ActionType from "types/ActionType";
import { SET_TOKEN } from "../../actionTypes/real-time-services";

export default (
  state: IRealTimeServices,
  { type, payload }: ActionType
): { [key: string]: any } | null => {
  switch (type) {
    case SET_TOKEN:
      return {
        ...state,
        token: payload?.token || null,
      };
    default:
      return null;
  }
};
