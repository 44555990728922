import {
  GET_SUPPORTED_LANGUAGE_START,
  GET_SUPPORTED_LANGUAGE_SUCCESS,
  GET_SUPPORTED_LANGUAGE_FAILURE,
} from "redux/actionTypes/users/language";
import apiAction from "utils/apiAction";

const getSupportedLanguages =
  (formData?: Record<string, unknown>) =>
  (dispatchAction: React.Dispatch<Record<string, unknown>>): void =>
    dispatchAction(
      apiAction({
        method: "post",
        url: "/SupportedLanguages",
        data: formData,
        onStart: () => (dispatch) =>
          dispatch({
            type: GET_SUPPORTED_LANGUAGE_START,
          }),
        onSuccess: (languages) => (dispatch) => {
          if (Array.isArray(languages)) {
            dispatch({
              type: GET_SUPPORTED_LANGUAGE_SUCCESS,
              payload: languages.map((language) => ({
                text: language.Name,
                value: language.Code,
                flag: language.Flag,
                key: language.Code,
              })),
            });
          }
        },
        onFailure: (err) => (dispatch) => {
          return dispatch({
            type: GET_SUPPORTED_LANGUAGE_FAILURE,
            payload: Array.isArray(err) ? err[0] || {} : err || {},
          });
        },
      })
    );

export default getSupportedLanguages;
