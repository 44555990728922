export default function getStatusColor(
  status?: string
): "green" | "red" | "yellow" {
  if (status === "0") {
    return "yellow";
  }
  if (status === "1") {
    return "green";
  }
  if (status === "2" || status === "3") {
    return "red";
  }
  return "green";
}
