const merchantInitialState = {
  merchantList: {
    data: null,
    loading: false,
    error: null,
  },
  addUpdateMerchant: {
    data: null,
    loading: false,
    error: null,
  },
  setWalletAutoPayStatus: {
    data: null,
    loading: false,
    error: null,
  },
};

export default merchantInitialState;
