import axios from "utils/axios";
import { AxiosResponse } from "axios";
import CountryDataInterface from "types/CountryData";
import { useQuery } from "@tanstack/react-query";

interface GetCountryDataRequestBody {
  CountryCode?: string;
}

const fetchCountryDataQueryFn = (requestBody: GetCountryDataRequestBody) => {
  const {
    REACT_APP_LOGIN_NAME: LoginName,
    REACT_APP_API_KEY: APIKey,
    REACT_APP_ID: AppID,
  } = process.env;

  return axios
    .post<any, AxiosResponse<CountryDataInterface[]>>("GetCountryData", {
      AppID,
      APIKey,
      LoginName,
      ...requestBody,
    })
    .then((response) => response.data);
};

const useFetchCountryData = (requestBody: GetCountryDataRequestBody) => {
  return useQuery({
    queryKey: ["GetCountryData", requestBody.CountryCode],
    staleTime: Infinity,
    enabled: !!requestBody.CountryCode,
    queryFn: () => fetchCountryDataQueryFn(requestBody),
  });
};

export { useFetchCountryData };
