import React from 'react';
import { useIntl } from 'react-intl';
import { Space, Typography } from 'antd';
import { ResponsiveLine } from '@nivo/line';

const { Text } = Typography;

const GraphLineComponent: React.FC<{
  dataSource: any;
  width?: number;
  height?: number;
  title: string;
}> = ({ dataSource, width, height, title }) => {
  const intl = useIntl();

  const data = [
    {
      id: intl.formatMessage({
        id: '1500',
        defaultMessage: 'Users',
      }),
      color: '#f35627',
      data: dataSource,
    },
  ];

  return (
    <>
      <div
        style={{
          height: height ? height : '456px',
          width: width ? width : '100%',
          marginLeft: 'auto',
        }}
      >
        <ResponsiveLine
          data={data}
          curve="catmullRom"
          enableGridX={false}
          margin={{ top: 10, right: 25, bottom: 96, left: 50 }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 4,
            tickPadding: 12,
            tickRotation: 0,
            legend: intl.formatMessage({
              id: '1258',
              defaultMessage: title,
            }),
            legendOffset: 56,
            legendPosition: 'middle',
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 0,
            tickPadding: 12,
            tickRotation: 0,
            legend: null,
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          colors={['#f35627']}
          pointSize={10}
          pointBorderWidth={4}
          pointBorderColor="#ffffff"
          enableSlices="x"
          sliceTooltip={({ slice: { points } }) => {
            const {
              data: { x, y },
            } = points[0];
            return (
              <Space
                direction="vertical"
                style={{
                  minWidth: 128,
                  backgroundColor: '#fff',
                  borderRadius: 2,
                  border: '1px solid #e1e1e1',
                  padding: 8,
                }}
              >
                <Text style={{ color: '#27241d' }}>
                  {`${intl.formatMessage({
                    id: '2668',
                    defaultMessage: 'Month',
                  })}: ${x}`}
                </Text>
                <Text style={{ color: '#27241d' }}>
                  {`${intl.formatMessage({
                    id: '1500',
                    defaultMessage: 'Users',
                  })}: ${y}`}
                </Text>
              </Space>
            );
          }}
          useMesh={true}
        />
      </div>
    </>
  );
};

export default GraphLineComponent;
