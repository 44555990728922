const colorsData = [
  {
    color: '#C99A2E',
    background: '#FFFAEB',
  },
  { color: '#044E54', background: '#E0FCFF' },
  { color: '#035388', background: '#E3F8FF' },
  { color: '#F35627', background: '#FFEFE6' },
  { color: '#DA127D', background: '#FFE3EC' },
];

const getColors = () => {
  const randomIndex = Math.floor(Math.random() * colorsData.length);
  return colorsData[randomIndex];
};

export default getColors;
