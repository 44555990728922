const walletsRecentOpsInitialState = {
  walletsWithRecentOps: {
    data: {},
    error: null,
    loading: false,
    success: false,
  },
  walletsWithRecentOpsExport: {
    data: {},
    error: null,
    loading: false,
    success: false,
  },
};

export default walletsRecentOpsInitialState;
