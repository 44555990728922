import {
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_START,
  DELETE_COMMENT_SUCCESS,
} from 'redux/actionTypes/contentReview';
import { ReportedItem } from 'types/contentReview/reportList';
import ContentReviewInterface from 'types/contentReview/index.d';

const removeReportFormList = (
  servicesList: Array<ReportedItem>,
  itemID: any,
  itemNumber: any
) => {
  return servicesList.filter((item: ReportedItem) => {
    return !(item.ItemID === itemID && item.ItemNumber === itemNumber);
  });
};

const commentReducer = (
  state: ContentReviewInterface,
  { payload, type }: any
) => {
  switch (type) {
    case DELETE_COMMENT_START:
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          loading: true,
          error: null,
          updateComment: null,
        },
      };
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        reportedContent: {
          data: {
            ...state.reportedContent.data,
            Data: removeReportFormList(
              state.reportedContent.data.Data,
              payload.ServiceID,
              payload.ItemNumber
            ),
          },
          loading: false,
          updateComment: payload.successMessage,
        },
      };
    case DELETE_COMMENT_FAILURE:
      return {
        ...state,
        reportedContent: {
          ...state.reportedContent,
          loading: false,
          error: payload,
        },
      };

    default:
      return null;
  }
};

export default commentReducer;
