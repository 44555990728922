export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_USERS_FLAT_START = "GET_USERS_FLAT_START";
export const GET_USERS_FLAT_SUCCESS = "GET_USERS_FLAT_SUCCESS";
export const GET_USERS_FLAT_FAILURE = "GET_USERS_FLAT_FAILURE";

export const GET_USER_DATA_START = "GET_USER_DATA_START";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE";

export const SET_CURRENT_USER_FROM_LOCAL_STORAGE =
  "SET_CURRENT_USER_FROM_LOCAL_STORAGE";

export const SET_SELECTED_OPS_COUNTRY = "SET_SELECTED_OPS_COUNTRY";
