import {
  CREATE_CASHPOINT_START,
  CREATE_CASHPOINT_SUCCESS,
  CREATE_CASHPOINT_FAILURE,
  CREATE_CASHPOINT_RESET,
} from "redux/actionTypes/cashpoints/index";
import { CashPointsInitialStateInterface } from "types/initialState";

const addCashPintOfficeReducer = (
  state: CashPointsInitialStateInterface,
  { type, payload }: Record<string, any>
) => {
  switch (type) {
    case CREATE_CASHPOINT_START:
      return {
        ...state,
        createCashpointOffice: { loading: true, data: null, error: null },
      };

    case CREATE_CASHPOINT_SUCCESS:
      return {
        ...state,
        createCashpointOffice: { loading: false, data: payload, error: null },
      };
    case CREATE_CASHPOINT_FAILURE:
      return {
        ...state,
        createCashpointOffice: { loading: false, data: null, error: null },
      };

    case CREATE_CASHPOINT_RESET:
      return {
        ...state,
        createCashpointOffice: { loading: false, data: null, error: null },
      };

    default:
      return null;
  }
};

export default addCashPintOfficeReducer;
