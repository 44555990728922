import { useRef, useEffect } from "react";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

import InitialState from "types/initialState";
import DispatchType from "types/DispatchType";

import {
  GET_CHAT_THREADS,
  GET_CHAT_THREADS_SUCCESS,
  GET_CHAT_THREADS_ERROR,
  DELETE_CHAT_THREAD,
  DELETE_CHAT_THREAD_SUCCESS,
  DELETE_CHAT_THREAD_ERROR,
} from "constants/chats";
import * as realTimeServicesActionTypes from "../../../redux/actionTypes/real-time-services";
import { chatSocketIOClient } from "..";
import { getDirectMessages } from "./directMessage";

const typedUseSelectorHook: TypedUseSelectorHook<InitialState> = useSelector;

export const getChatThreads =
  (rtsToken: string | null, options?: Record<string, any>) =>
  (dispatch: DispatchType): void => {
    chatSocketIOClient.emit(GET_CHAT_THREADS, options || {}, rtsToken);
    if (typeof dispatch === "function") {
      dispatch({ type: realTimeServicesActionTypes.GET_CHAT_THREADS });
    }
  };

export const clearChatThread =
  ({
    threadId,
    thread,
    rtsToken,
  }: {
    threadId: number;
    thread: string;
    rtsToken: string | null;
  }) =>
  (dispatch: DispatchType): void => {
    chatSocketIOClient.emit(DELETE_CHAT_THREAD, { id: threadId }, rtsToken);
    if (typeof dispatch === "function") {
      dispatch({
        type: realTimeServicesActionTypes.DELETE_CHAT_THREAD_SUCCESS,
        payload: { id: threadId, thread },
      });
    }
  };

const useChatThread = () => {
  const dispatch = useDispatch();

  const directMessagesRef = useRef<any>();
  const rtsTokenRef = useRef<string | null>();

  const {
    users: { currentUser: { data: userData = {} as Record<string, any> } = {} },
    realTimeServices: {
      token: rtsToken,
      chat: { directMessages = {} },
    },
  } = typedUseSelectorHook(({ users, realTimeServices }) => ({
    users,
    realTimeServices,
  }));

  useEffect(() => {
    if (rtsToken) {
      rtsTokenRef.current = rtsToken;
    }
  }, [rtsToken]);

  useEffect(() => {
    if (directMessages) {
      directMessagesRef.current = directMessages;
    }
  }, [directMessages]);

  useEffect(() => {
    if (rtsToken) {
      getChatThreads(rtsToken)(dispatch);
    }
  }, [rtsToken]);

  useEffect(() => {
    chatSocketIOClient.on(
      GET_CHAT_THREADS_SUCCESS,
      (response: {
        [key: string]: {
          data: Array<Record<string, any>>;
          meta: { [key: string]: string };
        };
      }) => {
        // const threads = response.data;
        // if (!Object.keys(directMessagesRef.current?.data || {}).length) {
        //   (Array.isArray(threads) ? threads : []).forEach(({ id }) => {
        //     getDirectMessages({
        //       rtsToken: rtsTokenRef.current as string,
        //       threadId: id,
        //     })(dispatch);
        //   });
        // }
        dispatch({
          type: realTimeServicesActionTypes.GET_CHAT_THREADS_SUCCESS,
          payload: response,
        });
      }
    );

    chatSocketIOClient.on(GET_CHAT_THREADS_ERROR, (error: any) => error);
    chatSocketIOClient.on(
      DELETE_CHAT_THREAD_SUCCESS,
      (response: Record<string, any>) => response
    );
    chatSocketIOClient.on(
      DELETE_CHAT_THREAD_ERROR,
      (response: Record<string, any>) => response
    );

    return () => {
      chatSocketIOClient.off(GET_CHAT_THREADS_SUCCESS);
      chatSocketIOClient.off(GET_CHAT_THREADS_ERROR);
      chatSocketIOClient.off(DELETE_CHAT_THREAD_SUCCESS);
      chatSocketIOClient.off(DELETE_CHAT_THREAD_ERROR);
    };
  }, []);
};

export default useChatThread;
