export const TYPING_CHAT_DIRECT_MESSAGE = 'TypingChatDirectMessage';
export const NEW_OUTGOING_CHAT_DIRECT_MESSAGE = 'NewOutgoingChatDirectMessage';
export const NEW_INCOMING_CHAT_DIRECT_MESSAGE = 'NewIncomingChatDirectMessage';
export const SUCCESS_OUTGOING_CHAT_DIRECT_MESSAGE =
  'SuccessOutgoingChatDirectMessage';
export const ERROR_OUTGOING_CHAT_DIRECT_MESSAGE =
  'ErrorOutgoingChatDirectMessage';

// get chat direct messages
export const GET_CHAT_DIRECT_MESSAGES = 'GetChatDirectMessages';
export const GET_CHAT_DIRECT_MESSAGES_SUCCESS = 'GetChatDirectMessagesSuccess';
export const GET_CHAT_DIRECT_MESSAGES_ERROR = 'GetChatDirectMessagesError';

// update chat direct messages read status
export const UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS =
  'UpdateChatDirectMessagesReadStatus';
export const UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_SUCCESS =
  'UpdateChatDirectMessagesReadStatusSuccess';
export const UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_ERROR =
  'UpdateChatDirectMessagesReadStatusError';

// delete chat direct messages
export const DELETE_CHAT_DIRECT_MESSAGES = 'DeleteChatDirectMessages';
export const DELETE_CHAT_DIRECT_MESSAGES_SUCCESS =
  'DeleteChatDirectMessagesSuccess';
export const DELETE_CHAT_DIRECT_MESSAGES_ERROR =
  'DeleteChatDirectMessagesError';

// clear chat direct messages
export const CLEAR_CHAT_DIRECT_MESSAGES = 'ClearChatDirectMessages';
export const CLEAR_CHAT_DIRECT_MESSAGES_SUCCESS =
  'ClearChatDirectMessagesSuccess';
export const CLEAR_CHAT_DIRECT_MESSAGES_ERROR = 'ClearChatDirectMessagesError';
