export const SELF = "0";
export const GIMAC = "1";
export const NFS_ZAMBIA = "2";
export const NECOR_ZAMBIA = "3";
export const KAZANG_ZAMBIA = "4";
export const UBA = "5";
export const BGFI = "6";
export const BIG = "7";
export const MAVIANCE_CM = "8";
export const AFRIKPAY_CM = "9";
export const NSANO = "11";
export const CGRATE = "12";
export const TERRAPAY = "13";

const allPartners = [
  { label: "UBA", value: UBA, countries: ["CM", "CG", "ZM"] },
  { label: "TERRA PAY", value: TERRAPAY, countries: ["CM", "CG", "ZM"] },
  { label: "GIMAC", value: GIMAC, countries: ["CM", "CG"] },
  { label: "MAVIANCE", value: MAVIANCE_CM, countries: ["CM"] },
  { label: "AFRIK PAY", value: AFRIKPAY_CM, countries: ["CM"] },
  { label: "NFS", value: NFS_ZAMBIA, countries: ["ZM"] },
  { label: "NECOR", value: NECOR_ZAMBIA, countries: ["ZM"] },
  { label: "KAZANG", value: KAZANG_ZAMBIA, countries: ["ZM"] },
  { label: "NSANO", value: NSANO, countries: ["ZM"] },
  { label: "CGRATE", value: CGRATE, countries: ["ZM"] },
];
export default allPartners;
