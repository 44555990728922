import TransactionsReportInterface from 'types/transactionsReport';
import transactionsSummaryReducer from './summaryReducer';
import transactionsSearchReducer from './transactionsSearchReducer';

const transactionsReportReducer = (
  state: TransactionsReportInterface,
  action: any
) => {
  return {
    ...state,
    ...transactionsSummaryReducer(state, action),
    ...transactionsSearchReducer(state, action),
  };
};

export default transactionsReportReducer;
