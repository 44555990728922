import FinancesInitialStateInterface from 'types/finances/financialInitialState';
import {
  UPDATE_PARTNER_BALANCE_START,
  UPDATE_PARTNER_BALANCE_SUCCESS,
  UPDATE_PARTNER_BALANCE_FAILURE,
} from 'redux/actionTypes/finances';

const updatePartnerBalanceReducer = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case UPDATE_PARTNER_BALANCE_START:
      return {
        ...state,
        updatePartnerBalance: {
          ...state.updatePartnerBalance,
          loading: true,
          error: null,
        },
      };
    case UPDATE_PARTNER_BALANCE_FAILURE:
      return {
        ...state,
        updatePartnerBalance: {
          ...state.updatePartnerBalance,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case UPDATE_PARTNER_BALANCE_SUCCESS:
      let newPartnersData = state.partners.data;

      newPartnersData = state.partners.data.map((partner) => {
        if (partner.LoginName === payload.PartnerLoginName) {
          const PartnerBalance =
            payload.OperationType === '+'
              ? Number(partner.PartnerBalance) + Number(payload.Amount)
              : Number(partner.PartnerBalance) - Number(payload.Amount);
          return {
            ...partner,
            PartnerBalance: PartnerBalance.toString(),
          };
        }
        return partner;
      });

      return {
        ...state,
        updatePartnerBalance: {
          ...state.updatePartnerBalance,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
        partners: {
          ...state.partners,
          data: newPartnersData,
        },
      };
    default:
      return null;
  }
};

export default updatePartnerBalanceReducer;
