// GET BULK ORGANIZATION LIST
export const BULK_ORGANIZATION_START = 'BULK_ORGANIZATION_START';
export const BULK_ORGANIZATION_END = 'BULK_ORGANIZATION_END';
export const BULK_ORGANIZATION_SUCCESS = 'BULK_ORGANIZATION_SUCCESS';
export const BULK_ORGANIZATION_FAILURE = 'BULK_ORGANIZATION_FAILURE';

// CREATE BULK ORGANIZATION
export const CREATE_BULK_ORGANIZATION_START = 'CREATE_BULK_ORGANIZATION_START';
export const CREATE_BULK_ORGANIZATION_END = 'CREATE_BULK_ORGANIZATION_END';
export const CREATE_BULK_ORGANIZATION_SUCCESS = 'CREATE_BULK_ORGANIZATION_SUCCESS';
export const CREATE_BULK_ORGANIZATION_FAILURE = 'CREATE_BULK_ORGANIZATION_FAILURE';