import * as transactionOptions from "constants/transactionOptions";

export default function getTransactionTypeText(type: string): string {
  switch (type) {
    case transactionOptions.CashPickup:
      return "Cash Pickup";
    case transactionOptions.CancelTrans:
      return "Cancellation";
    case transactionOptions.WalletTopUp:
      return "Wallet Top Up";
    case transactionOptions.SendCash:
      return "Send Cash";
    case transactionOptions.Redeem:
      return "Voucher Redeem";
    case transactionOptions.WalletToWallet:
      return "Wallet to Wallet";
    case transactionOptions.General:
      return "General";
    case transactionOptions.FromPartner:
      return "From Partner";
    case transactionOptions.ToPartner:
      return "To Partner";
    case transactionOptions.CashDeposit:
      return "Cash Deposit";
    case transactionOptions.MicroLoan:
      return "Micro Loan";
    case transactionOptions.BankToWallet:
      return "Bank to Wallet";
    case transactionOptions.WalletToBank:
      return "Wallet to Bank";
    case transactionOptions.ATM:
      return "ATM";
    case transactionOptions.PhoneTopUp:
      return "Phone Top Up";
    case transactionOptions.Payment:
      return "Payment";
    case transactionOptions.InvoicePostPay:
      return "Invoice Post Pay";
    case transactionOptions.InvoicePrePay:
      return "Invoice Prepay";
    case transactionOptions.ToPrepaidCard:
      return "To Prepaid Card";
    case transactionOptions.ToMNO:
      return "To MNO";
    case transactionOptions.BulkPay:
      return "Bulk pay";
    default:
      return type;
  }
}
