import {
  GET_MICRO_LOANS_APR_START,
  GET_MICRO_LOANS_APR_SUCCESS,
  GET_MICRO_LOANS_APR_FAILURE,
  SAVE_MICRO_LOANS_APR_FAILURE,
  SAVE_MICRO_LOANS_APR_SUCCESS,
  SAVE_MICRO_LOANS_APR_START,
} from 'redux/actionTypes/finances';
import FinancesInitialStateInterface from 'types/finances/financialInitialState';

const microLoansReducer = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_MICRO_LOANS_APR_START:
      return {
        ...state,
        microLoans: {
          ...state.microLoans,
          allRanges: {
            data: [],
            error: null,
            loading: true,
          },
        },
      };

    case GET_MICRO_LOANS_APR_SUCCESS:
      return {
        ...state,
        microLoans: {
          ...state.microLoans,
          allRanges: {
            data: payload,
            error: null,
            loading: false,
          },
        },
      };

    case GET_MICRO_LOANS_APR_FAILURE:
      return {
        ...state,
        microLoans: {
          ...state.microLoans,
          allRanges: {
            data: [],
            error: payload,
            loading: false,
          },
        },
      };

    case SAVE_MICRO_LOANS_APR_START:
      return {
        ...state,
        microLoans: {
          ...state.microLoans,
          editRange: {
            data: null,
            error: null,
            loading: true,
          },
        },
      };

    case SAVE_MICRO_LOANS_APR_SUCCESS: {
      let newData = state.microLoans.allRanges.data;
      const newDataIndex = newData.findIndex(
        (scoreRange) => scoreRange.Score === payload.Score
      );

      if (newDataIndex > -1) {
        newData = Array.from(state.microLoans.allRanges.data);
        newData[newDataIndex] = {
          ...payload,
          ScoreName: newData[newDataIndex].ScoreName,
        };
      }

      return {
        ...state,
        microLoans: {
          ...state.microLoans,
          allRanges: {
            ...state.microLoans.allRanges,
            data: newData,
          },
          editRange: {
            error: null,
            data: payload,
            loading: false,
          },
        },
      };
    }

    case SAVE_MICRO_LOANS_APR_FAILURE:
      return {
        ...state,
        microLoans: {
          ...state.microLoans,
          editRange: {
            data: null,
            loading: true,
            error: payload,
          },
        },
      };

    default:
      return null;
  }
};

export default microLoansReducer;
