import IRealTimeServices from "types/IRealTimeServices";
import ActionType from "types/ActionType";
import {
  NEW_OUTGOING_CHAT_DIRECT_MESSAGE,
  NEW_INCOMING_CHAT_DIRECT_MESSAGE,
  GET_CHAT_DIRECT_MESSAGES,
  GET_CHAT_DIRECT_MESSAGES_SUCCESS,
  SUCCESS_OUTGOING_CHAT_DIRECT_MESSAGE,
  DELETE_CHAT_DIRECT_MESSAGES,
  DELETE_CHAT_DIRECT_MESSAGES_SUCCESS,
  UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_SUCCESS,
  TYPING_CHAT_DIRECT_MESSAGE,
} from "../../actionTypes/real-time-services";
import { SEEN } from "constants/general";

const onGetMessages = (
  state: IRealTimeServices,
  payload: { [key: string]: any }
) => {
  let messages =
    JSON.parse(JSON.stringify(state.chat.directMessages.data)) || {};
  const thread = Object.keys(payload)?.[0];
  const currentMessages = messages[thread];

  if (thread && currentMessages?.data && currentMessages?.meta) {
    const currentPage = currentMessages.meta.page;
    const newPage = payload[thread]?.meta?.page;

    if (currentPage < newPage) {
      messages = {
        ...messages,
        [thread]: {
          ...payload[thread],
          data: [...currentMessages.data, ...payload[thread].data],
        },
      };
    } else {
      messages = { ...messages, ...payload };
    }
  } else {
    messages = { ...messages, ...payload };
  }

  return messages;
};

const onIncomingOrOutgoingMessage = (
  state: IRealTimeServices,
  payload: { [key: string]: any }
) => {
  let messages =
    JSON.parse(JSON.stringify(state.chat.directMessages.data)) || {};
  const { thread, message: newMessage } = payload;
  const currentMessages = messages[thread];

  if (thread && currentMessages?.data && currentMessages?.meta) {
    let doesMessageExist = false;
    currentMessages.data = currentMessages.data.map(
      (message: { [key: string]: number | string }) => {
        if (message.messageId === newMessage.messageId) {
          doesMessageExist = true;
          return { ...message, ...newMessage };
        }
        return message;
      }
    );
    messages = {
      ...messages,
      [thread]: {
        ...currentMessages,
        data: doesMessageExist
          ? currentMessages.data
          : [newMessage, ...currentMessages.data],
      },
    };
  } else {
    messages = {
      ...messages,
      [thread]: {
        ...currentMessages,
        data: [newMessage],
        meta: { page: 1, total: 1, perPage: 20 },
      },
    };
  }

  return messages;
};

const onDeleteDirectMessage = (
  state: IRealTimeServices,
  payload: { [key: string]: any }
) => {
  let messages =
    JSON.parse(JSON.stringify(state.chat.directMessages.data)) || {};
  const { thread, message: messageToDelete } = payload;
  const currentMessages = messages[thread];

  if (thread && currentMessages?.data && currentMessages?.meta) {
    messages = {
      ...messages,
      [thread]: {
        ...currentMessages,
        data: currentMessages.data.filter(
          (message: { [key: string]: number | string }) =>
            Number(message.id) !== Number(messageToDelete.id)
        ),
      },
    };
  }

  return messages;
};

const onUpdateDirectMessageReadStatus = (
  state: IRealTimeServices,
  payload: { [key: string]: any }
) => {
  let messages =
    JSON.parse(JSON.stringify(state.chat.directMessages.data)) || {};
  const { thread } = payload;
  const currentMessages = messages[thread];

  if (thread && currentMessages?.data && currentMessages?.meta) {
    messages = {
      ...messages,
      [thread]: {
        ...currentMessages,
        data: currentMessages.data.map(
          (message: { [key: string]: number | string }) => ({
            ...message,
            status: SEEN,
          })
        ),
      },
    };
  }

  return messages;
};

export default function directMessageReducer(
  state: IRealTimeServices,
  { type, payload }: ActionType
): { [key: string]: any } | null {
  switch (type) {
    case GET_CHAT_DIRECT_MESSAGES:
      return {
        ...state,
        chat: {
          ...state.chat,
          directMessages: {
            ...state.chat.directMessages,
            error: null,
            loading: true,
            success: false,
          },
        },
      };
    case GET_CHAT_DIRECT_MESSAGES_SUCCESS:
      return {
        ...state,
        chat: {
          ...state.chat,
          directMessages: {
            ...state.chat.directMessages,
            data: onGetMessages(state, payload as { [key: string]: any }),
            error: null,
            loading: false,
            success: true,
          },
          threads: {
            ...state.chat.threads,
            data:
              state.chat.threads?.data?.map((thread: Record<string, any>) => {
                if (
                  Number(thread?.id) ===
                  Number(
                    payload?.[Object.keys(payload)[0]]?.data?.[0]?.threadId
                  )
                ) {
                  return {
                    ...thread,
                    unreadMessagesCount: [
                      { ...thread?.unreadMessagesCount?.[0], count: 0 },
                    ],
                  };
                }
                return thread;
              }) || [],
          },
        },
      };
    case NEW_OUTGOING_CHAT_DIRECT_MESSAGE:
    case NEW_INCOMING_CHAT_DIRECT_MESSAGE:
    case SUCCESS_OUTGOING_CHAT_DIRECT_MESSAGE:
      return {
        ...state,
        chat: {
          ...state.chat,
          directMessages: {
            ...state.chat.directMessages,
            data: onIncomingOrOutgoingMessage(
              state,
              payload as { [key: string]: any }
            ),
            error: null,
            loading: false,
            success: true,
          },
        },
      };
    case DELETE_CHAT_DIRECT_MESSAGES:
    case DELETE_CHAT_DIRECT_MESSAGES_SUCCESS:
      return {
        ...state,
        chat: {
          ...state.chat,
          directMessages: {
            ...state.chat.directMessages,
            data: onDeleteDirectMessage(
              state,
              payload as { [key: string]: any }
            ),
            error: null,
            loading: false,
            success: true,
          },
        },
      };
    case UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_SUCCESS:
      return {
        ...state,
        chat: {
          ...state.chat,
          threads: {
            ...state.chat.threads,
            data: state.chat.threads.data.map((thread: any) => {
              if (Number(thread.id) === Number(payload?.threadId)) {
                return {
                  ...thread,
                  unreadMessagesCount: [
                    { ...(thread?.unreadMessagesCount?.[0] || []), count: 0 },
                  ],
                  directMessages: [
                    { ...(thread?.directMessages?.[0] || []), status: SEEN },
                  ],
                };
              }
              return thread;
            }),
          },
          directMessages: {
            ...state.chat.directMessages,
            data: onUpdateDirectMessageReadStatus(
              state,
              payload as { [key: string]: any }
            ),
            error: null,
            loading: false,
            success: true,
          },
        },
      };
    case TYPING_CHAT_DIRECT_MESSAGE:
      return {
        ...state,
        chat: {
          ...state.chat,
          usersTyping: payload,
        },
      };
    default:
      return null;
  }
}
