import { EventsInitialStateInterface } from "types/initialState";
import getEventsReducer from "./getEventsReducer";
import updateCreate from "./updateCreateEventReducer";
import updatePicture from "./updateEventPicture";

const eventsReducer = (state: EventsInitialStateInterface, action: any) => {
  return {
    ...state,
    ...getEventsReducer(state, action),
    ...updateCreate(state, action),
    ...updatePicture(state, action),
  };
};

export default eventsReducer;
