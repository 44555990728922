import {
  GET_LOCATION_START,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
} from "redux/actionTypes/users/location";
import apiAction from "utils/apiAction";

const getLocationAction = () => (
  dispatchAction: React.Dispatch<Record<string, unknown>>
): void =>
  dispatchAction(
    apiAction({
      method: "POST",
      url: "/GetLocationData",
      onStart: () => (dispatch) =>
        dispatch({
          type: GET_LOCATION_START,
        }),
      onSuccess: (data) => (dispatch) => {
        return dispatch({
          type: GET_LOCATION_SUCCESS,
          payload: Array.isArray(data) ? data[0] : data,
        });
      },
      onFailure: (err) => (dispatch) => {
        return dispatch({
          type: GET_LOCATION_FAILURE,
          payload: Array.isArray(err) ? err[0] || {} : err || {},
        });
      },
    })
  );

export default getLocationAction;
