export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const UNLOCK_USER_START = "UNLOCK_USER_START";
export const UNLOCK_USER_SUCCESS = "UNLOCK_USER_SUCCESS";
export const UNLOCK_USER_FAILURE = "UNLOCK_USER_FAILURE";

export const CONFIGURE_DUAL_LOGIN_START = "CONFIGURE_DUAL_LOGIN_START";
export const CONFIGURE_DUAL_LOGIN_SUCCESS = "CONFIGURE_DUAL_LOGIN_SUCCESS";
export const CONFIGURE_DUAL_LOGIN_FAILURE = "CONFIGURE_DUAL_LOGIN_FAILURE";
