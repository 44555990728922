

const bulkPaymentInitialState = {
    bulkOrganizationList: {
      data: null,
      loading: false,
      error: null,
    },
    createBulkOrganization: {
      data: null,
      loading: false,
      error: null,
    },
  };
  
export default bulkPaymentInitialState;