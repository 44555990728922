import { useEffect, useRef } from "react";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

import InitialState from "types/initialState";
import DispatchType from "types/DispatchType";
import { DirectChatMessageType } from "types/IDirectChatMessage";
import {
  // get messages
  GET_CHAT_DIRECT_MESSAGES,
  GET_CHAT_DIRECT_MESSAGES_SUCCESS,
  GET_CHAT_DIRECT_MESSAGES_ERROR,
  // send message
  NEW_OUTGOING_CHAT_DIRECT_MESSAGE,
  SUCCESS_OUTGOING_CHAT_DIRECT_MESSAGE,
  ERROR_OUTGOING_CHAT_DIRECT_MESSAGE,
  // incoming message
  NEW_INCOMING_CHAT_DIRECT_MESSAGE,
  // delete message
  DELETE_CHAT_DIRECT_MESSAGES,
  DELETE_CHAT_DIRECT_MESSAGES_SUCCESS,
  DELETE_CHAT_DIRECT_MESSAGES_ERROR,
  // update messages read status
  UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS,
  UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_SUCCESS,
  UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_ERROR,
  TYPING_CHAT_DIRECT_MESSAGE,
  GET_CHAT_THREADS,
} from "constants/chats";
import {
  ONLINE,
  AWAY,
  DO_NOT_DISTURB,
  INVISIBLE,
  OFFLINE,
} from "constants/userPresenceStatus";
import * as realTimeServicesActionTypes from "../../../redux/actionTypes/real-time-services";
import { DELETE_FOR_ALL, DELETE_FOR_ME } from "constants/general";
import socketIOClient, { chatSocketIOClient } from "..";

const typedUseSelectorHook: TypedUseSelectorHook<InitialState> = useSelector;

const getChatThreads =
  (rtsToken: string | null) =>
  (dispatch: DispatchType): void => {
    chatSocketIOClient.emit(GET_CHAT_THREADS, {}, rtsToken);
    if (typeof dispatch === "function") {
      dispatch({ type: realTimeServicesActionTypes.GET_CHAT_THREADS });
    }
  };

export const getThreadName = ({
  PID,
  sender,
  receiver,
}: {
  PID: string;
  sender: string;
  receiver: string;
}): string => {
  if (
    PID &&
    sender &&
    receiver &&
    String(PID).toUpperCase() === String(sender).toUpperCase()
  ) {
    return `${String(PID).toUpperCase()}-${String(receiver).toUpperCase()}`;
  }
  if (PID && sender && receiver) {
    return `${String(PID).toUpperCase()}-${String(sender).toUpperCase()}`;
  }

  return "";
};

export const getDirectMessages =
  ({
    rtsToken,
    receiver,
    threadId,
    page = 1,
  }: {
    rtsToken: string | null;
    receiver?: string;
    threadId?: number;
    page?: number;
  }) =>
  (dispatch: DispatchType): void => {
    chatSocketIOClient.emit(
      GET_CHAT_DIRECT_MESSAGES,
      { receiver, threadId, page },
      rtsToken
    );
    if (typeof dispatch === "function") {
      dispatch({ type: realTimeServicesActionTypes.GET_CHAT_DIRECT_MESSAGES });
    }
  };

export const sendDirectMessage =
  ({
    data,
    rtsToken,
  }: {
    data: DirectChatMessageType;
    rtsToken: string | null;
  }) =>
  (dispatch: DispatchType): void => {
    chatSocketIOClient.emit(NEW_OUTGOING_CHAT_DIRECT_MESSAGE, data, rtsToken);
    const thread = `${String(data.sender).toUpperCase()}-${String(
      data.receiver
    ).toUpperCase()}`;
    if (thread && typeof dispatch === "function") {
      dispatch({
        type: realTimeServicesActionTypes.NEW_INCOMING_CHAT_DIRECT_MESSAGE,
        payload: { thread, message: data },
      });
    }
  };

export const deleteDirectMessage =
  ({
    message,
    thread,
    deleteFor = DELETE_FOR_ALL,
    rtsToken,
  }: {
    message: DirectChatMessageType;
    thread: string;
    deleteFor?: typeof DELETE_FOR_ALL | typeof DELETE_FOR_ME;
    rtsToken: string | null;
  }) =>
  (dispatch: DispatchType): void => {
    chatSocketIOClient.emit(
      DELETE_CHAT_DIRECT_MESSAGES,
      { ...message, deleteFor },
      rtsToken
    );
    if (typeof dispatch === "function") {
      dispatch({
        type: realTimeServicesActionTypes.DELETE_CHAT_DIRECT_MESSAGES,
        payload: { thread, message },
      });
    }
  };

export const updateDirectMessageReadStatus = ({
  thread,
  presenceStatus,
  rtsToken,
}: {
  thread: { [key: string]: any };
  presenceStatus?:
    | typeof ONLINE
    | typeof AWAY
    | typeof DO_NOT_DISTURB
    | typeof INVISIBLE
    | typeof OFFLINE;
  rtsToken: string | null;
}): void => {
  chatSocketIOClient.emit(
    UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS,
    { ...thread, threadId: thread.id, presenceStatus },
    rtsToken
  );
};

export const onDirectMessageTyping = ({
  receiver,
  rtsToken,
}: {
  receiver: string;
  rtsToken: string;
}): void => {
  chatSocketIOClient.emit(TYPING_CHAT_DIRECT_MESSAGE, { receiver }, rtsToken);
};

const useChatDirectMessages = () => {
  const dispatch = useDispatch();

  const userDataRef = useRef<any>(null);
  const rtsTokenRef = useRef<string | null>(null);
  const typingTimeoutRef = useRef<NodeJS.Timeout | number | null>(null);

  const {
    users: {
      currentUser: { data: userData = {} as { [key: string]: any } } = {},
    },
    realTimeServices: { token: rtsToken },
  } = typedUseSelectorHook(({ users, realTimeServices }) => ({
    users,
    realTimeServices,
  }));

  useEffect(() => {
    if (userData) {
      userDataRef.current = {
        ...userData,
        PID: localStorage.getItem("rtsPID"),
      };
    }
    if (rtsToken) {
      rtsTokenRef.current = rtsToken;
    }
  }, [userData, rtsToken]);

  useEffect(() => {
    // incoming message
    socketIOClient.on(
      NEW_INCOMING_CHAT_DIRECT_MESSAGE,
      (response: Record<string, string | number>) => {
        const { PID } = userDataRef.current || {};
        const { sender, receiver } = response || {};
        const thread = getThreadName({
          PID,
          sender: String(sender ?? ""),
          receiver: String(receiver ?? ""),
        });

        if (thread) {
          dispatch({
            type: realTimeServicesActionTypes.NEW_INCOMING_CHAT_DIRECT_MESSAGE,
            payload: { thread, message: response },
          });
        }
        getChatThreads(rtsTokenRef.current as string)(dispatch);
      }
    );

    // get messages
    chatSocketIOClient.on(
      GET_CHAT_DIRECT_MESSAGES_SUCCESS,
      (response: { [key: string]: { [key: string]: any } }) => {
        const { PID } = userDataRef.current || {};
        const { sender, receiver } = response.data[0] || {};
        const thread = getThreadName({ PID, sender, receiver });
        if (thread) {
          dispatch({
            type: realTimeServicesActionTypes.GET_CHAT_DIRECT_MESSAGES_SUCCESS,
            payload: { [thread]: response },
          });
        }
      }
    );

    chatSocketIOClient.on(
      GET_CHAT_DIRECT_MESSAGES_ERROR,
      (error: any) => error
    );

    // send message
    chatSocketIOClient.on(
      SUCCESS_OUTGOING_CHAT_DIRECT_MESSAGE,
      (response: Record<string, string | number>) => {
        const { PID } = userDataRef.current || {};
        const { sender, receiver } = response || {};
        const thread = getThreadName({
          PID,
          sender: String(sender ?? ""),
          receiver: String(receiver ?? ""),
        });

        if (thread) {
          dispatch({
            type: realTimeServicesActionTypes.SUCCESS_OUTGOING_CHAT_DIRECT_MESSAGE,
            payload: { thread, message: response },
          });
        }
        getChatThreads(rtsTokenRef.current as string)(dispatch);
      }
    );
    chatSocketIOClient.on(
      ERROR_OUTGOING_CHAT_DIRECT_MESSAGE,
      (error: Record<string, string | number>) => error
    );

    // delete message
    chatSocketIOClient.on(
      DELETE_CHAT_DIRECT_MESSAGES_SUCCESS,
      (response: Record<string, string | number>) => {
        const { PID } = userDataRef.current || {};
        const { sender, receiver } = response || {};
        const thread = getThreadName({
          PID,
          sender: String(sender ?? ""),
          receiver: String(receiver ?? ""),
        });

        if (thread) {
          dispatch({
            type: realTimeServicesActionTypes.DELETE_CHAT_DIRECT_MESSAGES_SUCCESS,
            payload: { thread, message: response },
          });
        }
        getChatThreads(rtsTokenRef.current as string)(dispatch);
      }
    );

    socketIOClient.on(
      DELETE_CHAT_DIRECT_MESSAGES_SUCCESS,
      (response: Record<string, string | number>) => {
        const { PID } = userDataRef.current || {};
        const { sender, receiver } = response || {};
        const thread = getThreadName({
          PID,
          sender: String(sender ?? ""),
          receiver: String(receiver ?? ""),
        });

        if (thread) {
          dispatch({
            type: realTimeServicesActionTypes.DELETE_CHAT_DIRECT_MESSAGES_SUCCESS,
            payload: { thread, message: response },
          });
        }
        getChatThreads(rtsTokenRef.current as string)(dispatch);
      }
    );

    socketIOClient.on(
      DELETE_CHAT_DIRECT_MESSAGES_ERROR,
      (error: Record<string, string | number>) => error
    );

    // update messages read status
    chatSocketIOClient.on(
      UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_SUCCESS,
      (response: Record<string, string | number>) => {
        const { PID } = userDataRef.current || {};
        const { sender, receiver } = response || {};
        const thread = getThreadName({
          PID,
          sender: String(sender ?? ""),
          receiver: String(receiver ?? ""),
        });
        if (thread) {
          dispatch({
            type: realTimeServicesActionTypes.UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_SUCCESS,
            payload: { thread, threadId: response.id },
          });
        } else {
          getChatThreads(rtsTokenRef.current as string)(dispatch);
        }
      }
    );
    socketIOClient.on(
      UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_SUCCESS,
      (response: Record<string, string | number>) => {
        const { PID } = userDataRef.current || {};
        const { sender, receiver } = response || {};
        const thread = getThreadName({
          PID,
          sender: String(sender ?? ""),
          receiver: String(receiver ?? ""),
        });
        if (thread) {
          dispatch({
            type: realTimeServicesActionTypes.UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_SUCCESS,
            payload: { thread, threadId: response.id },
          });
        } else {
          getChatThreads(rtsTokenRef.current as string)(dispatch);
        }
      }
    );
    socketIOClient.on(
      UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_ERROR,
      (error: Record<string, string | number>) => error
    );

    // typing message
    socketIOClient.on(
      TYPING_CHAT_DIRECT_MESSAGE,
      (response: Record<string, string | number>) => {
        dispatch({
          type: realTimeServicesActionTypes.TYPING_CHAT_DIRECT_MESSAGE,
          payload: [response.receiver],
        });

        clearTimeout(typingTimeoutRef.current as NodeJS.Timeout);

        typingTimeoutRef.current = setTimeout(() => {
          dispatch({
            type: realTimeServicesActionTypes.TYPING_CHAT_DIRECT_MESSAGE,
            payload: [],
          });
        }, 3000);
      }
    );

    return () => {
      // incoming message
      socketIOClient.off(NEW_INCOMING_CHAT_DIRECT_MESSAGE);

      // get messages
      chatSocketIOClient.off(GET_CHAT_DIRECT_MESSAGES_SUCCESS);
      chatSocketIOClient.off(GET_CHAT_DIRECT_MESSAGES_ERROR);

      // send message
      chatSocketIOClient.off(SUCCESS_OUTGOING_CHAT_DIRECT_MESSAGE);
      chatSocketIOClient.off(ERROR_OUTGOING_CHAT_DIRECT_MESSAGE);

      // delete message
      chatSocketIOClient.off(DELETE_CHAT_DIRECT_MESSAGES_SUCCESS);
      socketIOClient.off(DELETE_CHAT_DIRECT_MESSAGES_SUCCESS);
      socketIOClient.off(DELETE_CHAT_DIRECT_MESSAGES_ERROR);

      // update messages read status
      chatSocketIOClient.off(UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_SUCCESS);
      socketIOClient.off(UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_SUCCESS);
      socketIOClient.off(UPDATE_CHAT_DIRECT_MESSAGES_READ_STATUS_ERROR);

      // typing message
      socketIOClient.off(TYPING_CHAT_DIRECT_MESSAGE);
    };
  }, []);
};

export default useChatDirectMessages;
