export const EXECUTE_NOTIFICATION_START = 'EXECUTE_NOTIFICATION_START';
export const EXECUTE_NOTIFICATION_SUCCESS = 'EXECUTE_NOTIFICATION_SUCCESS';
export const EXECUTE_NOTIFICATION_FAILURE = 'EXECUTE_NOTIFICATION_FAILURE';

export const SAVE_NOTIFICATION_START = 'SAVE_NOTIFICATION_START';
export const SAVE_NOTIFICATION_SUCCESS = 'SAVE_NOTIFICATION_SUCCESS';
export const SAVE_NOTIFICATION_FAILURE = 'SAVE_NOTIFICATION_FAILURE';

export const DELETE_NOTIFICATION_START = 'DELETE_NOTIFICATION_START';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

export const GET_ALL_NOTIFICATIONS_START = 'GET_ALL_NOTIFICATIONS_START';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS';
export const GET_ALL_NOTIFICATIONS_FAILURE = 'GET_ALL_NOTIFICATIONS_FAILURE';
