import {
  SET_USER_LIMIT_FAILURE,
  SET_USER_LIMIT_START,
  SET_USER_LIMIT_SUCCESS,
} from "redux/actionTypes/customers/setUserLimitActionsTypes";
import CustomersInitialStateInterface from "types/Customer/CustomerInitialState";

const setSafeLimitReducer = (
  state: CustomersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case SET_USER_LIMIT_START: {
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          setSafeLimit: {
            data: null,
            loading: true,
            error: null,
          },
        },
      };
    }
    case SET_USER_LIMIT_SUCCESS: {
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          data: {
            ...payload.data,
          },
          setSafeLimit: {
            message: payload.message,
            loading: false,
            error: null,
          },
        },
      };
    }

    case SET_USER_LIMIT_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          setSafeLimit: {
            ...state.selectedCustomer.setSafeLimit,
            error: payload,
            loading: false,
          },
        },
      };
  }
};
export default setSafeLimitReducer;
