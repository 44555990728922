import ContentReviewInterface from 'types/contentReview';
import {
  GET_ALL_NOTIFICATIONS_FAILURE,
  GET_ALL_NOTIFICATIONS_START,
  GET_ALL_NOTIFICATIONS_SUCCESS,
} from 'redux/actionTypes/contentReview/notifications';

const getAllNotificationsReducer = (
  state: ContentReviewInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_ALL_NOTIFICATIONS_START:
      return {
        ...state,
        notificationsList: {
          ...state.notificationsList,
          loading: true,
          error: null,
        },
      };

    case GET_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsList: {
          data: payload,
          loading: false,
          error: null,
        },
      };

    case GET_ALL_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationsList: {
          ...state.notificationsList,
          loading: false,
          error: payload,
        },
      };

    default:
      return null;
  }
};

export default getAllNotificationsReducer;
