import {
  CREATE_TELLER_START,
  CREATE_TELLER_SUCCESS,
  CREATE_TELLER_FAILURE,
  CREATE_TELLER_RESET,
  UNLOCK_TELLER_START,
  UNLOCK_TELLER_SUCCESS,
  UNLOCK_TELLER_FAILURE,
} from "redux/actionTypes/cashpoints/index";

import { CashPointsInitialStateInterface } from "types/initialState";

const addTellerReducer = (
  state: CashPointsInitialStateInterface,
  { type, payload }: Record<string, any>
) => {
  switch (type) {
    case CREATE_TELLER_START:
      return {
        ...state,
        createTeller: { loading: true, data: null, error: null },
      };

    case CREATE_TELLER_SUCCESS: {
      const newTeller = {
        FirstName: payload.data.FirstName,
        LastName: payload.data.LastName,
        TellerPhone: payload.data.Phone,
        TellerEmail: payload.data.Email,
        TPID: payload.data.TPID,
        IPAddress: "",
        PictureURL: "",
      };
      return {
        ...state,
        createTeller: { loading: false, data: payload, error: null },
        tellers: {
          ...state.tellers,
          data: [newTeller].concat(state.tellers.data),
        },
      };
    }

    case CREATE_TELLER_FAILURE:
      return {
        ...state,
        createTeller: { loading: false, data: null, error: null },
      };

    case CREATE_TELLER_RESET:
      return {
        ...state,
        createTeller: { loading: false, data: null, error: null },
      };

    case UNLOCK_TELLER_START:
      return {
        ...state,
        getTellers: { ...state.getTellers, loading: true },
      };

    case UNLOCK_TELLER_SUCCESS:
      return {
        ...state,
        getTellers: { ...state.getTellers, loading: false },
      };

    case UNLOCK_TELLER_FAILURE:
      return {
        ...state,
        getTellers: { ...state.getTellers, loading: false },
      };

    default:
      return null;
  }
};

export default addTellerReducer;
