import io from "socket.io-client";

const { REACT_APP_REAL_TIME_SERVICES_URL } = process.env;

const socketIOClient = io(REACT_APP_REAL_TIME_SERVICES_URL!, {
  forceNew: true,
});
export const chatSocketIOClient = io(
  `${REACT_APP_REAL_TIME_SERVICES_URL}/chat`,
  {
    forceNew: true,
    transports: ["websocket"],
  }
);

export default socketIOClient;
