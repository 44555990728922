import {
  TRANSACTIONS_FOR_EXPORT_RESET,
  TRANSACTIONS_FOR_EXPORT_START,
  TRANSACTIONS_FOR_EXPORT_SUCCESS,
  TRANSACTIONS_FOR_EXPORT_FAILURE,
} from "redux/actionTypes/transactionReports";
import TransactionsReportInterface from "types/transactionsReport";

const getTransactionsForExportReducer = (
  state: TransactionsReportInterface,
  { type, payload }: any
) => {
  switch (type) {
    case TRANSACTIONS_FOR_EXPORT_RESET:
      return {
        ...state,
        transactionsExport: {
          data: [],
          loading: false,
          error: null,
          success: false,
        },
      };
    case TRANSACTIONS_FOR_EXPORT_START:
      return {
        ...state,
        transactionsExport: {
          data: [],
          loading: true,
          error: null,
          success: false,
        },
      };
    case TRANSACTIONS_FOR_EXPORT_SUCCESS: {
      return {
        ...state,
        transactionsExport: {
          data: payload,
          loading: false,
          error: null,
          success: true,
        },
      };
    }
    case TRANSACTIONS_FOR_EXPORT_FAILURE:
      return {
        ...state,
        transactionsExport: {
          data: [],
          error: payload,
          loading: false,
          success: false,
        },
      };
    default:
      return null;
  }
};

export default getTransactionsForExportReducer;
