const realTimeServices = {
  chat: {
    threads: {
      error: null,
      loading: false,
      message: "",
      success: false,
      data: [],
      meta: {},
    },
    directMessages: {
      error: null,
      loading: false,
      message: "",
      success: false,
      data: {},
    },
    usersTyping: [],
  },
};
export default realTimeServices;
