import {
  GET_MY_CREDIT_CARD_START,
  GET_MY_CREDIT_CARD_SUCCESS,
  GET_MY_CREDIT_CARD_FAILURE,
} from '../../actionTypes/customers/getCreditCards';

const getCreditCardsReducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case GET_MY_CREDIT_CARD_START:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          creditCardList: {
            ...state.creditCardList,
            loading: true,
            error: null,
          },
        },
      };
    case GET_MY_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          creditCardList: {
            data: payload,
            loading: false,
            error: null,
          },
        },
      };
    case GET_MY_CREDIT_CARD_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          creditCardList: {
            ...state.creditCardList,
            loading: false,
            error: payload,
          },
        },
      };
  }
};

export default getCreditCardsReducer;
