import { CSSProperties } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Table as AntTable, Spin, TableProps } from "antd";

const Table = <T extends {}>(props: TableProps<T>) => {
  const { pagination, ...restProps } = props;
  return (
    <AntTable
      className="ant-modified-table"
      components={{
        body: {
          cell: ({ children, style, ...cellProps }: any) => {
            const align = (style as CSSProperties).textAlign;
            const justifyContent =
              align === "center"
                ? "center"
                : align === "right"
                ? "flex-end"
                : "flex-left";

            return (
              <td style={style} {...cellProps}>
                <div
                  style={{ justifyContent }}
                  className="ant-modified-table__cell"
                >
                  {children}
                </div>
              </td>
            );
          },
        },
      }}
      {...restProps}
      pagination={props.pagination}
      loading={
        typeof props.loading === "boolean"
          ? {
              spinning: props.loading,
              indicator: <Spin indicator={<LoadingOutlined spin />} />,
            }
          : props.loading
      }
    />
  );
};

export default Table;
