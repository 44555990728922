export const GET_CURRENCY_LIST_START = 'GET_CURRENCY_LIST_START';
export const GET_CURRENCY_LIST_SUCCESS = 'GET_CURRENCY_LIST_SUCCESS';
export const GET_CURRENCY_LIST_FAILURE = 'GET_CURRENCY_LIST_FAILURE';

export const SET_BASE_CURRENCIES_START = 'SET_BASE_CURRENCIES_START';
export const SET_BASE_CURRENCIES_SUCCESS = 'SET_BASE_CURRENCIES_SUCCESS';
export const SET_BASE_CURRENCIES_FAILURE = 'SET_BASE_CURRENCIES_FAILURE';
export const RESTORE_SET_BASE_CURRENCIES = 'RESTORE_SET_BASE_CURRENCIES';

export const GET_BASE_CURRENCIES_START = 'GET_BASE_CURRENCIES_START';
export const GET_BASE_CURRENCIES_SUCCESS = 'GET_BASE_CURRENCIES_SUCCESS';
export const GET_BASE_CURRENCIES_FAILURE = 'GET_BASE_CURRENCIES_FAILURE';

export const SET_CROWDFUNDING_SERVICE_RATE_START =
  'SET_CROWDFUNDING_SERVICE_RATE_START';
export const SET_CROWDFUNDING_SERVICE_RATE_SUCCESS =
  'SET_CROWDFUNDING_SERVICE_RATE_SUCCESS';
export const SET_CROWDFUNDING_SERVICE_RATE_FAILURE =
  'SET_CROWDFUNDING_SERVICE_RATE_FAILURE';
export const RESTORE_SET_CROWDFUNDING_SERVICE_RATE =
  'RESTORE_SET_CROWDFUNDING_SERVICE_RATE';

export const GET_CROWDFUNDING_SERVICE_RATE_START =
  'GET_CROWDFUNDING_SERVICE_RATE_START';
export const GET_CROWDFUNDING_SERVICE_RATE_SUCCESS =
  'GET_CROWDFUNDING_SERVICE_RATE_SUCCESS';
export const GET_CROWDFUNDING_SERVICE_RATE_FAILURE =
  'GET_CROWDFUNDING_SERVICE_RATE_FAILURE';

export const GET_GLOBAL_FINANCES_START = 'GET_GLOBAL_FINANCES_START';
export const GET_GLOBAL_FINANCES_SUCCESS = 'GET_GLOBAL_FINANCES_SUCCESS';
export const GET_GLOBAL_FINANCES_FAILURE = 'GET_GLOBAL_FINANCES_FAILURE';

export const UPDATE_PARAMS_FINANCE_CONFIG_START =
  'UPDATE_PARAMS_FINANCE_CONFIG_START';
export const UPDATE_PARAMS_FINANCE_CONFIG_SUCCESS =
  'UPDATE_PARAMS_FINANCE_CONFIG_SUCCESS';
export const UPDATE_PARAMS_FINANCE_CONFIG_FAILURE =
  'UPDATE_PARAMS_FINANCE_CONFIG_FAILURE';

export const ACTIVATE_OR_DELETE_PARTNER_START =
  'ACTIVATE_OR_DELETE_PARTNER_START';
export const ACTIVATE_OR_DELETE_PARTNER_SUCCESS =
  'ACTIVATE_OR_DELETE_PARTNER_SUCCESS';
export const ACTIVATE_OR_DELETE_PARTNER_FAILURE =
  'ACTIVATE_OR_DELETE_PARTNER_FAILURE';
export const RESTORE_ACTIVATE_OR_DELETE_PARTNER =
  'RESTORE_ACTIVATE_OR_DELETE_PARTNER';

export const UPDATE_PARTNER_BALANCE_START = 'UPDATE_PARTNER_BALANCE_START';
export const UPDATE_PARTNER_BALANCE_SUCCESS = 'UPDATE_PARTNER_BALANCE_SUCCESS';
export const UPDATE_PARTNER_BALANCE_FAILURE = 'UPDATE_PARTNER_BALANCE_FAILURE';

export const UPDATE_OSSIX_BALANCE_START = 'UPDATE_OSSIX_BALANCE_START';
export const UPDATE_OSSIX_BALANCE_SUCCESS = 'UPDATE_OSSIX_BALANCE_SUCCESS';
export const UPDATE_OSSIX_BALANCE_FAILURE = 'UPDATE_OSSIX_BALANCE_FAILURE';

export const GET_MICRO_LOANS_APR_START = 'GET_MICRO_LOANS_APR_START';
export const GET_MICRO_LOANS_APR_SUCCESS = 'GET_MICRO_LOANS_APR_SUCCESS';
export const GET_MICRO_LOANS_APR_FAILURE = 'GET_MICRO_LOANS_APR_FAILURE';

export const SAVE_MICRO_LOANS_APR_START = 'SAVE_MICRO_LOANS_APR_START';
export const SAVE_MICRO_LOANS_APR_SUCCESS = 'SAVE_MICRO_LOANS_APR_SUCCESS';
export const SAVE_MICRO_LOANS_APR_FAILURE = 'SAVE_MICRO_LOANS_APR_FAILURE';
