import merchantListReducer from "./merchantList";
import addUpdateMerchantReducer from "./addUpdateMerchant";
import setWalletAutoPayStatusReducer from "./setWalletAutoPayStatus";

const merchantsReducer = (state: any, action: any) => {
  return {
    ...state,
    ...merchantListReducer(state, action),
    ...addUpdateMerchantReducer(state, action),
    ...setWalletAutoPayStatusReducer(state, action),
  };
};

export default merchantsReducer;
