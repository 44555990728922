import { EventsInitialStateInterface } from 'types/initialState';
import {
  GET_EVENTS_START,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  CLEAR_EVENTS_STATE,
} from 'redux/actionTypes/events';
import getColors from 'utils/eventRandomColors';

const getEventsReducer = (
  state: EventsInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_EVENTS_START:
      const fetchedYears = state.allEvents.fetchedYears;
      fetchedYears.push(payload);

      return {
        ...state,
        allEvents: {
          ...state.allEvents,
          getAllEvents: {
            error: null,
            loading: true,
          },
          fetchedYears: fetchedYears,
        },
      };
    case GET_EVENTS_SUCCESS:
      const availableData = state.allEvents.data;
      availableData.push(...payload.Data);

      const eventsWithColors = availableData.map((event) => {
        const colors = getColors();
        event.Colors = colors;
        return event;
      });
      return {
        ...state,
        allEvents: {
          ...state.allEvents,
          metaData: {
            ...payload.Meta,
          },
          data: eventsWithColors,
          getAllEvents: {
            error: null,
            loading: false,
          },
        },
      };
    case GET_EVENTS_FAILURE:
      return {
        ...state,
        allEvents: {
          ...state.allEvents,
          getAllEvents: {
            error: payload,
            loading: false,
          },
        },
      };

    case CLEAR_EVENTS_STATE:
      return {
        // ...initialState.events,
        allEvents: {
          metaData: {
            CurrentPage: '0',
            RecordsPerPage: '12',
            TotalPages: '0',
            TotalRecords: '0',
          },
          data: [],
          getAllEvents: {
            loading: false,
            error: null,
            message: null,
          },
          fetchedYears: [],
        },
        updateCreate: {
          loading: false,
          error: null,
          message: null,
        },
        updateImage: {
          loading: false,
          error: null,
          message: null,
        },
      };
  }
};

export default getEventsReducer;
