const checkExpiredTokenError = (error: any): boolean => {
  let hasTokenExpired = false;
  try {
    if (Array.isArray(error)) {
      error.forEach((err) => {
        if (
          typeof err === "object" &&
          err.TokenVerified &&
          err.TokenVerified.toLowerCase() === "false"
        ) {
          hasTokenExpired = true;
        }
      });
    }
  } catch (e) {
    hasTokenExpired = false;
  }
  return hasTokenExpired;
};

export default checkExpiredTokenError;
