const dashboardInitialState = {
  loadingRealTimeData: false,
  KPI: {
    data: {
      GraphData: [],
      Currencies: [],
      Customers: null,
      TransVolume: null,
      Transactions: null,
    },
    loading: false,
    error: null,
  },
  GraphData: {
    data: {
      GraphData: [],
      Currencies: [],
      Customers: null,
      TransVolume: null,
      Transactions: null,
    },
    loading: false,
    error: null,
  },
  CashCountTab: {
    data: {
      GraphData: [],
      Currencies: [],
      Customers: null,
      TransVolume: null,
      Transactions: null,
    },
    loading: false,
    error: null,
  },
  TransferCountTab: {
    data: {
      GraphData: [],
      Currencies: [],
      Customers: null,
      TransVolume: null,
      Transactions: null,
    },
    loading: false,
    error: null,
  },
  PaymentCountTab: {
    data: {
      GraphData: [],
      Currencies: [],
      Customers: null,
      TransVolume: null,
      Transactions: null,
    },
    loading: false,
    error: null,
  },
  CommissionCountTab: {
    data: {
      GraphData: [],
      Currencies: [],
      Customers: null,
      TransVolume: null,
      Transactions: null,
    },
    loading: false,
    error: null,
  },
  CashAmountTab: {
    data: {
      GraphData: [],
      Currencies: [],
      Customers: null,
      TransVolume: null,
      Transactions: null,
    },
    loading: false,
    error: null,
  },
  TransferAmountTab: {
    data: {
      GraphData: [],
      Currencies: [],
      Customers: null,
      TransVolume: null,
      Transactions: null,
    },
    loading: false,
    error: null,
  },
  PaymentAmountTab: {
    data: {
      GraphData: [],
      Currencies: [],
      Customers: null,
      TransVolume: null,
      Transactions: null,
    },
    loading: false,
    error: null,
  },
  CommissionAmountTab: {
    data: {
      GraphData: [],
      Currencies: [],
      Customers: null,
      TransVolume: null,
      Transactions: null,
    },
    loading: false,
    error: null,
  },
  accountsBalance: {
    data: { Transit: [], Trust: [], Earnings: [], CollectedTaxes: [] },
    error: null,
    loading: false,
  },
};

export default dashboardInitialState;
