import BankAccountInterface from "types/bankAccounts";
import unlinkAccountReducer from "./unlinkAccount";
import approveOrRejectBankAccount from "./approveOrRejectBankAccount";
import linkBankAccountReducer from "./linkBankAccount";
import linkBankAccountRequestReducer from "./linkBankAccountRequest";
import getBankList from "./getBankList";

const bankAccountsReducer = (state: BankAccountInterface, action: any) => {
  return {
    ...state,
    ...unlinkAccountReducer(state, action),
    ...approveOrRejectBankAccount(state, action),
    ...linkBankAccountReducer(state, action),
    ...linkBankAccountRequestReducer(state, action),
    ...getBankList(state, action),
  };
};

export default bankAccountsReducer;
