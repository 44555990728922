import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransactionReport from 'components/TransactionsReport';
import NotFoundPageComponent from 'components/NotFoundPage';
import TransactionsSummary from 'components/TransactionsReport/TransactionsSummary';

const TransactionReportPage: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/transactions-report" component={TransactionReport} />
      <Route
        path="/transactions-report/summary"
        component={TransactionsSummary}
      />
      <Route path="/transactions-report/:notfound">
        <NotFoundPageComponent link="/customer-support" />
      </Route>
    </Switch>
  );
};

export default TransactionReportPage;
