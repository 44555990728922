import React from "react";
import {
  REFRESH_TOKEN_START,
  REFRESH_TOKEN_SUCCESS,
} from "redux/actionTypes/users/refreshToken";
import logout from "utils/logout";
import apiAction from "utils/apiAction";

const refreshTokenAction =
  (prevAction: any) =>
  (dispatchAction: React.Dispatch<Record<string, unknown>>) =>
    dispatchAction(
      apiAction({
        method: "post",
        url: "/RefreshToken",
        data: {
          RefreshToken: localStorage.refreshToken,
        },
        onStart: () => (dispatch: React.Dispatch<Record<string, unknown>>) => {
          return dispatch({
            type: REFRESH_TOKEN_START,
          });
        },
        onSuccess:
          (res: any) => (dispatch: React.Dispatch<Record<string, unknown>>) => {
            const data = Array.isArray(res) ? res[0] : res;
            const { LiveToken, RefreshToken, ...user } = data;
            dispatch({
              type: REFRESH_TOKEN_SUCCESS,
              payload: user,
            });
            if (LiveToken && RefreshToken) {
              localStorage.token = LiveToken;
              localStorage.refreshToken = RefreshToken;
              dispatch(prevAction);
            }
          },
        onFailure: () => () => logout(),
      })
    );

export default refreshTokenAction;
