import FinancesInitialStateInterface from 'types/finances/financialInitialState';
import {
  ACTIVATE_OR_DELETE_PARTNER_START,
  ACTIVATE_OR_DELETE_PARTNER_SUCCESS,
  ACTIVATE_OR_DELETE_PARTNER_FAILURE,
  RESTORE_ACTIVATE_OR_DELETE_PARTNER,
} from 'redux/actionTypes/finances';

const activateOrDeletePartnerReducer = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case ACTIVATE_OR_DELETE_PARTNER_START:
      return {
        ...state,
        activateOrDeletePartner: {
          ...state.activateOrDeletePartner,
          loading: true,
          error: null,
          action: payload.action,
        },
      };
    case ACTIVATE_OR_DELETE_PARTNER_FAILURE:
      return {
        ...state,
        activateOrDeletePartner: {
          ...state.activateOrDeletePartner,
          error: payload,
          loading: false,
          success: false,
          action: '',
        },
      };
    case RESTORE_ACTIVATE_OR_DELETE_PARTNER:
      return {
        ...state,
        activateOrDeletePartner: {
          ...state.activateOrDeletePartner,
          error: null,
          loading: false,
          success: false,
          action: '',
        },
      };
    case ACTIVATE_OR_DELETE_PARTNER_SUCCESS:
      let newPartnersData = state.partners.data;
      if (payload.Delete.toLowerCase() === 'yes') {
        newPartnersData = state.partners.data.filter(
          (partner) => partner.LoginName !== payload.PartnerLoginName
        );
      } else {
        newPartnersData = state.partners.data.map((partner) => {
          if (
            partner.LoginName === payload.PartnerLoginName &&
            payload.Activate
          ) {
            return {
              ...partner,
              Status: payload.Activate.toLowerCase() === 'yes' ? '1' : '0',
              StatusText:
                payload.Activate.toLowerCase() === 'yes'
                  ? 'Active'
                  : 'Inactive',
            };
          }

          return partner;
        });
      }

      return {
        ...state,
        activateOrDeletePartner: {
          ...state.activateOrDeletePartner,
          data: payload,
          error: null,
          loading: false,
          success: true,
          action: '',
        },
        partners: {
          ...state.partners,
          data: newPartnersData,
        },
      };
    default:
      return null;
  }
};

export default activateOrDeletePartnerReducer;
