import {
  CONFIGURE_CASHPOINT_START,
  CONFIGURE_CASHPOINT_SUCCESS,
  CONFIGURE_CASHPOINT_FAILURE,
} from "redux/actionTypes/cashpoints/index";
import { CashPointsInitialStateInterface } from "types/initialState";

const cashpointReducer = (
  state: CashPointsInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case CONFIGURE_CASHPOINT_START:
      return {
        ...state,
        updateCashpoint: { loading: true, data: null, error: null },
      };

    case CONFIGURE_CASHPOINT_SUCCESS: {
      const filteredCashPoints = Array.from(state.allCashPoints.data);
      const configuredCashPoint = filteredCashPoints.findIndex(
        (cashpoint) => cashpoint.UserPID === payload.CPOwnerPID
      );
      if (configuredCashPoint >= 0) {
        filteredCashPoints[configuredCashPoint] = {
          ...filteredCashPoints[configuredCashPoint],
          ...payload,
        };
      }

      return {
        ...state,
        allCashPoints: { ...state.allCashPoints, data: filteredCashPoints },
        updateCashpoint: { loading: false, data: payload, error: null },
      };
    }

    case CONFIGURE_CASHPOINT_FAILURE:
      return {
        ...state,
        updateCashpoint: { loading: false, data: null, error: payload },
      };

    default:
      return null;
  }
};

export default cashpointReducer;
