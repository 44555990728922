import {
  APPROVE_OR_REJECT_BANK_FAILURE,
  APPROVE_OR_REJECT_BANK_SUCCESS,
  APPROVE_OR_REJECT_BANK_START,
  CLEAR_APPROVE_OR_REJECT_BANK,
} from "redux/actionTypes/banksAccounts/index";
import BankAccountInterface from "types/bankAccounts";

export default function approveOrRejectBankAccount(
  state: BankAccountInterface,
  { type, payload }: Record<string, any>
) {
  switch (type) {
    case APPROVE_OR_REJECT_BANK_START:
      return {
        ...state,
        approveOrRejectBankAccount: {
          ...state.approveOrRejectBankAccount,
          loading: true,
          error: null,
          success: false,
        },
      };
    case APPROVE_OR_REJECT_BANK_SUCCESS:
      return {
        ...state,
        approveOrRejectBankAccount: {
          ...state.approveOrRejectBankAccount,
          data: payload.data,
          loading: false,
          success: payload.success,
        },
      };
    case APPROVE_OR_REJECT_BANK_FAILURE:
      return {
        ...state,
        approveOrRejectBankAccount: {
          ...state.approveOrRejectBankAccount,
          loading: false,
          error: payload.error,
        },
      };

    case CLEAR_APPROVE_OR_REJECT_BANK:
      return {
        ...state,
        approveOrRejectBankAccount: {
          ...state.approveOrRejectBankAccount,
          loading: false,
          success: false,
          data: {},
          error: null,
        },
      };

    default:
      return null;
  }
}
