import {
  GET_COUNTRIES_START,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_END,
} from "redux/actionTypes/users/countryActionTypes";
import UsersInitialStateInterface from "types/users/userInitialState";
import { SET_SELECTED_OPS_COUNTRY } from "redux/actionTypes/users/getUserData";

const countryReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_COUNTRIES_START:
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: true,
        },
      };

    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          ...state.countries,
          data: payload,
        },
      };

    case GET_COUNTRIES_END:
      return {
        ...state,
        countries: {
          ...state.countries,
          loading: false,
        },
      };

    case GET_COUNTRIES_FAILURE:
      return {
        ...state,
        countries: { ...state.countries, loading: false, error: payload },
      };

    case SET_SELECTED_OPS_COUNTRY:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          selectedOpsCountry: payload.toUpperCase(),
        },
      };

    default:
      return null;
  }
};

export default countryReducer;
