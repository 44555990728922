import logout from "utils/logout";
import { useIntl } from "react-intl";
import getInitials from "utils/nameInitials";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import InitialStateInterface from "types/initialState";
import { PREFERRED_LANGUAGE } from "constants/general";
import setLanguageAction from "redux/actions/users/setLanguage";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import {
  Tag,
  Menu,
  Space,
  Avatar,
  Button,
  Tooltip,
  Divider,
  Dropdown,
  Typography,
} from "antd";
import CountryInterface from "types/Country";
import saveUserDataAction from "redux/actions/users/saveUserDataAction";
import Icon, { UserOutlined } from "@ant-design/icons";
import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";
import { ReactComponent as LanguageIconSVG } from "assets/icons/language.svg";
import { ReactComponent as LeftChevronIcon } from "assets/icons/left-chevron.svg";

import "./index.scss";

const typedUseSelectorHook: TypedUseSelectorHook<InitialStateInterface> =
  useSelector;

const { SubMenu } = Menu;
const { Text, Title } = Typography;

const NavBarComponent: React.FC<{
  title?: string;
  className?: string;
  fixedNavbar?: boolean;
  titleClassName?: string;
  onBack?: () => void;
}> = ({ title, titleClassName, className, fixedNavbar = false, onBack }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [country, setCountry] = useState<CountryInterface | undefined>(
    undefined
  );
  const [currentLanguage, setCurrentLanguage] = useState("English");

  const {
    users: {
      supportedLanguages: { data: languageList },
      countries: { data: operationCountries },
      currentUser: {
        preferredLanguageCode,
        data: userData,
        selectedOpsCountry,
      },
    },
    dashboard: { KPI },
    globalSettings: { globalBuiltinConfigData },
    app: {
      global: { sidebarOpen },
    },
  } = typedUseSelectorHook(({ users, app, dashboard, globalSettings }) => ({
    users,
    app,
    dashboard,
    globalSettings,
  }));
  const [currency, setCurrency] = useState<Record<string, any>>();
  const changeUserLanguage = (selectedLanguage: any) => {
    setCurrentLanguage(selectedLanguage.text);
    localStorage.setItem(
      PREFERRED_LANGUAGE,
      JSON.stringify({
        language: selectedLanguage.text,
        code: selectedLanguage.value,
      })
    );

    setLanguageAction({
      key: selectedLanguage.value,
      text: selectedLanguage.text,
    })(dispatch);

    saveUserDataAction(
      {
        DOB: userData?.DOB,
        UserPID: userData?.PID,
        LastName: userData?.LastName,
        FirstName: userData?.FirstName,
        Language: selectedLanguage.value,
        MaidenName: userData?.MaidenName,
        EmployeeNumber: userData?.EmployeeNumber,
        SecretSentence: userData?.SecretSentence,
      },
      intl,
      "update-language"
    )(dispatch);
  };

  useEffect(() => {
    setCurrentLanguage(
      languageList.find((item) => item.value === preferredLanguageCode)?.text ||
        "English"
    );
  }, [preferredLanguageCode, languageList]);
  useEffect(() => {
    setCurrency({
      Name: globalBuiltinConfigData?.data?.DefaultBaseCurrency?.Currency,
      Flag: globalBuiltinConfigData?.data?.DefaultBaseCurrency?.Flag,
      Code: globalBuiltinConfigData?.data?.DefaultBaseCurrency?.Currency,
    });
  }, [globalBuiltinConfigData]);

  useEffect(() => {
    setCountry(
      operationCountries.find(
        (country) => country.CountryCode === selectedOpsCountry
      )
    );
  }, [operationCountries, selectedOpsCountry]);

  const userAccountMenu = (
    <Menu mode="vertical" style={{ minWidth: 256 }}>
      <Menu.Item key="display" style={{ textAlign: "center" }}>
        <Space align="center" direction="vertical" style={{ marginTop: 8 }}>
          <Avatar
            size={96}
            src={`${process.env.REACT_APP_FILE_SERVICE_URL}/files/compress-remote-image?width=128&height=128&source=${userData?.PictureURL}&clearCache=true`}
            style={{
              backgroundColor: "#f7f7f7",
              textTransform: "uppercase",
              color: "#27241d",
            }}
          >
            {userData
              ? getInitials(userData.FirstName, userData.LastName)
              : "U"}
          </Avatar>
          <Space size={-4} align="center" direction="vertical">
            <Text className="app-navbar__username">{`${
              userData?.FirstName || "-"
            } ${userData?.LastName || "-"}`}</Text>
            <Text type="secondary">{userData?.PID}</Text>
            <Text type="secondary">{userData?.CountryName}</Text>
          </Space>
        </Space>
      </Menu.Item>
      <Menu.Divider />

      <SubMenu
        key="lang"
        title={currentLanguage}
        icon={<Icon component={LanguageIconSVG} />}
      >
        {languageList.map((language) => (
          <Menu.Item
            key={language.value}
            icon={
              <Avatar
                size={14}
                alt="flag"
                shape="square"
                src={language.flag}
                style={{ marginRight: 4 }}
              />
            }
            onClick={() => changeUserLanguage(language)}
          >
            {language.text}
          </Menu.Item>
        ))}
      </SubMenu>

      <Menu.Divider />
      <Menu.Item
        key="account"
        icon={<UserOutlined />}
        onClick={() => history.push("/account")}
      >
        {intl.formatMessage({
          id: "2324",
          defaultMessage: "Edit Profile",
        })}
      </Menu.Item>
      <Menu.Item
        key="logout"
        icon={<Icon component={LogoutIcon} />}
        onClick={() => logout(history)}
      >
        {intl.formatMessage({
          id: "2207",
          defaultMessage: "Log out",
        })}
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={`app-navbar ${className} ${
        fixedNavbar ? "fixed-navbar" : ""
      } ${sidebarOpen ? "sidebar-open" : ""}`}
    >
      <Title
        level={4}
        className={`app-navbar__title ${titleClassName ? titleClassName : ""}`}
      >
        {typeof onBack === "function" ? (
          <Icon
            onClick={onBack}
            component={LeftChevronIcon}
            style={{
              fontSize: 24,
              marginRight: 8,
              verticalAlign: "-0.25em",
              cursor: "pointer",
              color: "#212529",
            }}
          />
        ) : null}
        {title}
      </Title>

      <Space size={16}>
        <Space>
          <Tooltip
            title={intl.formatMessage({
              id: "2659",
              defaultMessage: "Country of operation",
            })}
          >
            <Space>
              <Avatar size={16} src={country?.Flag} shape="square" alt="flag" />
              <Text>{country?.CountryName}</Text>
            </Space>
          </Tooltip>

          <Divider type="vertical" />
          <Tooltip title={""}>
            <Tag color="cyan">
              <Space>
                <img
                  src={currency?.Flag}
                  alt=""
                  style={{
                    width: 24,
                    minHeight: 20,
                    marginTop: 4,
                    marginBottom: 4,
                  }}
                />
                <Text strong>{currency?.Code}</Text>
              </Space>
            </Tag>
          </Tooltip>

          <Divider type="vertical" style={{ height: 48 }} />
          {userData && (
            <Space size={-4} direction="vertical" align="end">
              <Text className="text-strong text-capitalize">{`${
                userData.FirstName || "-"
              } ${userData.LastName || "-"}`}</Text>
              <Text type="secondary">{userData.PID}</Text>
            </Space>
          )}
          <Divider type="vertical" style={{ height: 48 }} />
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            overlay={userAccountMenu}
          >
            <Button
              type="text"
              size="large"
              shape="circle"
              style={{ padding: 0 }}
            >
              <Avatar
                size="large"
                src={`${process.env.REACT_APP_FILE_SERVICE_URL}/files/compress-remote-image?width=128&height=128&source=${userData?.PictureURL}&clearCache=true`}
                style={{
                  color: "#27241d",
                  textTransform: "uppercase",
                  backgroundColor: "#f7f7f7",
                }}
              >
                {userData
                  ? getInitials(userData.FirstName, userData.LastName)
                  : "U"}
              </Avatar>
            </Button>
          </Dropdown>
        </Space>
      </Space>
    </div>
  );
};

export default NavBarComponent;
