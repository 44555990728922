export const APP_NAME = "APP_NAME";
export const MIN_PASSWORD_LENGTH = 8;
export const ERROR_COLOR = "#ff4d4f";
export const ACTIVE_COLOR = "#4098D7";
export const SUCCESS_COLOR = "#52c41a";
export const DEFAULT_LANGUAGE_CODE = "en";
export const DEFAULT_LANGUAGE = "English";
export const PREFERRED_LANGUAGE = "PREFERRED_LANGUAGE";
export const CELINE_MONEY_WEBSITE = "https://www.celinemoney.com/";
export const MIN_OTP_LENGTH = 4;
export const MAX_RECORDS_DOWNLOAD = 1000;

//chat status
export const SENT = "sent";
export const SEEN = "seen";
export const DELIVERED = "delivered";
export const SENDING = "sending";
export const DELETE_FOR_ME = "deleteForMe";
export const DELETE_FOR_ALL = "deleteForAll";
