/**
 * Utility to change the format of language messages from the server to React-intl format
 */
const formatLanguageMessages = (
  messages: Array<any>
): { [key: number]: string } => {
  const formattedMessages: { [key: string]: string } = {};
  for (let index = 0; index < messages.length; index++) {
    const message = messages[index];
    if (message) {
      formattedMessages[message.Index] = message.Label;
    }
  }
  return Object.assign({}, formattedMessages);
};

export default formatLanguageMessages;
