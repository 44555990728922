import * as React from "react";
import Icon from "@ant-design/icons";
import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";
import { ReactComponent as CloseIconSVG } from "assets/icons/close.svg";
import { ReactComponent as InformationIconSVG } from "assets/icons/information.svg";

class NotificationTrigger extends React.Component {
  static error: (properties: ArgsProps) => void;
  static success: (properties: ArgsProps) => void;

  render() {
    return null;
  }
}

NotificationTrigger.error = (properties) => {
  notification.error({
    ...properties,
    className:
      "ant-notification-notice__modified ant-notification-notice__error",
    closeIcon: <Icon component={CloseIconSVG} />,
    icon: <Icon component={InformationIconSVG} />,
  });
};

NotificationTrigger.success = (properties) => {
  notification.success({
    ...properties,
    className:
      "ant-notification-notice__modified ant-notification-notice__success",
    closeIcon: <Icon component={CloseIconSVG} />,
    icon: <Icon component={InformationIconSVG} />,
  });
};

export default NotificationTrigger;
