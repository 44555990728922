import React from 'react';
import { Result, Button } from 'antd';
import { Redirect } from 'react-router-dom';

interface ErrorStateInterface {
  hasError: boolean;
  redirect: boolean;
}
interface Props {
  children?: React.ReactChild;
}

class ErrorBoundaryComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, redirect: false };
  }

  static getDerivedStateFromError(): Record<string, boolean> {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(): void {
    // You can also log the error to an error reporting service
  }

  render(): React.ReactElement | React.ReactChildren | undefined {
    const { hasError, redirect } = this.state as ErrorStateInterface;

    if (hasError) {
      return (
        <>
          {!redirect ? (
            <Result
              status="500"
              title="500"
              subTitle="Sorry, something went wrong"
              extra={
                <Button
                  type="primary"
                  onClick={() =>
                    this.setState({ redirect: true, hasError: false })
                  }
                >
                  Back Home
                </Button>
              }
            />
          ) : (
            <Redirect to="/" />
          )}
        </>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundaryComponent;
