import { GlobalSettingsInitialInterface } from 'types/initialState';
import {
  GET_GLOBAL_SETTINGS_START,
  GET_GLOBAL_SETTINGS_FAILURE,
  GET_GLOBAL_SETTINGS_SUCCESS,
} from 'redux/actionTypes/globalSettings/getGlobalSettingsTypes';

const getGlobalSettingsReducer = (
  state: GlobalSettingsInitialInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_GLOBAL_SETTINGS_START:
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_GLOBAL_SETTINGS_SUCCESS:
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          loading: false,
          data: payload,
          error: null,
        },
      };

    case GET_GLOBAL_SETTINGS_FAILURE:
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          loading: false,
          error: payload,
          data: [],
        },
      };

    default:
      return null;
  }
};

export default getGlobalSettingsReducer;
