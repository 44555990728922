export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_END = "LOGIN_END";
export const RESET_ERROR = "RESET_ERROR";
export const LOGIN_STATUS_START = "LOGIN_STATUS_START";
export const LOGIN_STATUS_SUCCESS = "LOGIN_STATUS_SUCCESS";
export const LOGIN_STATUS_FAILURE = "LOGIN_STATUS_FAILURE";
export const LOGIN_STATUS_CLEAR = "LOGIN_STATUS_CLEAR";

export const REDIRECT_TO_RESET = "REDIRECT_TO_RESET";
