import {
  DELETE_USER_START,
  UNLOCK_USER_START,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  UNLOCK_USER_FAILURE,
  UNLOCK_USER_SUCCESS,
  CONFIGURE_DUAL_LOGIN_START,
  CONFIGURE_DUAL_LOGIN_SUCCESS,
  CONFIGURE_DUAL_LOGIN_FAILURE,
} from "redux/actionTypes/users/editUser";
import UsersInitialStateInterface from "types/users/userInitialState";

const editUserReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case DELETE_USER_START: {
      return {
        ...state,
        deleteUser: {
          loading: true,
          error: null,
        },
      };
    }

    case DELETE_USER_SUCCESS: {
      const users = state.allUsers.data;
      const userPid = payload.data.UserPID;
      const filteredUsers = users.filter((user) => user.PID !== userPid);

      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          data: filteredUsers,
        },
        deleteUser: {
          message: payload.message,
          loading: false,
          error: null,
        },
      };
    }

    case DELETE_USER_FAILURE:
      return {
        ...state,
        deleteUser: {
          loading: false,
          error: payload,
        },
      };

    case CONFIGURE_DUAL_LOGIN_START:
      return {
        ...state,
        dualLogin: {
          data: null,
          error: null,
          loading: true,
        },
      };

    case CONFIGURE_DUAL_LOGIN_SUCCESS: {
      const allUsers = state.allUsers.data;
      const userIndex = allUsers.findIndex(
        (user) => user.PID === payload.UserPID
      );
      if (userIndex) {
        const user = {
          ...allUsers[userIndex],
          CanSignAlone: payload.CanSignAlone,
          PeerPID: payload.PeerPID,
          BackupPeerPID: payload.BackupPeerPID,
        };

        allUsers[userIndex] = user;
      }

      return {
        ...state,
        allUsers: { ...state.allUsers, data: allUsers },
        dualLogin: {
          data: null,
          error: null,
          loading: false,
        },
      };
    }

    case CONFIGURE_DUAL_LOGIN_FAILURE:
      return {
        ...state,
        dualLogin: {
          data: null,
          loading: false,
          error: payload,
        },
      };

    case UNLOCK_USER_START:
      return {
        ...state,
        unlockUser: {
          data: payload,
          loading: true,
          error: payload,
        },
      };

    case UNLOCK_USER_SUCCESS: {
      const users = state.allUsers.data;
      const userPid = payload.data.UserPID;
      const filteredUsers = users.map((user) => {
        if (user.PID === userPid) {
          return { ...user, UserLocked: "NO" };
        } else return user;
      });

      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          data: filteredUsers,
        },
        unlockUser: {
          error: null,
          data: payload,
          loading: false,
        },
      };
    }

    case UNLOCK_USER_FAILURE:
      return {
        ...state,
        unlockUser: {
          data: null,
          loading: false,
          error: payload,
        },
      };

    default:
      return null;
  }
};
export default editUserReducer;
