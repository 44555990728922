import {
  LOGIN_STATUS_START,
  LOGIN_STATUS_SUCCESS,
  LOGIN_STATUS_FAILURE,
  LOGIN_STATUS_CLEAR,
} from "redux/actionTypes/users/login";
import UsersInitialStateInterface from "types/users/userInitialState";

const loginStatusReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case LOGIN_STATUS_START:
      return {
        ...state,
        loginStatus: {
          ...state.loginStatus,
          loading: true,
          error: null,
          success: false,
        },
      };

    case LOGIN_STATUS_FAILURE:
      return {
        ...state,
        loginStatus: {
          ...state.loginStatus,
          error: payload,
          loading: false,
          success: false,
        },
      };

    case LOGIN_STATUS_SUCCESS:
      return {
        ...state,
        loginStatus: {
          ...state.loginStatus,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    case LOGIN_STATUS_CLEAR:
      return {
        ...state,
        loginStatus: {
          ...state.loginStatus,
          error: null,
          loading: false,
          success: false,
        },
      };

    default:
      return null;
  }
};

export default loginStatusReducer;
