import { SET_APP_NAME, CHANGE_SIDEBAR_STATE } from "redux/actionTypes/app";
import ApplicationStateInterface from "types/applicationState";

const appGlobalReducer = (
  state: ApplicationStateInterface,
  { type, payload }: Record<string, unknown>
) => {
  switch (type) {
    case CHANGE_SIDEBAR_STATE:
      return {
        ...state,
        global: {
          ...state.global,
          sidebarOpen: payload,
        },
      };
    case SET_APP_NAME:
      return {
        ...state,
        global: {
          ...state.global,
          appName: payload,
        },
      };

    default:
      return null;
  }
};

export default appGlobalReducer;
