import CustomersInitialStateInterface from "types/Customer/CustomerInitialState";
import searchReducer from "./searchReducer";
import saveUserData from "./saveUseDataReducer";
import customerReducer from "./customerReducer";
import transactionsReducer from "./transactions";
import saveUserIDData from "./saveUserIDataReducer";
import uploadKYCDocs from "../customers/uploadKYCDocsReducer";
import getCreditCardsReducer from "../customers/getCreditCards";
import getVirtualCardsReducer from "../customers/getVirtualCards";
import verifyDocumentsReducer from "../customers/verifyDocumentsReducer";
import setSafeLimitReducer from "../customers/setCustomerSafeLimitReducer";
import payCommission from "../customers/payCommission";

const customersReducer = (
  state: CustomersInitialStateInterface,
  action: any
) => {
  return {
    ...state,
    ...searchReducer(state, action),
    ...transactionsReducer(state, action),
    ...customerReducer(state, action),
    ...verifyDocumentsReducer(state, action),
    ...getVirtualCardsReducer(state, action),
    ...getCreditCardsReducer(state, action),
    ...setSafeLimitReducer(state, action),
    ...uploadKYCDocs(state, action),
    ...saveUserIDData(state, action),
    ...saveUserData(state, action),
    ...payCommission(state, action),
  };
};

export default customersReducer;
