import {
  DELETE_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION_START,
  DELETE_NOTIFICATION_SUCCESS,
  EXECUTE_NOTIFICATION_FAILURE,
  EXECUTE_NOTIFICATION_START,
  EXECUTE_NOTIFICATION_SUCCESS,
  SAVE_NOTIFICATION_FAILURE,
  SAVE_NOTIFICATION_START,
  SAVE_NOTIFICATION_SUCCESS,
} from 'redux/actionTypes/contentReview/notifications';
import ContentReviewInterface from 'types/contentReview';

const notificationsCenterReducer = (
  state: ContentReviewInterface,
  { type, payload }: any
) => {
  switch (type) {
    case SAVE_NOTIFICATION_START:
      return {
        ...state,
        saveNotification: {
          data: null,
          error: null,
          loading: true,
        },
      };

    case SAVE_NOTIFICATION_SUCCESS: {
      let notificationList = state.notificationsList.data;
      const notificationID = notificationList.findIndex(
        (notification) => notification.NotificationID === payload.NotificationID
      );

      if (notificationID >= 0) {
        notificationList[notificationID] = payload;
      } else {
        notificationList = notificationList.concat([payload]);
      }

      return {
        ...state,
        notificationsList: {
          loading: false,
          error: null,
          data: notificationList,
        },
        saveNotification: {
          data: payload,
          error: null,
          loading: false,
        },
      };
    }

    case SAVE_NOTIFICATION_FAILURE:
      return {
        ...state,
        saveNotification: {
          data: null,
          error: payload,
          loading: false,
        },
      };

    case DELETE_NOTIFICATION_START:
      return {
        ...state,
        deleteNotification: {
          data: null,
          error: null,
          loading: true,
        },
      };

    case DELETE_NOTIFICATION_SUCCESS: {
      const notificationList = state.notificationsList.data.filter(
        (notification) => notification.NotificationID !== payload.NotificationID
      );

      return {
        ...state,
        notificationsList: {
          loading: false,
          error: null,
          data: notificationList,
        },
        deleteNotification: {
          data: payload,
          error: null,
          loading: false,
        },
      };
    }

    case DELETE_NOTIFICATION_FAILURE:
      return {
        ...state,
        deleteNotification: {
          data: null,
          error: null,
          loading: false,
        },
      };

    case EXECUTE_NOTIFICATION_START:
      return {
        ...state,
        executeNotification: {
          data: null,
          error: null,
          loading: true,
        },
      };

    case EXECUTE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        executeNotification: {
          data: payload,
          error: null,
          loading: false,
        },
      };

    case EXECUTE_NOTIFICATION_FAILURE:
      return {
        ...state,
        executeNotification: {
          data: null,
          error: payload,
          loading: false,
        },
      };

    default:
      return null;
  }
};

export default notificationsCenterReducer;
