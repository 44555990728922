import React from 'react';
import { useIntl } from 'react-intl';
import { Result, Button } from 'antd';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

const NotFoundPageComponent: React.FC<{ link?: string }> = ({ link }) => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <>
      <Helmet
        title={intl.formatMessage({
          id: '2001',
          defaultMessage: 'Page not found',
        })}
      />
      <Result
        title="404"
        status="404"
        subTitle={intl.formatMessage({
          id: '2002',
          defaultMessage:
            'We are sorry, but the page you requested was not found',
        })}
        extra={
          <Button
            type="primary"
            onClick={() => {
              if (link) {
                history.push(link);
              } else history.push('/');
            }}
          >
            {intl.formatMessage({
              id: '174',
              defaultMessage: 'Back',
            })}
          </Button>
        }
      />
    </>
  );
};

export default NotFoundPageComponent;
