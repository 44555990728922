import rootReducer from './reducers';
import initialState from './initialState';
import { createStore, applyMiddleware } from 'redux';
import apiMiddleware from '../middlewares/apiMiddleware';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const middleware = [apiMiddleware];

const store = createStore(
  rootReducer,
  // @ts-expect-error //TODO: Fix TS types error
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
