import {
  CREATE_CASHPOINT_OWNER_START,
  CREATE_CASHPOINT_OWNER_SUCCESS,
  CREATE_CASHPOINT_OWNER_FAILURE,
  CREATE_CASHPOINT_OWNER_RESET,
} from "redux/actionTypes/cashpoints/index";
import { CashPointsInitialStateInterface } from "types/initialState";

const createCashpointReducer = (
  state: CashPointsInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case CREATE_CASHPOINT_OWNER_START:
      return {
        ...state,

        createCashpoint: { loading: true, data: null, error: null },
      };

    case CREATE_CASHPOINT_OWNER_SUCCESS:
      return {
        ...state,
        createCashpoint: { loading: false, data: payload, error: null },
      };

    case CREATE_CASHPOINT_OWNER_FAILURE:
      return {
        ...state,
        createCashpoint: { loading: false, data: null, error: null },
      };

    case CREATE_CASHPOINT_OWNER_RESET:
      return {
        ...state,
        createCashpoint: { loading: false, data: null, error: null },
      };

    default:
      return null;
  }
};

export default createCashpointReducer;
