
import { 
    GET_OPERATORS_START, 
    GET_OPERATORS_SUCCESS, 
    GET_OPERATORS_FAILURE } 
from 'redux/actionTypes/operators';
import { Operators } from 'types/operator';

  
  const operators = (
    state: Operators,
    { type, payload }: any
  ) => {
    switch (type) {
      case GET_OPERATORS_START:
        return {
          ...state,
          operators: {
            data: null,
            loading: true,
            error: null,
          },
        };
      case GET_OPERATORS_SUCCESS: {
        return {
          ...state,
          operators: {
            data: payload,
            loading: false,
            error: null,
          },
        };
      }
      case GET_OPERATORS_FAILURE:
        return {
          ...state,
          operators: {
            error: payload,
            loading: false,
          },
        };
      default:
        return null;
    }
  };
  
  export default operators;
  