export const GET_ROLES_START = 'GET_ROLES_START';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';
export const GET_ROLES_END = 'GET_ROLES_END';

export const GET_PERMISSIONS_START = 'GET_PERMISSIONS_START';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';
export const GET_PERMISSIONS_END = 'GET_PERMISSIONS_END';

export const UPDATE_USER_ROLES_START = 'UPDATE_USER_ROLES_START';
export const UPDATE_USER_ROLES_SUCCESS = 'UPDATE_USER_ROLES_SUCCESS';
export const UPDATE_USER_ROLES_FAILURE = 'UPDATE_USER_ROLES_FAILURE';

export const UPDATE_ROLE_START = 'UPDATE_ROLE_START';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_END = 'UPDATE_ROLE_END';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';
