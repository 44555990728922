import axios from "utils/axios";
import { AxiosResponse } from "axios";
import { FetchListResponse } from "./utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Commission } from "types/Cashpoint";

export interface FetchCommissionsPayload {
  UserPID?: string; //could be a CPManager PID , a Teller ID, or Even an user OD (in ase a user has linked his/her wallet to the Teller account
  PageNumber: string;
  RecordPerPage: string;
  Status?: "PAID" | "PENDING" | "REFUSED" | "ALL"; //PENDING means not paid and active, PAID , means already paid, REFUSED means that was blocked by the BO user, so it won't be paid.
  OwnerPID: string;
  LoginName?: string;
  APIKey?: string;
  AppID?: string;
  DateFrom: string;
  DateTo: string;
  Currency?: string;
  CountryCode?: string;
  MaxRecordsReturned: string;
}

interface payOrRejectCommission {
  PIN: string;
  CountryCode: string;
  Action: "PAY" | "REJECT";
  All?: "YES" | "NO";
  UserPID?: string;
  Commissions?: Commission[];
}

const fetchCommissions = (payload: FetchCommissionsPayload) => {
  return axios
    .post<any, AxiosResponse<FetchListResponse<Commission>[]>>(
      "GetPendingCPCommissions",
      payload
    )
    .then((response) => response.data[0]);
};

export const useFetchCommissions = (payload: FetchCommissionsPayload) => {
  return useQuery({
    queryKey: [
      "GetPendingCPCommissions",
      payload.UserPID,
      payload.Status,
      payload.PageNumber,
      payload.RecordPerPage,
    ],
    refetchOnMount: false,
    queryFn: () => fetchCommissions(payload),
  });
};

const payOfRejectCPCommission = (payload: payOrRejectCommission) => {
  return axios
    .post<any, AxiosResponse<FetchListResponse<Commission>[]>>(
      "PayRejectCPCommissions",
      payload
    )
    .then((response) => {
      return response.data[0];
    });
};

export const usePayOfRejectCPCommission = (args?: {
  onSuccess?: any;
  onError?: any;
}) => {
  return useMutation({
    mutationKey: ["PayRejectCPCommissions"],
    mutationFn: payOfRejectCPCommission,
    onSuccess: (_data, variables) => {
      args?.onSuccess?.(_data);
    },
    onError: (err, variables) => {
      args?.onError?.(err);
    },
  });
};
