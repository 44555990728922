import ReactGA from "react-ga";
import { useIntl } from "react-intl";
import queryString from "query-string";
import UserInterface from "types/user";
import logoutHelper, { redirectWithNext } from "utils/logout";
import IdleTimer from "react-idle-timer";
import { createBrowserHistory } from "history";
import SideBarComponent from "components/Sidebar";
import useSocketIO from "services/socketIO/events/index";
import InitialStateInterface from "./types/initialState";
import getLocation from "redux/actions/users/getLocation";
import ErrorBoundaryComponent from "components/ErrorBoundary";
import setLanguageAction from "redux/actions/users/setLanguage";
import TransactionReportPage from "pages/TransactionsReportPage";
import { Modal, Spin, Layout, Row, Space, Typography } from "antd";
import React, { useEffect, useState, useRef, Suspense } from "react";
import { Router, Switch, Route, useHistory } from "react-router-dom";
import { INITIATE_NEW_POSTS } from "redux/actionTypes/contentReview";
import getCountriesAction from "redux/actions/users/getCountryAction";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import getSupportedLanguages from "redux/actions/users/getSupportedLanguages";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { setCurrentUserAction } from "redux/actions/users/setCurrentUserAction";
import getBuiltinConfigDataAction from "redux/actions/globalSettings/getBuilltinConfigData";
import { useFetchCountryData } from "./queries";

const CustomerSupportPage = React.lazy(
  () => import("pages/CustomerSupportPage")
);
const LoginPage = React.lazy(() => import("pages/LoginPage"));
const ResetPage = React.lazy(() => import("pages/ResetPage"));
const EventsPage = React.lazy(() => import("pages/EventsPage"));
const FinancePage = React.lazy(() => import("pages/FinancePage"));
const AccountPage = React.lazy(() => import("pages/AccountPage"));
const Organizations = React.lazy(() => import("pages/Organizations"));
const Operators = React.lazy(() => import("pages/Operators"));
const Merchants = React.lazy(() => import("pages/Merchants"));
const DashboardPage = React.lazy(() => import("pages/DashboardPage"));
const CashPointsPage = React.lazy(() => import("pages/CashPointsPage"));
const NotFoundPage = React.lazy(() => import("components/NotFoundPage"));
const CompanySettingsPage = React.lazy(() => import("pages/GlobalParameters"));
const ContentReviewPage = React.lazy(() => import("pages/ContentReviewPage"));
const UserManagementPage = React.lazy(() => import("pages/UserManagementPage"));
const NotificationCenterPage = React.lazy(
  () => import("pages/NotificationCenterPage")
);

const { Text } = Typography;
const { REACT_APP_GA_TRACKER_ID } = process.env;

const typedUseSelector: TypedUseSelectorHook<InitialStateInterface> =
  useSelector;
const browsersHistory = createBrowserHistory();

ReactGA.initialize(REACT_APP_GA_TRACKER_ID || "");
browsersHistory.listen((location) => {
  const queryParams = queryString.parseUrl(window.location.href, {
    parseFragmentIdentifier: true,
  });
  ReactGA.pageview(
    location.pathname + location.search + queryParams.fragmentIdentifier || ""
  );
});

const RoutesComponent: React.FC = () => {
  const intl = useIntl();
  const appRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const {
    currentUser: { data: currentUserData, selectedOpsCountry },
    appState: { appName },
  } = typedUseSelector(({ users, app }) => ({
    currentUser: users.currentUser,
    appState: app.global,
  }));

  useFetchCountryData({ CountryCode: selectedOpsCountry });

  const MAX_USER_IDLE_TIME = Number(localStorage.getItem("MAX_USER_IDLE_TIME"));
  const DEBOUNCE_TIME = MAX_USER_IDLE_TIME * (1 / 4);
  const INITIAL_TIMEOUT_DURATION = Math.floor(MAX_USER_IDLE_TIME * (3 / 4));

  const sessionTimeoutRef = useRef(setTimeout(() => null, DEBOUNCE_TIME));

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { confirm } = Modal;

  const SuspenseLoader = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const showConfirm = () => {
    confirm({
      title: intl.formatMessage({
        id: "1340",
        defaultMessage: "Account has no activity",
      }),
      content: intl.formatMessage({
        id: "1341",
        defaultMessage:
          "Your account has been idle for a while, you are going to be logged out soon.",
      }),
      icon: <ExclamationCircleOutlined />,
      okText: intl.formatMessage({
        id: "1899",
        defaultMessage: "Keep me signed in.",
      }),
      okType: "danger",
      cancelText: intl.formatMessage({
        id: "1342",
        defaultMessage: "Log me out",
      }),
      onOk: stayActive,
      onCancel: logUserOut,
    });
  };

  const stayActive = () => {
    clearTimeout(sessionTimeoutRef?.current);
  };

  const logUserOut = () => {
    localStorage.userWasIdle = true;
    logoutHelper(history);
  };

  const onIdle = () => {
    showConfirm();
    sessionTimeoutRef.current = setTimeout(logUserOut, DEBOUNCE_TIME);
  };

  useSocketIO();

  useEffect(() => {
    if (selectedOpsCountry) {
      getBuiltinConfigDataAction(selectedOpsCountry)(dispatch);
    }
  }, [selectedOpsCountry, dispatch]);

  useEffect(() => {
    getSupportedLanguages()(dispatch);
    getCountriesAction()(dispatch);
    getLocation()(dispatch);
    dispatch({ type: INITIATE_NEW_POSTS });
  }, [dispatch]);

  useEffect(() => {
    if (!currentUserData && localStorage.token && localStorage.user) {
      const user = JSON.parse(localStorage.user) as UserInterface;
      setCurrentUserAction(Array.isArray(user) ? user[0] : user)(dispatch);
      setLanguageAction({ key: user.Language, text: user.Language })(dispatch);
    }
    /**
     * TODO: upgrade react-router-dom@latest, update the auth verification
     * @link https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
     */
    if (
      window.location.pathname !== "/login" &&
      !currentUserData &&
      !localStorage.token &&
      !localStorage.user
    ) {
      redirectWithNext("/login");
    }
  }, [currentUserData, dispatch]);

  const mainRoutes = [
    {
      path: "/",
      exact: true,
      Component: DashboardPage,
    },
    {
      exact: false,
      path: "/account",
      Component: AccountPage,
    },
    {
      exact: false,
      path: "/user-management",
      Component: UserManagementPage,
    },
    {
      exact: false,
      path: "/customer-support",
      Component: CustomerSupportPage,
    },
    {
      exact: false,
      path: "/cashpoints",
      Component: CashPointsPage,
    },
    {
      exact: false,
      path: "/finances",
      Component: FinancePage,
    },
    {
      exact: true,
      path: "/v2/company-settings",
      Component: CompanySettingsPage,
    },
    {
      exact: true,
      path: "/events",
      Component: EventsPage,
    },
    { path: "/content-review", exact: true, Component: ContentReviewPage },
    {
      exact: false,
      path: "/notification-center",
      Component: NotificationCenterPage,
    },
    {
      exact: false,
      path: "/organizations",
      Component: Organizations,
    },
    {
      exact: false,
      path: "/operators",
      Component: Operators,
    },
    {
      path: "/Merchants",
      Component: Merchants,
    },
    {
      exact: false,
      path: "/transactions-report",
      Component: TransactionReportPage,
    },
  ];

  return (
    <Router history={browsersHistory}>
      <ErrorBoundaryComponent>
        <IdleTimer
          ref={appRef}
          element={document}
          timeout={INITIAL_TIMEOUT_DURATION}
          onIdle={() => {
            if (localStorage.token && process.env.NODE_ENV !== "development") {
              onIdle();
            }
          }}
        >
          <Layout style={{ backgroundColor: "#ffffff" }}>
            <Suspense
              fallback={
                <Row justify="center" align="middle" style={{ marginTop: 64 }}>
                  <Space direction="vertical" align="center">
                    <Spin indicator={SuspenseLoader} />
                    <Text>{`${intl.formatMessage({
                      id: "194",
                      defaultMessage: "Loading",
                    })}...`}</Text>
                  </Space>
                </Row>
              }
            >
              <Switch>
                <Route exact={true} path="/login">
                  <Layout
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "100vh",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <LoginPage />
                  </Layout>
                </Route>
                <Route exact={true} path="/change-password-pin">
                  <Layout
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "100vh",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <ResetPage />
                  </Layout>
                </Route>

                {mainRoutes.map((ActiveRoute, index) => (
                  <Route
                    path={ActiveRoute.path}
                    exact={ActiveRoute.exact}
                    key={index}
                  >
                    <SideBarComponent
                      appName={appName}
                      isCollapsed={isCollapsed}
                      setIsCollapsed={setIsCollapsed}
                    />
                    <Layout
                      className={`site-layout ${
                        isCollapsed ? "is_collapsed" : ""
                      }`}
                    >
                      <Layout className="apps-container">
                        <Suspense
                          fallback={
                            <Row
                              justify="center"
                              align="middle"
                              style={{ marginTop: 64 }}
                            >
                              <Space direction="vertical" align="center">
                                <Spin indicator={SuspenseLoader} />
                                <Text>{`${intl.formatMessage({
                                  id: "194",
                                  defaultMessage: "Loading",
                                })}...`}</Text>
                              </Space>
                            </Row>
                          }
                        >
                          <ActiveRoute.Component />
                        </Suspense>
                      </Layout>
                    </Layout>
                  </Route>
                ))}
                <Route component={NotFoundPage} />
              </Switch>
            </Suspense>
          </Layout>
        </IdleTimer>
      </ErrorBoundaryComponent>
    </Router>
  );
};

export default RoutesComponent;
