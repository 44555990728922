import {
  SAVE_TELLERS,
  SAVE_CASHPOINTS,
  GET_TELLER_START,
  GET_TELLER_RESET,
  GET_TELLER_SUCCESS,
  GET_TELLER_FAILURE,
} from "redux/actionTypes/cashpoints/index";

import { CashPointsInitialStateInterface } from "types/initialState";

const getTellersReducer = (
  state: CashPointsInitialStateInterface,
  { type, payload }: Record<string, any>
) => {
  switch (type) {
    case GET_TELLER_START:
      return {
        ...state,
        getTellers: { loading: true, data: [], error: null },
      };

    case GET_TELLER_SUCCESS:
      return {
        ...state,
        getTellers: { loading: false, data: payload, error: null },
      };

    case GET_TELLER_FAILURE:
      return {
        ...state,
        getTellers: { loading: false, data: [], error: null },
      };

    case GET_TELLER_RESET:
      return {
        ...state,
        getTellers: { loading: false, data: [], error: null },
      };

    case SAVE_TELLERS:
      return {
        ...state,
        tellers: { data: payload },
      };

    case SAVE_CASHPOINTS:
      return {
        ...state,
        cashpoints: { data: payload },
      };

    default:
      return null;
  }
};

export default getTellersReducer;
