import {
  RESET_START,
  RESET_FAILURE,
  RESET_SUCCESS,
  CLEAR_RESET_DATA,
} from "redux/actionTypes/users/reset";
import {
  CHANGE_BO_USER_PWD_PIN_FAILURE,
  CHANGE_BO_USER_PWD_PIN_START,
  CHANGE_BO_USER_PWD_PIN_SUCCESS,
} from "redux/actionTypes/users/changeBOuserPwdPIN";
import UsersInitialStateInterface from "types/users/userInitialState";

const resetReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case CHANGE_BO_USER_PWD_PIN_START:
      return {
        ...state,
        reset: {
          ...state.reset,
          loading: true,
          error: null,
          success: false,
        },
      };

    case CHANGE_BO_USER_PWD_PIN_SUCCESS:
      return {
        ...state,
        reset: {
          ...state.reset,
          loading: false,
          error: null,
          success: true,
        },
      };

    case CHANGE_BO_USER_PWD_PIN_FAILURE:
      return {
        ...state,
        reset: {
          ...state.reset,
          loading: false,
          error: null,
          success: false,
        },
      };

    case RESET_START:
      return {
        ...state,
        reset: {
          ...state.reset,
          loading: true,
          error: null,
          success: false,
        },
      };

    case RESET_FAILURE:
      return {
        ...state,
        reset: {
          ...state.reset,
          error: payload,
          loading: false,
          success: false,
        },
      };

    case RESET_SUCCESS:
      return {
        ...state,
        reset: {
          ...state.reset,
          error: null,
          loading: false,
          success: true,
        },
      };

    case CLEAR_RESET_DATA:
      return {
        ...state,
        reset: {
          error: null,
          loading: false,
          success: false,
        },
      };

    default:
      return null;
  }
};

export default resetReducer;
