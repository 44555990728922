import appInitialState from "./appInitialState";
import userInitialSate from "./usersInitialState";
import eventsInitialState from "./eventsInitialState";
import financialInitialState from "./financeInitialState";
import dashboardInitialState from "./dashboardInitialState";
import customersInitialState from "./customersInitialState";
import cashpointsInitialState from "./cashpointsInitialState";
import contentInitialState from "./contentReviewInitialState";
import globalParametersInitialState from "./globalParametersInitialState";
import transactionsReportInitialState from "./transactionsReportInitialState";
import sendEmail from "./sendEmail";
import bulkPaymentInitialState from "./bulkOrganization";
import bankAccounts from "./bankAccounts";
import operatorState from "./operators";
import merchantInitialState from "./merchants";
import realTimeServicesState from "./realTimeServices";
import walletsWithRecentOps from "./walletsWithRecentOps";

export default {
  app: appInitialState,
  users: userInitialSate,
  events: eventsInitialState,
  finances: financialInitialState,
  dashboard: dashboardInitialState,
  customers: customersInitialState,
  contentReview: contentInitialState,
  cashPoints: cashpointsInitialState,
  globalSettings: globalParametersInitialState,
  transactionsReport: transactionsReportInitialState,
  sendEmail: sendEmail,
  bulkOrganization: bulkPaymentInitialState,
  bankAccounts,
  operators: operatorState,
  merchants: merchantInitialState,
  realTimeServices: realTimeServicesState,
  walletsWithRecentOps: walletsWithRecentOps,
};
