import {
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_FLAT_SUCCESS,
  GET_USERS_FLAT_FAILURE,
  GET_USERS_FLAT_START,
} from "redux/actionTypes/users/getUserData";
import { SET_LANGUAGE } from "redux/actionTypes/users/language";

const userDataReducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case SET_LANGUAGE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          preferredLanguage: payload.language,
          preferredLanguageCode: payload.code,
          updateUserLanguagePreference: true,
        },
      };

    case GET_USERS_START:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          loading: true,
          error: null,
        },
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          data: payload.merge
            ? state.allUsers.data.concat(payload.data)
            : payload.data,
          loading: false,
          error: null,
          metaData: {
            CurrentPage: payload.meta.CurrentPage,
            RecordsPerPage: payload.meta.RecordsperPage,
            TotalPages: payload.meta.TotalPages,
            TotalRecords: payload.meta.TotalRecords,
          },
        },
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          data: [],
          loading: false,
          error: payload,
        },
      };

    case GET_USERS_FLAT_START:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          flatList: { data: [], loading: true, error: null },
        },
      };

    case GET_USERS_FLAT_SUCCESS:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          flatList: { data: payload.data, loading: false, error: null },
        },
      };

    case GET_USERS_FLAT_FAILURE:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          flatList: { data: [], loading: false, error: payload },
        },
      };

    default:
      return null;
  }
};

export default userDataReducer;
