import { IntlShape } from "react-intl";
import apiAction from "utils/apiAction";
import {
  GET_TRANSACTIONS_SUMMARY_START,
  GET_TRANSACTIONS_SUMMARY_SUCCESS,
  GET_TRANSACTIONS_SUMMARY_FAILURE,
  TRANSACTIONS_REPORT_SEARCH_START,
  TRANSACTIONS_REPORT_SEARCH_SUCCESS,
  TRANSACTIONS_REPORT_SEARCH_FAILURE,
  TRANSACTIONS_FOR_EXPORT_START,
  TRANSACTIONS_FOR_EXPORT_SUCCESS,
  TRANSACTIONS_FOR_EXPORT_FAILURE,
} from "redux/actionTypes/transactionReports";
import NotificationTrigger from "components/Alert/Notification";

export const getTransactionSummaryDataAction = (CountryCode: string) => {
  return (dispatchAction: any) =>
    dispatchAction(
      apiAction({
        method: "POST",
        url: "/BOGetCountsReport",
        data: { CountryCode },
        onStart: () => (dispatch) => {
          dispatch({
            type: GET_TRANSACTIONS_SUMMARY_START,
          });
        },
        onSuccess: (data) => (dispatch) => {
          dispatch({
            type: GET_TRANSACTIONS_SUMMARY_SUCCESS,
            payload: data[0],
          });
        },
        onFailure: (error) => (dispatch) => {
          dispatch({
            type: GET_TRANSACTIONS_SUMMARY_FAILURE,
            payload: error,
          });
        },
      })
    );
};

export const searchTransactionReportAction = (
  formData: Record<string, unknown>
) => {
  return (dispatchAction: any) =>
    dispatchAction(
      apiAction({
        method: "POST",
        url: "/GetTransactions",
        data: {
          Proxy: "YES",
          RecordPerPage: "32",
          PageNumber: "1",
          ...formData,
        },
        onStart: () => (dispatch) => {
          dispatch({
            type: TRANSACTIONS_REPORT_SEARCH_START,
          });
        },
        onSuccess: (data) => (dispatch) => {
          if (Array.isArray(data) && data[0]?.Error === "2016") {
            dispatch({
              type: TRANSACTIONS_REPORT_SEARCH_SUCCESS,
              payload: [],
            });
          } else
            dispatch({
              type: TRANSACTIONS_REPORT_SEARCH_SUCCESS,
              payload: data[0].Data,
            });
        },
        onFailure: (error) => (dispatch) => {
          dispatch({
            type: TRANSACTIONS_REPORT_SEARCH_FAILURE,
            payload: error,
          });
        },
      })
    );
};

export const getTransactionForExportAction = (
  payload: any,
  intl: IntlShape,
  previousData?: Array<any>
) => {
  if (payload?.AllPages === true && payload?.MaxPages > 1) {
    NotificationTrigger.success({
      message: intl?.formatMessage({
        id: "dd.new",
        defaultMessage: "Export transactions",
      }),
      description: `Page ${payload?.PageNumber} download in progress...`,
      duration: 2,
    });
  }
  return (dispatchAction: any) =>
    dispatchAction(
      apiAction({
        method: "POST",
        url: "/GetTransactions",
        data: payload,
        onStart: () => (dispatch) => {
          dispatch({
            type: TRANSACTIONS_FOR_EXPORT_START,
          });
        },
        onSuccess: (response) => (dispatch) => {
          const combinedData = Array.isArray(previousData)
            ? previousData?.concat?.(response?.[0]?.Data)
            : response?.[0]?.Data;
          if (
            payload?.AllPages === true &&
            payload?.PageNumber < payload?.MaxPages
          ) {
            getTransactionForExportAction(
              { ...payload, PageNumber: payload.PageNumber + 1 },
              intl,
              combinedData
            )(dispatchAction);
            return;
          }
          dispatch({
            type: TRANSACTIONS_FOR_EXPORT_SUCCESS,
            payload: {
              ...response[0],
              Data: combinedData?.map((item: any) => ({
                ...item,
                CashPointPID: item?.Teller?.CashPointPID,
                CashPointManagerPID: item?.Teller?.ManagerPID,
                TellerPID: item?.Teller?.TellerPID,
                TellerFirstName: item?.Teller?.FirstName,
                TellerLastName: item?.Teller?.LastName,
                TellerPhoneNumber: item?.Teller?.PhoneNumber,
              })),
            },
          });

          NotificationTrigger.success({
            message: intl?.formatMessage({
              id: "dd.new",
              defaultMessage: "Export transactions",
            }),
            description: intl?.formatMessage({
              id: "dd.new",
              defaultMessage: "Download finished",
            }),
          });
        },
        onFailure: (error) => (dispatch) => {
          dispatch({
            type: TRANSACTIONS_FOR_EXPORT_FAILURE,
            payload: error,
          });
        },
      })
    );
};
