import {
  ADD_UPDATE_OPERATOR_START,
  ADD_UPDATE_OPERATOR_SUCCESS,
  ADD_UPDATE_OPERATOR_FAILURE,
} from "redux/actionTypes/operators";

const addUpdateOperator = (state: any, { type, payload }: any) => {
  switch (type) {
    case ADD_UPDATE_OPERATOR_START:
      return {
        ...state,
        addUpdateOperator: { loading: true, data: null, error: null },
      };

    case ADD_UPDATE_OPERATOR_SUCCESS: {
      const newOperatorData = Object.assign({}, payload);
      let listData = Array.from(state.operators?.data?.[0].Operators);
      if (listData) {
        const oldOpIndex = listData && listData?.findIndex(
          ({ OperatorID }: any) => OperatorID === payload.OperatorID
        );
        if (oldOpIndex !== -1) {
          listData.splice(oldOpIndex, 1, newOperatorData);
        } else {
          listData.unshift(newOperatorData);
        }
      } else {
        listData = []
      }

      const operatorData = state.operators.data[0]
      return {
        ...state,
        operators: {
          ...state.operators,
          data: [{ ...operatorData, Operators: listData }],
        },
        addUpdateOperator: { loading: false, data: payload, error: null },
      };
    }

    case ADD_UPDATE_OPERATOR_FAILURE:
      return {
        ...state,
        operators: { ...state.operators },
        addUpdateOperator: { loading: false, data: null, error: null },
      };

    default:
      return null;
  }
};

export default addUpdateOperator;
