import CustomersInitialStateInterface from 'types/Customer/CustomerInitialState'


const customersInitialState: CustomersInitialStateInterface = {
	professions: {
		data: [],
		loading: false,
	},
	search: {
		results: {
			Meta: {
				CurrentPage: '1',
				RecordsperPage: '8',
				TotalPages: '0',
				TotalRecords: '0',
			},
			Data: [],
		},
		fetchOnSelect: false,
		loading: false,
	},
	selectedCustomer: {
		transactions: { data: [], loading: false },
		userPID: '',
		loading: false,
		verifyCustomer: {
			error: null,
			loading: false,
		},
		resetAccountCredentials: {
			loading: false,
		},
		setAccountStatus: {
			loading: false,
			error: null,
		},
		verifyKYDoc: {
			loading: false,
			error: null,
		},
		virtualCardList: {
			data: [],
			loading: false,
		},
		creditCardList: {
			data: [],
			loading: false,
		},
		setSafeLimit: {
			loading: false,
		},
		uploadKYCDocs: {
			loading: false,
			success: false,
		},
		saveUserIDData: {
			loading: false,
			success: false,
		},
		saveUserData: {
			loading: false,
			success: false,
		},
	},
	payReferralCommission: {
		data: null,
		loading: false,
		error: null,
	  },
}

export default customersInitialState
