import {
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_START,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_ALL_DASHBOARD_DATA_END,
  GET_ALL_DASHBOARD_DATA_START,
  GET_ALL_DASHBOARD_DATA_SUCCESS,
} from 'redux/actionTypes/dashboard';
import DashboardInitialStateInterface from 'types/dashboard';

const dashboardKPIDataReducer = (
  state: DashboardInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_ALL_DASHBOARD_DATA_START:
      return { ...state, loadingRealTimeData: true };

    case GET_ALL_DASHBOARD_DATA_SUCCESS:
      return { ...state, loadingRealTimeData: false };

    case GET_ALL_DASHBOARD_DATA_END:
      return { ...state, loadingRealTimeData: false };

    case GET_DASHBOARD_DATA_START:
      return {
        ...state,
        KPI: {
          ...state.KPI,
          loading: true,
          error: null,
        },
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      delete payload.OK;
      delete payload.Result;
      delete payload.DataFound;
      return {
        ...state,
        KPI: { data: payload, loading: false, error: null },
      };

    case GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        KPI: {
          ...state.KPI,
          error: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};

export default dashboardKPIDataReducer;
