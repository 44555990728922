const contentInitialState = {
  reportedContent: {
    data: {
      Data: [],
      Meta: { TotalRecords: '0' },
      loading: false,
      error: null,
    },
  },
  newPosts: {},
  notificationsList: {
    data: [],
    loading: false,
    error: null,
  },
  saveNotification: {
    data: null,
    loading: false,
    error: null,
  },
  deleteNotification: {
    data: null,
    loading: false,
    error: null,
  },
  executeNotification: {
    data: null,
    loading: false,
    error: null,
  },
};

export default contentInitialState;
