import {
  WALLETS_WITH_RECENT_OPS_TO_EXPORT_RESET,
  WALLETS_WITH_RECENT_OPS_TO_EXPORT_START,
  WALLETS_WITH_RECENT_OPS_TO_EXPORT_SUCCESS,
  WALLETS_WITH_RECENT_OPS_TO_EXPORT_FAILURE,
} from "redux/actionTypes/finances/walletRecentOps";
import WalletsRecentOpsInterface from "types/finances/walletsRecentOps";

const getWalletsWithRecentOpsToExport = (
  state: WalletsRecentOpsInterface,
  { type, payload }: any
) => {
  switch (type) {
    case WALLETS_WITH_RECENT_OPS_TO_EXPORT_RESET:
      return {
        ...state,
        walletsWithRecentOpsExport: {
          data: {},
          loading: false,
          error: null,
          success: false,
        },
      };
    case WALLETS_WITH_RECENT_OPS_TO_EXPORT_START:
      return {
        ...state,
        walletsWithRecentOpsExport: {
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case WALLETS_WITH_RECENT_OPS_TO_EXPORT_SUCCESS: {
      return {
        ...state,
        walletsWithRecentOpsExport: {
          data: payload,
          loading: false,
          error: null,
          success: true,
        },
      };
    }
    case WALLETS_WITH_RECENT_OPS_TO_EXPORT_FAILURE:
      return {
        ...state,
        walletsWithRecentOpsExport: {
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
      };
    default:
      return null;
  }
};

export default getWalletsWithRecentOpsToExport;
