import {
  GET_COUNTRIES_START,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_END,
} from 'redux/actionTypes/users/countryActionTypes';
import apiAction from 'utils/apiAction';

const getCountriesAction = () => {
  return (dispatchAction: any) =>
    dispatchAction(
      apiAction({
        method: 'POST',
        url: '/OssixCountries',
        onStart: () => (dispatch) => {
          dispatch({ type: GET_COUNTRIES_START });
        },
        onSuccess: (data) => (dispatch) => {
          dispatch({
            type: GET_COUNTRIES_SUCCESS,
            payload: Array.isArray(data) ? data : [],
          });
        },
        onFailure: (err) => (dispatch) => {
          const error = Array.isArray(err) ? err[0] || {} : err || {};
          dispatch({
            type: GET_COUNTRIES_FAILURE,
            payload: error,
          });
        },
        onEnd: () => (dispatch) => {
          dispatch({ type: GET_COUNTRIES_END });
        },
      })
    );
};

export default getCountriesAction;
