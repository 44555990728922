import UsersInitialStateInterface from "types/users/userInitialState";
import { PeerApprovalStatus } from "types/general.d";
import {
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "redux/actionTypes/users/logout";

const logoutReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case LOGOUT_START:
      return { ...state };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isAuth: false,
          data: undefined,
          isLoggedIn: false,
          approvalStatus: PeerApprovalStatus.APPROVAL_NONE,
          selectedOpsCountry: undefined,
        },
      };

    case LOGOUT_FAILURE:
      return {
        ...state,
        currentUser: { ...state.currentUser, logoutError: payload },
      };
    default:
      return null;
  }
};

export default logoutReducer;
