export const WALLETS_WITH_RECENT_OPS_START = "WALLETS_WITH_RECENT_OPS_START";
export const WALLETS_WITH_RECENT_OPS_SUCCESS =
  "WALLETS_WITH_RECENT_OPS_SUCCESS";
export const WALLETS_WITH_RECENT_OPS_FAILURE =
  "WALLETS_WITH_RECENT_OPS_FAILURE";

export const WALLETS_WITH_RECENT_OPS_TO_EXPORT_START =
  "WALLETS_WITH_RECENT_OPS_TO_EXPORT_START";
export const WALLETS_WITH_RECENT_OPS_TO_EXPORT_SUCCESS =
  "WALLETS_WITH_RECENT_OPS_TO_EXPORT_SUCCESS";
export const WALLETS_WITH_RECENT_OPS_TO_EXPORT_FAILURE =
  "WALLETS_WITH_RECENT_OPS_TO_EXPORT_FAILURE";
export const WALLETS_WITH_RECENT_OPS_TO_EXPORT_RESET =
  "WALLETS_WITH_RECENT_OPS_TO_EXPORT_RESET";
