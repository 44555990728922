export enum PermissionLevel {
  READ_ONLY = "1",
  WRITE = "2",
}

export enum PermissionNames {
  USER_ADMIN = "1", // user management
  FINANCIAL_CONF = "5", // finance , cash points
  GLOBAL_PARAMS = "2", // company settings
  CUSTOMER_SUPPORT = "4", // customer support
  REPORTING = "8",
  MARKETING = "7",
  PARTNERS = "9",
}

export enum RoleNames {
  GLOBAL_ADMIN = 'Global Administrator',
  CONFIGURATOR = 'Configurator',
  USER_ADMIN = 'User Administrators',
}

export enum ReportedItemTypeCode {
  SERVICE = "1",
  SERVICE_COMMENT = "2",
  STORE_COMMENT = "3",
}

export enum DataPagination {
  DEFAULT_PAGE_NUMBER = "1",
  DEFAULT_PAGE_SIZE = "10",
}
