export enum PeerApprovalStatus {
  APPROVAL_NONE = "APPROVAL_NONE",
  APPROVAL_WAITING = "APPROVAL_WAITING",
  APPROVAL_TIME_OUT = "APPROVAL_TIME_OUT",
  APPROVAL_ACCEPTED = "APPROVAL_ACCEPTED",
  APPROVAL_REJECTED = "APPROVAL_REJECTED",
  APPROVAL_REQUESTED = "APPROVAL_REQUESTED",
}

const userInitialSate = {
  currentUser: {
    isAuth: false,
    data: undefined,
    isLoggedIn: false,
    preferredLanguageCode: "en",
    preferredLanguage: "English",
    selectedOpsCountry: undefined,
    updateUserLanguagePreference: false,
    approvalStatus: PeerApprovalStatus.APPROVAL_NONE,
  },
  allUsers: {
    data: [],
    error: null,
    loading: false,
    metaData: {
      CurrentPage: "0",
      RecordsPerPage: "120",
      TotalPages: "0",
      TotalRecords: "0",
    },
    flatList: { data: [], loading: false, error: null },
  },
  unlockUser: {
    loading: false,
    data: null,
    error: null,
  },
  dualLogin: {
    loading: false,
    error: null,
  },
  deleteUser: {
    message: null,
    loading: false,
    error: null,
  },
  newUser: {
    data: null,
    error: null,
    loading: false,
  },
  editUser: {
    data: null,
    loading: false,
    error: null,
  },
  editUserStatus: {
    data: null,
    loading: false,
    error: null,
  },
  allRoles: {
    data: [],
    loading: false,
    error: null,
  },
  allPermissions: {
    data: [],
    loading: false,
    error: null,
  },
  editRole: {
    data: null,
    error: null,
    loading: false,
  },
  supportedLanguages: {
    data: [],
    loading: false,
    error: null,
  },
  location: {},
  login: {
    loading: false,
    error: null,
    success: false,
  },
  language: { loading: false, error: null, data: {} },
  countries: {
    data: [],
    error: null,
    loading: false,
  },
  reset: {
    loading: false,
    error: null,
    success: false,
  },
  updateUserData: {
    data: null,
    error: null,
    loading: false,
    message: null,
  },
};

export default userInitialSate;
