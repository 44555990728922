import {
  GET_MY_VIRTUAL_CARD_START,
  GET_MY_VIRTUAL_CARD_SUCCESS,
  GET_MY_VIRTUAL_CARD_FAILURE,
} from '../../actionTypes/customers/getVirtualCards';

const getVirtualCardsReducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case GET_MY_VIRTUAL_CARD_START:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          virtualCardList: {
            ...state.selectedCustomer.virtualCardList,
            loading: true,
            error: null,
          },
        },
      };
    case GET_MY_VIRTUAL_CARD_SUCCESS:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          virtualCardList: {
            data: payload,
            loading: false,
            error: null,
          },
        },
      };
    case GET_MY_VIRTUAL_CARD_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          virtualCardList: {
            ...state.virtualCardList,
            loading: false,
            error: payload,
          },
        },
      };
  }
};

export default getVirtualCardsReducer;
