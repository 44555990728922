export const GET_DASHBOARD_DATA_START = 'GET_DASHBOARD_DATA_START';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILURE = 'GET_DASHBOARD_DATA_FAILURE';

export const GET_DASHBOARD_DATA_GRAPH_START = 'GET_DASHBOARD_DATA_GRAPH_START';
export const GET_DASHBOARD_DATA_GRAPH_SUCCESS = 'GET_DASHBOARD_DATA_GRAPH_SUCCESS';
export const GET_DASHBOARD_DATA_GRAPH_FAILURE = 'GET_DASHBOARD_DATA_GRAPH_FAILURE';

export const GET_ALL_DASHBOARD_DATA_START = 'GET_ALL_DASHBOARD_DATA_START';
export const GET_ALL_DASHBOARD_DATA_SUCCESS = 'GET_ALL_DASHBOARD_DATA_SUCCESS';
export const GET_ALL_DASHBOARD_DATA_FAILURE = 'GET_ALL_DASHBOARD_DATA_FAILURE';
export const GET_ALL_DASHBOARD_DATA_END = 'GET_ALL_DASHBOARD_DATA_END';

export const GET_ACCOUNT_BALANCE_START = 'GET_ACCOUNT_BALANCE_START';
export const GET_ACCOUNT_BALANCE_SUCCESS = 'GET_ACCOUNT_BALANCE_SUCCESS';
export const GET_ACCOUNT_BALANCE_FAILURE = 'GET_ACCOUNT_BALANCE_FAILURE';
