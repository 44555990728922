const cashpointsInitialState = {
  allCashPoints: {
    data: [],
    error: null,
    loading: false,
    metaData: {
      CurrentPage: "0",
      RecordsPerPage: "8",
      TotalPages: "0",
      TotalRecords: "0",
    },
  },
  searchCashpoints: {
    data: [],
    error: null,
    loading: false,
  },
  createCashpoint: {
    data: null,
    loading: false,
    error: null,
  },
  updateCashpoint: {
    data: null,
    error: null,
    loading: false,
  },
  initiateCashDeposit: {
    loading: false,
    data: null,
    error: null,
  },
  confirmCashDeposit: {
    loading: false,
    data: null,
    error: null,
  },
  pendingCashDeposits: {
    loading: false,
    data: [],
    error: null,
  },
  createTeller: {
    data: null,
    loading: false,
    error: null,
  },
  getTellers: {
    data: null,
    loading: false,
    error: null,
  },
  tellers: {
    data: [],
  },
  cashpoints: {
    data: [],
  },
  createCashPointOffice: {
    data: null,
    loading: false,
    error: null,
  },
};

export default cashpointsInitialState;
