
import { 
    BULK_ORGANIZATION_START, 
    BULK_ORGANIZATION_SUCCESS, 
    BULK_ORGANIZATION_FAILURE } 
from 'redux/actionTypes/organization';
import { OrganizationBulkListInterface } from 'types/organization';

  
  const bulkOrganizationListReducer = (
    state: OrganizationBulkListInterface,
    { type, payload }: any
  ) => {
    switch (type) {
      case BULK_ORGANIZATION_START:
        return {
          ...state,
          bulkOrganizationList: {
            data: [],
            loading: true,
            error: null,
          },
        };
      case BULK_ORGANIZATION_SUCCESS: {
        delete payload.OK;
        delete payload.Result;
        delete payload.DataFound;
        return {
          ...state,
          bulkOrganizationList: {
            data: payload,
            loading: false,
            error: null,
          },
        };
      }
      case BULK_ORGANIZATION_FAILURE:
        return {
          ...state,
          bulkOrganizationList: {
            ...state.data,
            error: payload,
            loading: false,
          },
        };
      default:
        return null;
    }
  };
  
  export default bulkOrganizationListReducer;
  