import {
  UPDATE_TRANSFER_FEES_START,
  UPDATE_TRANSFER_FEES_FAILURE,
  UPDATE_TRANSFER_FEES_SUCCESS,
} from 'redux/actionTypes/finances/partnerTypes';
import FinancesInitialStateInterface from 'types/finances/financialInitialState';

const allServicesReducer = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case UPDATE_TRANSFER_FEES_START:
      return {
        ...state,
        editService: {
          ...state.editService,
          loading: true,
          error: null,
        },
      };

    case UPDATE_TRANSFER_FEES_SUCCESS:
      const newServices = state.allServices.data.map((service) => {
        if (service.ServiceType === payload.ServiceType) {
          return { ...payload, Enabled: payload.Enable };
        }
        return service;
      });
      return {
        ...state,
        allServices: {
          ...state.allServices,
          data: newServices,
        },
        editService: {
          ...state.editService,
          loading: false,
          data: { ...payload, message: 'Partner created' },
          error: null,
        },
      };

    case UPDATE_TRANSFER_FEES_FAILURE:
      return {
        ...state,
        editService: {
          ...state.editService,
          loading: false,
          error: payload,
        },
      };

    default:
      return null;
  }
};

export default allServicesReducer;
