import {
  VERIFY_CUSTOMER_START,
  VERIFY_CUSTOMER_SUCCESS,
  VERIFY_CUSTOMER_FAILURE,
} from "redux/actionTypes/customers/verifyCustomerActionTypes";
import {
  SET_CUSTOMER_STATUS_START,
  SET_CUSTOMER_STATUS_SUCCESS,
  SET_CUSTOMER_STATUS_FAILURE,
  UNLOCK_CUSTOMER_START,
  UNLOCK_CUSTOMER_SUCCESS,
  UNLOCK_CUSTOMER_FAILURE,
  RESET_CUSTOMER_START,
  RESET_CUSTOMER_SUCCESS,
  RESET_CUSTOMER_FAILURE,
} from "redux/actionTypes/customers/customerStatusType";
import {
  GET_PROFESSION_LIST_FAILURE,
  GET_PROFESSION_LIST_START,
  GET_PROFESSION_LIST_SUCCESS,
} from "redux/actionTypes/customers/searchActionTypes";
import CustomersInitialStateInterface from "types/Customer/CustomerInitialState";

const customerReducer = (
  state: CustomersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case VERIFY_CUSTOMER_START:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          verifyCustomer: {
            error: null,
            loading: true,
          },
        },
      };

    case VERIFY_CUSTOMER_SUCCESS:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          data: {
            ...state.selectedCustomer.data,
            ...payload,
          },
          verifyCustomer: {
            error: null,
            loading: false,
          },
        },
      };

    case VERIFY_CUSTOMER_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          verifyCustomer: {
            error: payload,
            loading: false,
          },
        },
      };

    case RESET_CUSTOMER_START:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          resetAccountCredentials: {
            error: null,
            loading: true,
          },
        },
      };

    case RESET_CUSTOMER_SUCCESS:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          resetAccountCredentials: {
            error: null,
            loading: false,
          },
        },
      };

    case RESET_CUSTOMER_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          resetAccountCredentials: {
            error: payload,
            loading: false,
          },
        },
      };

    case SET_CUSTOMER_STATUS_START:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          setAccountStatus: {
            error: null,
            loading: true,
          },
        },
      };

    case SET_CUSTOMER_STATUS_SUCCESS: {
      const searchResults = state.search.results.Data.map((customer) => {
        if (customer.PID === payload[0].PID) {
          return {
            ...customer,
            AccountDisabled: payload[0].AccountDisabled,
          };
        }
        return customer;
      });

      if (!state.selectedCustomer.data) {
        return {
          ...state,
          search: {
            ...state.search,
            results: searchResults,
          },
          selectedCustomer: {
            ...state.selectedCustomer,
            setAccountStatus: {
              error: null,
              loading: false,
            },
          },
        };
      } else {
        return {
          ...state,
          search: {
            ...state.search,
            results: searchResults,
          },
          selectedCustomer: {
            ...state.selectedCustomer,
            data: {
              ...state.selectedCustomer.data,
              AccountDisabled:
                state.selectedCustomer.data.AccountDisabled.toLowerCase() ===
                "yes"
                  ? "NO"
                  : "YES",
            },
            setAccountStatus: {
              error: null,
              loading: false,
            },
          },
        };
      }
    }

    case SET_CUSTOMER_STATUS_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          setAccountStatus: {
            error: payload,
            loading: false,
          },
        },
      };

    case UNLOCK_CUSTOMER_START:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          setAccountStatus: {
            error: null,
            loading: true,
          },
        },
      };

    case UNLOCK_CUSTOMER_SUCCESS: {
      const searchResults = state.search.results.Data.map((customer) => {
        if (customer.PID === payload[0].PID) {
          return {
            ...customer,
            AccountLocked: "No",
          };
        }
        return customer;
      });

      if (!state.selectedCustomer.data) {
        return {
          ...state,
          search: {
            ...state.search,
            results: searchResults,
          },
          selectedCustomer: {
            ...state.selectedCustomer,
            setAccountStatus: {
              error: null,
              loading: false,
            },
          },
        };
      } else {
        return {
          ...state,
          search: {
            ...state.search,
            results: searchResults,
          },
          selectedCustomer: {
            ...state.selectedCustomer,
            data: {
              ...state.selectedCustomer.data,
              AccountLocked: "No",
            },
            setAccountStatus: {
              error: null,
              loading: false,
            },
          },
        };
      }
    }

    case UNLOCK_CUSTOMER_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          setAccountStatus: {
            error: payload,
            loading: false,
          },
        },
      };

    case GET_PROFESSION_LIST_START:
      return {
        ...state,
        professions: {
          error: null,
          loading: true,
          data: [],
        },
      };
    case GET_PROFESSION_LIST_SUCCESS:
      return {
        ...state,
        professions: {
          error: null,
          loading: false,
          data: payload,
        },
      };

    case GET_PROFESSION_LIST_FAILURE:
      return {
        ...state,
        professions: {
          error: payload,
          loading: false,
          data: [],
        },
      };

    default:
      return null;
  }
};

export default customerReducer;
