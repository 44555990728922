import {
  GET_ACCOUNT_BALANCE_FAILURE,
  GET_ACCOUNT_BALANCE_START,
  GET_ACCOUNT_BALANCE_SUCCESS,
} from 'redux/actionTypes/dashboard';
import DashboardInitialStateInterface from 'types/dashboard';

const accountBalanceReducer = (
  state: DashboardInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_ACCOUNT_BALANCE_START:
      return {
        ...state,
        accountsBalance: {
          data: { Transit: [], Trust: [], Earnings: [], CollectedTaxes: [] },
          loading: true,
          error: null,
        },
      };
    case GET_ACCOUNT_BALANCE_SUCCESS: {
      delete payload.OK;
      delete payload.Result;
      delete payload.DataFound;
      return {
        ...state,
        accountsBalance: {
          data: payload,
          loading: false,
          error: null,
        },
      };
    }
    case GET_ACCOUNT_BALANCE_FAILURE:
      return {
        ...state,
        accountsBalance: {
          ...state.accountsBalance,
          error: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};

export default accountBalanceReducer;
