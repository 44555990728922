import {
  GET_DASHBOARD_DATA_GRAPH_FAILURE,
  GET_DASHBOARD_DATA_GRAPH_START,
  GET_DASHBOARD_DATA_GRAPH_SUCCESS,
  GET_ALL_DASHBOARD_DATA_END,
  GET_ALL_DASHBOARD_DATA_START,
  GET_ALL_DASHBOARD_DATA_SUCCESS,
} from 'redux/actionTypes/dashboard';
import DashboardInitialStateInterface from 'types/dashboard';

const dashboardKPIGraphDataReducer = (
  state: DashboardInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_ALL_DASHBOARD_DATA_START:
      return { ...state, loadingRealTimeData: true };

    case GET_ALL_DASHBOARD_DATA_SUCCESS:
      return { ...state, loadingRealTimeData: false };

    case GET_ALL_DASHBOARD_DATA_END:
      return { ...state, loadingRealTimeData: false };

    case GET_DASHBOARD_DATA_GRAPH_START:
      return {
        ...state,
        GraphData: {
          ...state.GraphData,
          loading: true,
          error: null,
        },
      };
    case GET_DASHBOARD_DATA_GRAPH_SUCCESS:
      delete payload.OK;
      delete payload.Result;
      delete payload.DataFound;
      return {
        ...state,
        GraphData: { data: payload.data, loading: false, error: null },
        [payload.graphType]: { data: payload.data, loading: false, error: null }
      };

    case GET_DASHBOARD_DATA_GRAPH_FAILURE:
      return {
        ...state,
        GraphData: {
          ...state.GraphData,
          error: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};

export default dashboardKPIGraphDataReducer;
