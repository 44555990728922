import {
  GET_PARTNER_LIST_START,
  GET_PARTNER_LIST_SUCCESS,
  GET_PARTNER_LIST_FAILURE,
} from 'redux/actionTypes/finances/partnerTypes';
import FinancesInitialStateInterface from 'types/finances/financialInitialState';

const allPartnersReducer = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_PARTNER_LIST_START:
      return {
        ...state,
        partners: {
          ...state.partners,
          loading: true,
          error: null,
        },
      };

    case GET_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        partners: {
          ...state.partners,
          loading: false,
          success: true,
          error: null,
          data: payload?.Data,
        },
      };

    case GET_PARTNER_LIST_FAILURE:
      return {
        ...state,
        partners: {
          ...state.partners,
          loading: false,
          error: payload,
          success: false,
        },
      };

    default:
      return null;
  }
};

export default allPartnersReducer;
