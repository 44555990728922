import {
  GET_MERCHANTS_START,
  GET_MERCHANTS_SUCCESS,
  GET_MERCHANTS_FAILURE,
} from "redux/actionTypes/merchant";
import { MerchantListInterface } from "types/merchants";

const getMerchants = (state: MerchantListInterface, { type, payload }: any) => {
  switch (type) {
    case GET_MERCHANTS_START:
      return {
        ...state,
        merchantList: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case GET_MERCHANTS_SUCCESS: {
      return {
        ...state,
        merchantList: {
          data: payload,
          loading: false,
          error: null,
        },
      };
    }
    case GET_MERCHANTS_FAILURE:
      return {
        ...state,
        merchantList: {
          error: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};

export default getMerchants;
