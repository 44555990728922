import { SET_LANGUAGE } from "redux/actionTypes/users/language";
import {
  PREFERRED_LANGUAGE,
  APP_NAME,
  DEFAULT_LANGUAGE_CODE,
} from "constants/general";
import getLanguageAction from "./getLanguage";
import { SET_APP_NAME } from "redux/actionTypes/app";

export const InitializeAppAction =
  () => (dispatch: React.Dispatch<Record<string, unknown>>) => {
    /**
     * check loaded app name, if changed, force language update
     * to avoid hard coded app name in translations
     * TODO: review this block
     *  */
    if (
      localStorage.getItem(APP_NAME) &&
      localStorage.getItem(APP_NAME) !== process.env.REACT_APP_NAME!
    ) {
      getLanguageAction(DEFAULT_LANGUAGE_CODE, true)(dispatch);
    }
    localStorage.setItem(APP_NAME, process.env.REACT_APP_NAME!);
    dispatch({
      type: SET_APP_NAME,
      payload: process.env.REACT_APP_NAME!.toLowerCase().replace(/\s/, "-"),
    });

    /**
     * Define user's language. Different browsers have the user locale defined
     * on different fields on the `navigator` object, so we make sure to account
     * for these different by checking all of them
     *  */

    // set preferred language
    const language =
      (navigator.languages && navigator.languages[0]) || navigator.language;

    // Split locales with a region code
    const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

    const preferredLanguage = localStorage.getItem(PREFERRED_LANGUAGE);

    if (!preferredLanguage) {
      localStorage.setItem(
        PREFERRED_LANGUAGE,
        JSON.stringify({ language: "", code: languageWithoutRegionCode })
      );
      return dispatch({
        type: SET_LANGUAGE,
        payload: { language: "", code: languageWithoutRegionCode },
      });
    }
    try {
      const lang: { language: string; code: string } =
        JSON.parse(preferredLanguage);

      return dispatch({
        type: SET_LANGUAGE,
        payload: { language: lang.language, code: lang.code },
      });
    } catch (error) {
      // when the PREFERRED_LANGUAGE in the localStorage is corrupted
      localStorage.setItem(
        PREFERRED_LANGUAGE,
        JSON.stringify({ language: "", code: languageWithoutRegionCode })
      );
      return dispatch({
        type: SET_LANGUAGE,
        payload: { language: "", code: languageWithoutRegionCode },
      });
    }
  };
