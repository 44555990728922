export const CashPickup = "CP"; 
export const CancelTrans = "CC"; 
export const WalletTopUp = "CA";
export const SendCash = "CS"; 
export const Redeem = "CR"; 
export const WalletToWallet = "WW";
export const General = "GT";
export const FromPartner = "FP"; 
export const ToPartner = "TP"; 
export const CashDeposit = "CD";
export const MicroLoan = "ML";
export const BankToWallet = "BW";
export const WalletToBank = "WB"; 
export const ATM = "TM"; 
export const PhoneTopUp = "PT"; 
export const Payment = "MP"; 
export const InvoicePostPay = "IP"; 
export const InvoicePrePay = "PI"; 
export const ToPrepaidCard = "PC"; 
export const ToMNO = "TO"; 
export const BulkPay = "BP"; 