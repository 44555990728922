export const UPDATE_ACCOUNT_START = "UPDATE_ACCOUNT_START";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_SUCCESS";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const SET_USER_STATUS_START = "SET_USER_STATUS_START";
export const SET_USER_STATUS_SUCCESS = "SET_USER_STATUS_SUCCESS";
export const SET_USER_STATUS_FAILURE = "SET_USER_STATUS_FAILURE";
