import {
  UPDATE_EVENT_PICTURE_START,
  UPDATE_EVENT_PICTURE_SUCCESS,
  UPDATE_EVENT_PICTURE_FAILURE,
} from 'redux/actionTypes/events/updateEventPicture';
import { EventsInitialStateInterface } from 'types/initialState';

const updateEventPicture = (
  state: EventsInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case UPDATE_EVENT_PICTURE_START:
      return {
        ...state,
        updateImage: {
          loading: true,
          error: null,
          message: null,
        },
      };
    case UPDATE_EVENT_PICTURE_SUCCESS:
      return {
        ...state,
        updateImage: {
          event: payload,
          error: null,
          loading: false,
          message: 'Image updated successfully',
        },
      };
    case UPDATE_EVENT_PICTURE_FAILURE:
      return {
        ...state,
        allEvents: {
          ...state.allEvents,
        },
        updateImage: {
          error: payload,
          loading: false,
          message: null,
        },
      };
  }
};

export default updateEventPicture;
