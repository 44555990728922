const financialInitialState = {
  currencyList: {
    data: [],
    error: null,
    loading: false,
  },
  setBaseCurrencies: {
    data: null,
    error: null,
    loading: false,
  },
  setCrowdfundingFeeRate: {
    data: null,
    error: null,
    loading: false,
  },
  getCrowdfundingFeeRate: {
    data: null,
    error: null,
    loading: false,
  },
  globalFinances: {
    data: null,
    error: null,
    loading: false,
  },
  updateParamsFinanceConfig: {
    data: null,
    error: null,
    loading: false,
  },
  allServices: {
    data: [],
    loading: false,
    error: null,
  },
  allPartners: {
    data: [],
    loading: false,
    error: null,
  },

  editService: {
    data: null,
    loading: false,
    error: null,
  },
  partners: {
    data: [],
    loading: false,
    error: null,
    success: false,
  },
  updatePartner: {
    data: null,
    loading: false,
    error: null,
    success: false,
  },
  registerAsTwoWayPartner: {
    data: null,
    loading: false,
    error: null,
    success: false,
  },
  newPartner: {
    data: null,
    loading: false,
    error: null,
    success: false,
  },
  activateOrDeletePartner: {
    data: null,
    loading: false,
    error: null,
    success: false,
    action: "",
  },
  updatePartnerBalance: {
    data: null,
    loading: false,
    error: null,
    success: false,
    operation: "",
  },
  updateOssixBalance: {
    data: null,
    loading: false,
    error: null,
    success: false,
    operation: "",
  },
  microLoans: {
    creditScores: {
      data: [
        { Name: "Bad", Range: "350-549" },
        { Name: "Fair", Range: "550-649" },
        { Name: "Good", Range: "650-699" },
        { Name: "Very Good", Range: "700-749" },
        { Name: "Excellent", Range: "750-850" },
      ],
      loading: false,
      error: null,
    },
    allRanges: {
      data: [],
      error: null,
      loading: false,
    },
    editRange: {
      data: [],
      error: null,
      loading: false,
    },
  },
  bankBranchesList: {
    data: [],
    error: null,
    loading: false,
    success: false,
  },
};

export default financialInitialState;
