import { useIntl } from "react-intl";

export const useTransactionTypes = () => {
  const intl = useIntl();

  const transactionTypes = new Map([
    [
      "CC",
      {
        value: intl.formatMessage({
          id: "86",
          defaultMessage: "Canceled",
        }),
        color: "red",
      },
    ],
    [
      "CA",
      {
        value: intl.formatMessage({
          id: "542",
          defaultMessage: "Wallet Top Up",
        }),
        color: "green",
      },
    ],
    [
      "CP",
      {
        value: intl.formatMessage({
          id: "1110",
          defaultMessage: "Cash pick up",
        }),
        color: "green",
      },
    ],
    [
      "CS",
      {
        value: intl.formatMessage({ id: "915", defaultMessage: "Send cash" }),
        color: "magenta",
      },
    ],
    [
      "CR",
      {
        value: intl.formatMessage({ id: "809", defaultMessage: "Redeem" }),
        color: "orange",
      },
    ],
    [
      "FP",
      {
        value: intl.formatMessage({ id: "3319", defaultMessage: "Partner" }),
        color: "geekblue",
      },
    ],
    [
      "CD",
      {
        value: intl.formatMessage({ id: "3474", defaultMessage: "Deposit" }),
        color: "cyan",
      },
    ],
    [
      "WW",
      {
        value: intl.formatMessage({
          id: "dd.new",
          defaultMessage: "Wallet to Wallet",
        }),
        color: "cyan",
      },
    ],
    [
      "ST",
      {
        value: intl.formatMessage({
          id: "dd.new",
          defaultMessage: "Accounts settlement",
        }),
        color: "cyan",
      },
    ],
  ]);
  return { transactionTypes };
};

export interface TransactionsFilters {
  PID?: string;
  DateTo?: string;
  DateFrom?: string;
  TransactionType?: string[];
  Membership?: string[];
  PageNumber?: string;
  RecordPerPage?: string;
  WalletNumber?: string;
  CountryCode?: string;
  Currency?: string;
  Proxy?: "YES" | "NO";
}
