export const GET_SUPPORTED_LANGUAGE_START = 'GET_SUPPORTED_LANGUAGE_START';
export const GET_SUPPORTED_LANGUAGE_SUCCESS = 'GET_SUPPORTED_LANGUAGE_SUCCESS';
export const GET_SUPPORTED_LANGUAGE_FAILURE = 'GET_SUPPORTED_LANGUAGE_FAILURE';
export const GET_SUPPORTED_LANGUAGE_END = 'GET_SUPPORTED_LANGUAGE_END';

export const GET_LANGUAGE_START = 'GET_LANGUAGE_START';
export const GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS';
export const GET_LANGUAGE_FAILURE = 'GET_LANGUAGE_FAILURE';
export const GET_LANGUAGE_END = 'GET_LANGUAGE_END';

export const SET_LANGUAGE = 'SET_LANGUAGE';
