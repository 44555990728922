import {
  GET_LANGUAGE_START,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_FAILURE,
  GET_LANGUAGE_END,
} from "redux/actionTypes/users/language";
import React from "react";
import apiAction from "utils/apiAction";
import { DEFAULT_LANGUAGE_CODE } from "constants/general";
import formatLanguageMessages from "utils/formatLanguageMessages";

function getLanguageAction(langCode?: string, versionUpdate = false) {
  return (dispatchAction: React.Dispatch<Record<string, unknown>>): void => {
    const language = (langCode || "").trim();

    if (!versionUpdate && (!language || language === DEFAULT_LANGUAGE_CODE)) {
      // user selected the default language, rest the (state) language object
      dispatchAction({
        type: GET_LANGUAGE_SUCCESS,
        payload: { data: {} },
      });
      return;
    }

    // handle language change/selection
    if (!versionUpdate && localStorage[language]) {
      dispatchAction({
        type: GET_LANGUAGE_SUCCESS,
        payload: { data: JSON.parse(localStorage[language]) },
      });
      return;
    }

    return dispatchAction(
      apiAction({
        method: "post",
        url: "/LoadLanguage",
        data: { Language: langCode },
        onStart: () => (dispatch) =>
          dispatch({
            type: GET_LANGUAGE_START,
          }),
        onSuccess: (data) => (dispatch) => {
          const formattedData = formatLanguageMessages(data);
          dispatch({
            type: GET_LANGUAGE_SUCCESS,
            payload: { data: formattedData },
          });
          if (Array.isArray(data) && data.length) {
            localStorage[langCode!] = JSON.stringify(formattedData);
          }
        },
        onEnd: () => (dispatch) => {
          return dispatch({ type: GET_LANGUAGE_END });
        },
        onFailure: (error) => (dispatch) => {
          return dispatch({
            type: GET_LANGUAGE_FAILURE,
            payload: error,
          });
        },
      })
    );
  };
}

export default getLanguageAction;
