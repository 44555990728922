import { kpiGraphTabs } from "constants/kpiGraphs";
import {
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_START,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_ACCOUNT_BALANCE_START,
  GET_ACCOUNT_BALANCE_FAILURE,
  GET_ACCOUNT_BALANCE_SUCCESS,
  GET_ALL_DASHBOARD_DATA_START,
  GET_ALL_DASHBOARD_DATA_SUCCESS,
  GET_ALL_DASHBOARD_DATA_FAILURE,
  GET_ALL_DASHBOARD_DATA_END,
  GET_DASHBOARD_DATA_GRAPH_START,
  GET_DASHBOARD_DATA_GRAPH_SUCCESS,
  GET_DASHBOARD_DATA_GRAPH_FAILURE
} from "redux/actionTypes/dashboard";
import apiAction from "utils/apiAction";
import concurrentAPIAction from "utils/concurrentAPIAction";

export const getDashboardKPIDataAction = (CountryCode: string) => {
  return (dispatchAction: any) =>
    dispatchAction(
      apiAction({
        method: "POST",
        url: "/BOGetGlobalKPISet",
        data: { CountryCode },
        onStart: () => (dispatch) => {
          dispatch({
            type: GET_DASHBOARD_DATA_START,
          });
        },
        onSuccess: (data) => (dispatch) => {
          dispatch({
            type: GET_DASHBOARD_DATA_SUCCESS,
            payload: data[0],
          });
        },
        onFailure: (error) => (dispatch) => {
          dispatch({
            type: GET_DASHBOARD_DATA_FAILURE,
            payload: error,
          });
        },
      })
    );
};

export const getDashboardKPIGraphDataAction = (GraphData: Record<string, string>) => {
  return (dispatchAction: any) =>
    dispatchAction(
      apiAction({
        method: "POST",
        url: "/BOGetGlobalKPIGraphData",
        data: GraphData,
        onStart: () => (dispatch) => {
          dispatch({
            type: GET_DASHBOARD_DATA_GRAPH_START,
          });
        },
        onSuccess: (data) => (dispatch) => {
          dispatch({
            type: GET_DASHBOARD_DATA_GRAPH_SUCCESS,
            payload: { data: data[0], graphType: kpiGraphTabs[GraphData.graphKey] },
          });
        },
        onFailure: (error) => (dispatch) => {
          dispatch({
            type: GET_DASHBOARD_DATA_GRAPH_FAILURE,
            payload: error,
          });
        },
      })
    );
};

export const getAccountBalancesAction = (CountryCode: string) => {
  return (dispatchAction: any) =>
    dispatchAction(
      apiAction({
        method: "POST",
        url: "/BOGetAccountsData",
        data: { CountryCode },
        onStart: () => (dispatch) => {
          dispatch({
            type: GET_ACCOUNT_BALANCE_START,
          });
        },
        onSuccess: (data) => (dispatch) => {
          dispatch({
            type: GET_ACCOUNT_BALANCE_SUCCESS,
            payload: data[0],
          });
        },
        onFailure: (error) => (dispatch) => {
          dispatch({
            type: GET_ACCOUNT_BALANCE_FAILURE,
            payload: error,
          });
        },
      })
    );
};

export const loadAllDashboardDataAction = () => {
  return (dispatchAction: React.Dispatch<any>) =>
    dispatchAction(
      concurrentAPIAction({
        requests: [
          apiAction({
            method: "POST",
            url: "/BOGetGlobalKPISet",
            onSuccess: (data) => (dispatch) => {
              dispatch({
                type: GET_DASHBOARD_DATA_SUCCESS,
                payload: data[0],
              });
            },
            onFailure: (error) => (dispatch) => {
              dispatch({
                type: GET_DASHBOARD_DATA_FAILURE,
                payload: error,
              });
            },
          }),
          apiAction({
            method: "POST",
            url: "/BOGetAccountsData",
            onSuccess: (data) => (dispatch) => {
              dispatch({
                type: GET_ACCOUNT_BALANCE_SUCCESS,
                payload: data[0],
              });
            },
            onFailure: (error) => (dispatch) => {
              dispatch({
                type: GET_ACCOUNT_BALANCE_FAILURE,
                payload: error,
              });
            },
          }),
        ],
        onStart: () => {
          return (dispatch) => {
            dispatch({ type: GET_ALL_DASHBOARD_DATA_START });
          };
        },
        onSuccess: () => {
          return (dispatch) => {
            dispatch({ type: GET_ALL_DASHBOARD_DATA_SUCCESS });
          };
        },
        onFailure: () => {
          return (dispatch) => {
            dispatch({ type: GET_ALL_DASHBOARD_DATA_FAILURE });
          };
        },
        onEnd: () => {
          return (dispatch) => {
            dispatch({ type: GET_ALL_DASHBOARD_DATA_END });
          };
        },
      })
    );
};
