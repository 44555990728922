import {
  Col,
  Tag,
  Row,
  Menu,
  Spin,
  Space,
  Image,
  Button,
  Dropdown,
  Typography,
} from "antd";
import zipcelx from "zipcelx";
import { useIntl } from "react-intl";
import { getMonthName } from "utils/dates";
import GraphBarComponent from "./GraphBar";
import GraphLineComponent from "./GraphLine";
import { useHistory } from "react-router-dom";
import PageHOCComponent from "components/PageHOC";
import React, { useEffect, useState } from "react";
import BKIconImage from "assets/images/bk-logo.png";
import UBAIconImage from "assets/images/uba-logo.png";
import InitialStateInterface from "types/initialState";
import { PermissionNames } from "constants/enumerators";
import FormattedNumber from "components/FormattedNumber";
import Icon, { LoadingOutlined } from "@ant-design/icons";
import PageAccessControl from "components/PageAccessControl";
import GrowthArrowIconSVG from "assets/icons/growth-arrow.svg";
import { getDashboardKPIDataAction } from "redux/actions/dashboard";
import StudentIconImage from "assets/images/clip-girl-studying.png";
import { ReactComponent as CubesIconSVG } from "assets/icons/cubes.svg";
import AcceptedHereSignImage from "assets/images/accepted-here-sign.svg";
import AcceptedHereSignImage2u from "assets/images/accepted-here-sign-2u.svg";
import StandardBankIconImage from "assets/images/standard-bank-logo.png";
import { ReactComponent as SalaryIconSVG } from "assets/icons/salary.svg";
import { ReactComponent as GenderIconSVG } from "assets/icons/gender.svg";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ReactComponent as DownloadIconSVG } from "assets/icons/download.svg";
import { ReactComponent as AgeGroupsIconSVG } from "assets/icons/age-group.svg";
import { ReactComponent as XLSFileIconSVG } from "assets/icons/xls-file-icon.svg";
import { ReactComponent as PDFFileIconSVG } from "assets/icons/pdf-file-icon.svg";
import { ReactComponent as StoreFrontIconSVG } from "assets/icons/store-front.svg";
import { ReactComponent as UnemploymentIconSVG } from "assets/icons/unemployment.svg";
import { getTransactionSummaryDataAction } from "redux/actions/transactionReports/index";

import "./index.scss";

const { REACT_APP_NAME } = process.env;

const { Text } = Typography;
const typedUseSelector: TypedUseSelectorHook<InitialStateInterface> =
  useSelector;

const TransactionsSummary: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [userGraphData, setUserGraphData] = useState<Array<any>>([]);
  const [merchantsGraphData, setMerchantsGraphData] = useState<Array<any>>([]);
  const [cashpointsGraphData, setCashpointsGraphData] = useState<Array<any>>(
    []
  );

  const {
    users: {
      currentUser: { selectedOpsCountry },
    },
    dashboard: {
      KPI: {
        data: { GraphData, Customers, Transactions, TransVolume },
        loading,
      },
    },
    transactionsReport: {
      transactionsSummary: {
        loading: loadingSummary,
        data: transactionsSummaryData,
      },
    },
  } = typedUseSelector(({ users, dashboard, transactionsReport }) => ({
    users,
    dashboard,
    transactionsReport,
  }));

  // download as xlsx sheet configurations
  const zipcelxConfig = () => {
    const footNotes = [
      [
        {
          value:
            "1. Active are accounts with at least one transaction during the last 30 days",
          type: "string",
        },
      ],
    ];

    const storeColumns = transactionsSummaryData?.Store?.Categories.map(
      (category: any) => [
        {
          value: `Number of ${category.CatName} stores accepting M2U`,
          type: "string",
        },
        {
          value: Number(category.CatCount || 0),
          type: "number",
        },
      ]
    );

    return {
      filename: `transactions-report-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}`,
      sheet: {
        data: [
          [
            { value: "Customer demographics", type: "string" },
            { value: "", type: "string" },
          ],
          [
            { value: "", type: "string" },
            { value: "Current value", type: "string" },
            ...(GraphData.length
              ? GraphData.map((data) => ({
                  value: `${getMonthName(Number(data.Month))}-${data.Year}`,
                  type: "string",
                }))
              : []),
          ],
          [
            { value: "Total users", type: "string" },
            {
              value: Number(
                transactionsSummaryData?.UsersCount?.TotalUsers || 0
              ),
              type: "number",
            },
            ...(GraphData.length
              ? GraphData.map((data) => ({
                  value: Number(data.RegistrationCount || 0),
                  type: "number",
                }))
              : []),
          ],
          [
            { value: "Number of active users (*1)", type: "string" },
            {
              value: Number(Customers?.ActiveCustomers || 0),
              // Number(Customers?.PercentActive || 0) / 100,
              type: "number",
            },
          ],
          [
            { value: "Number of male users", type: "string" },
            {
              value: Number(
                transactionsSummaryData?.UsersCount?.GenderMale || 0
              ),
              type: "number",
            },
          ],
          [
            { value: "Number of female users", type: "string" },
            {
              value: Number(
                transactionsSummaryData?.UsersCount?.GenderFemale || 0
              ),
              type: "number",
            },
          ],
          [
            { value: "Number of users who are students", type: "string" },
            {
              value: Number(
                transactionsSummaryData?.UsersCount?.StudentCount || 0
              ),
              type: "number",
            },
          ],
          [
            { value: "Number of users with bank accounts", type: "string" },
            {
              value: Number(
                transactionsSummaryData?.UsersCount?.BankCount || 0
              ),
              type: "number",
            },
          ],
          [
            { value: "Number of users without bank accounts", type: "string" },
            {
              value: Number(
                transactionsSummaryData?.UsersCount?.NoBankCount || 0
              ),
              type: "number",
            },
          ],
          [
            {
              value: "Number of users without specified bank details",
              type: "string",
            },
            {
              value: Number(
                transactionsSummaryData?.UsersCount?.BankNotSpecifiedCount || 0
              ),
              type: "number",
            },
          ],
          [],
          [
            {
              value: "Number of merchants accepting payments with M2U",
              type: "string",
            },
            {
              value: Number(transactionsSummaryData?.Store?.TotalStore || 0),
              type: "number",
            },
            ...(transactionsSummaryData?.StoreGraph?.length
              ? transactionsSummaryData?.StoreGraph?.map((data: any) => ({
                  value: Number(data.TotalStore || 0),
                  type: "number",
                }))
              : []),
          ],
          [
            { value: "Number of M2U Cashpoints", type: "string" },
            {
              value: Number(
                transactionsSummaryData?.CashPoints?.TotalCashPoints || 0
              ),
              type: "number",
            },
            ...(transactionsSummaryData?.CashPointGraph?.length
              ? transactionsSummaryData?.CashPointGraph?.map((data: any) => ({
                  value: Number(data.TotalCP || 0),
                  type: "number",
                }))
              : []),
          ],
          ...storeColumns,
          [],
          [
            { value: "Total transactions number", type: "string" },
            {
              value: Number(Transactions?.TotalTransactionsCount || 0),
              type: "number",
            },
            ...(GraphData.length
              ? GraphData.map((data) => ({
                  value: Number(data.TransactionCount || 0),
                  type: "number",
                }))
              : []),
          ],
          [
            { value: "Total transactions volume", type: "string" },
            {
              value: Number(TransVolume?.TotalTransationsVolume || 0),
              type: "number",
            },
          ],
          [],
          ...footNotes,
        ],
      },
      sheet1: { data: [[{ value: "Transactions", type: "string" }]] },
    };
  };

  useEffect(() => {
    if (selectedOpsCountry) {
      getDashboardKPIDataAction(selectedOpsCountry)(dispatch);
      getTransactionSummaryDataAction(selectedOpsCountry)(dispatch);
    }
  }, [dispatch, selectedOpsCountry]);

  useEffect(() => {
    if (!loading && GraphData) {
      const userData = GraphData.map(({ Month, RegistrationCount }) => ({
        x: getMonthName(+Month),
        y: +RegistrationCount,
      }));

      setUserGraphData(userData);
    }
  }, [GraphData, loading]);

  useEffect(() => {
    if (!loadingSummary && transactionsSummaryData.StoreGraph) {
      const merchantsGraphData = transactionsSummaryData.StoreGraph?.map(
        ({ Month, TotalStore }: { [key: string]: string }) => ({
          x: getMonthName(+Month),
          y: +TotalStore,
        })
      );
      setMerchantsGraphData(merchantsGraphData);
    }

    if (!loadingSummary && transactionsSummaryData.CashPointGraph) {
      const cashpointsGraphData = transactionsSummaryData.CashPointGraph?.map(
        ({ Month, TotalCP }: { [key: string]: string }) => ({
          x: getMonthName(+Month),
          y: +TotalCP,
        })
      );
      setCashpointsGraphData(cashpointsGraphData);
    }
  }, [transactionsSummaryData, loadingSummary]);

  const downloadMenu = (
    <Menu>
      <Menu.Item
        disabled={loadingSummary}
        icon={<Icon component={XLSFileIconSVG} />}
        onClick={() => {
          zipcelx(zipcelxConfig());
        }}
      >
        {intl.formatMessage({
          id: "2852",
          defaultMessage: "XLSX sheet",
        })}
      </Menu.Item>
      <Menu.Item disabled icon={<Icon component={PDFFileIconSVG} />} hidden>
        {intl.formatMessage({
          id: "2853",
          defaultMessage: "PDF document",
        })}
      </Menu.Item>
    </Menu>
  );

  return (
    <PageHOCComponent
      onBack={history.goBack}
      pageClassName="transactions-summary__wrapper"
      title={intl.formatMessage({
        id: "2777",
        defaultMessage: "Comprehensive report",
      })}
      displayTitle={intl.formatMessage({
        id: "2777",
        defaultMessage: "Comprehensive report",
      })}
    >
      <PageAccessControl permID={PermissionNames.REPORTING}>
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          tip={`${intl.formatMessage({
            id: "194",
            defaultMessage: "Loading",
          })}...`}
        >
          <div className="transactions-summary__wrapper__heading">
            <Dropdown overlay={downloadMenu} trigger={["click"]}>
              <Button icon={<Icon component={DownloadIconSVG} />}>
                {intl.formatMessage({
                  id: "1465",
                  defaultMessage: "Download",
                })}
              </Button>
            </Dropdown>
          </div>
          <div className="transactions-summary">
            <Row style={{ marginTop: 56 }}>
              <Col span={10}>
                <Space direction="vertical">
                  <Text className="transactions-summary__heading">
                    {intl.formatMessage({
                      id: "2854",
                      defaultMessage: "Customer Demographics",
                    })}
                  </Text>
                  <Text className="transactions-summary__description">
                    {intl.formatMessage({
                      id: "2855",
                      defaultMessage: `Add context and color to our customers.
                Demographics help us understand who our customers are and to offer personalized and relevant offers`,
                    })}
                  </Text>
                </Space>
              </Col>

              <Col
                span={14}
                className="transactions-summary__p1"
                style={{ backgroundImage: `url(${GrowthArrowIconSVG})` }}
              >
                <Space direction="vertical">
                  <div className="transactions-summary__p1__text">
                    <Text>
                      {intl.formatMessage({
                        id: "2855",
                        defaultMessage: `Our customer base has increased by`,
                      })}
                    </Text>{" "}
                    <Text className="transactions-summary__p1__text-bold">
                      <FormattedNumber
                        style="percent"
                        value={Number(Customers?.GrowthThisMonth || 0) / 100}
                      />{" "}
                      {intl.formatMessage({
                        id: "2857",
                        defaultMessage: "this month",
                      })}
                    </Text>
                  </div>
                  <Text className="transactions-summary__p1__text">
                    {intl.formatMessage({
                      id: "1453",
                      defaultMessage: "Today",
                    })}
                    {", M2U Money "}
                    {intl.formatMessage({
                      id: "2858",
                      defaultMessage: "platform has",
                    })}
                  </Text>
                  <Text className="transactions-summary__p1__text-large">
                    <FormattedNumber
                      value={Number(
                        transactionsSummaryData?.UsersCount?.TotalUsers | 0
                      )}
                    />{" "}
                    <span>
                      {intl.formatMessage({
                        id: "2559",
                        defaultMessage: "Accounts",
                      })}
                    </span>
                  </Text>
                  <div className="transactions-summary__p1__text">
                    <Text className="transactions-summary__p1__text-bold">
                      <FormattedNumber
                        style="percent"
                        value={Number(Customers?.PercentActive || 0) / 100}
                      />
                      {"("}
                      <FormattedNumber
                        value={Number(Customers?.ActiveCustomers || 0)}
                      />
                      {")"}
                    </Text>
                    <Text>
                      {intl.formatMessage({
                        id: "2860",
                        defaultMessage: `are active accounts with a recorded transaction during the last 3 months`,
                      })}
                    </Text>{" "}
                  </div>
                </Space>
              </Col>
            </Row>

            <Row style={{ marginTop: 16 }}>
              <Col span={12} className="transactions-summary__p2">
                <Row>
                  <Col span={8} className="transactions-summary__p2__s1">
                    <Space direction="vertical">
                      <Icon
                        component={GenderIconSVG}
                        style={{ fontSize: 32 }}
                      />
                      <Text>
                        {intl.formatMessage({
                          id: "1346",
                          defaultMessage: "Gender",
                        })}
                      </Text>
                    </Space>
                  </Col>
                  <Col span={14} className="transactions-summary__p2__s2">
                    <Space direction="vertical">
                      <GraphBarComponent
                        label={intl.formatMessage({
                          id: "1344",
                          defaultMessage: "Male",
                        })}
                        value={Number(
                          transactionsSummaryData?.UsersCount?.GenderMale | 0
                        )}
                        percent={
                          Number(
                            transactionsSummaryData?.UsersCount?.GenderMale
                          ) /
                            Number(
                              transactionsSummaryData?.UsersCount?.TotalUsers
                            ) || 0
                        }
                      />
                      <GraphBarComponent
                        label={intl.formatMessage({
                          id: "1343",
                          defaultMessage: "Female",
                        })}
                        value={Number(
                          transactionsSummaryData?.UsersCount?.GenderFemale | 0
                        )}
                        percent={
                          Number(
                            transactionsSummaryData?.UsersCount?.GenderFemale
                          ) /
                            Number(
                              transactionsSummaryData?.UsersCount?.TotalUsers
                            ) || 0
                        }
                      />
                      <GraphBarComponent
                        label={intl.formatMessage({
                          id: "608",
                          defaultMessage: "Others",
                        })}
                        value={Number(
                          transactionsSummaryData?.UsersCount
                            ?.GenderNotSpecified | 0
                        )}
                        percent={
                          Number(
                            transactionsSummaryData?.UsersCount
                              ?.GenderNotSpecified
                          ) /
                            Number(
                              transactionsSummaryData?.UsersCount?.TotalUsers
                            ) || 0
                        }
                      />
                    </Space>
                  </Col>
                </Row>
              </Col>

              <Col span={12} className="transactions-summary__p3">
                <Row>
                  <Col span={8} className="transactions-summary__p3__s1">
                    <Space direction="vertical">
                      <Icon
                        component={AgeGroupsIconSVG}
                        style={{ fontSize: 32 }}
                      />
                      <Text>
                        {intl.formatMessage({
                          id: "2861",
                          defaultMessage: "Age groups",
                        })}
                      </Text>
                    </Space>
                  </Col>

                  <Col span={14} className="transactions-summary__p3__s2">
                    <Space direction="horizontal" size={24}>
                      <GraphBarComponent
                        label="21 yrs"
                        direction="horizontal"
                        value={Number(
                          transactionsSummaryData?.AgeRange?.Less21 | 0
                        )}
                        percent={
                          Number(transactionsSummaryData?.AgeRange?.Less21) /
                            Number(
                              transactionsSummaryData?.UsersCount?.TotalUsers
                            ) || 0
                        }
                      />
                      <GraphBarComponent
                        label="21 - 35 yrs"
                        direction="horizontal"
                        value={Number(
                          transactionsSummaryData?.AgeRange?.Between2135 | 0
                        )}
                        percent={
                          Number(
                            transactionsSummaryData?.AgeRange?.Between2135
                          ) /
                            Number(
                              transactionsSummaryData?.UsersCount?.TotalUsers
                            ) || 0
                        }
                      />
                      <GraphBarComponent
                        label="35 - 60 yrs"
                        direction="horizontal"
                        value={Number(
                          transactionsSummaryData?.AgeRange?.Between3560 | 0
                        )}
                        percent={
                          Number(
                            transactionsSummaryData?.AgeRange?.Between3560
                          ) /
                            Number(
                              transactionsSummaryData?.UsersCount?.TotalUsers
                            ) || 0
                        }
                      />
                      <GraphBarComponent
                        label="60 yrs"
                        direction="horizontal"
                        value={Number(
                          transactionsSummaryData?.AgeRange?.Over60 | 0
                        )}
                        percent={
                          Number(transactionsSummaryData?.AgeRange?.Over60) /
                            Number(
                              transactionsSummaryData?.UsersCount?.TotalUsers
                            ) || 0
                        }
                      />
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 16 }}>
              <Col span={24} className="transactions-summary__p4">
                <Row>
                  <Col span={8} className="transactions-summary__p4__s1">
                    <Space direction="vertical">
                      <Icon component={CubesIconSVG} style={{ fontSize: 32 }} />
                      <Text>
                        {intl.formatMessage({
                          id: "2862",
                          defaultMessage: "More categories",
                        })}
                      </Text>
                    </Space>
                  </Col>

                  <Col span={8}>
                    <Space
                      size={16}
                      align="start"
                      className="transactions-summary__p4__s2"
                    >
                      <Text className="transactions-summary__p4__s2__text1">
                        <FormattedNumber
                          value={Number(
                            transactionsSummaryData?.UsersCount?.EmployeeCount |
                              0
                          )}
                        />
                      </Text>
                      <Text className="transactions-summary__p4__s2__text2">
                        {intl.formatMessage({
                          id: "2863",
                          defaultMessage: "The number of salaried customers",
                        })}
                      </Text>
                      <Icon
                        style={{ fontSize: 64 }}
                        component={SalaryIconSVG}
                        className="transactions-summary__p4__s2__icon"
                      />
                    </Space>
                  </Col>

                  <Col span={8}>
                    <Space
                      size={16}
                      align="start"
                      direction="vertical"
                      className="transactions-summary__p4__s3"
                    >
                      <Text className="transactions-summary__p4__s3__text1">
                        <FormattedNumber
                          value={Number(
                            transactionsSummaryData?.UsersCount?.BankCount | 0
                          )}
                        />
                      </Text>
                      <Text className="transactions-summary__p4__s3__text2">
                        {intl.formatMessage({
                          id: "2864",
                          defaultMessage:
                            "The number of customers with bank accounts",
                        })}
                      </Text>
                      <Image
                        src={BKIconImage}
                        className="transactions-summary__p4__s3__icon1"
                      />
                      <Image
                        src={UBAIconImage}
                        className="transactions-summary__p4__s3__icon2"
                      />
                      <Image
                        src={StandardBankIconImage}
                        className="transactions-summary__p4__s3__icon3"
                      />
                    </Space>
                  </Col>

                  <Col span={8} offset={6}>
                    <Space
                      size={16}
                      align="start"
                      direction="vertical"
                      className="transactions-summary__p4__s4"
                    >
                      <Text className="transactions-summary__p4__s4__text1">
                        <FormattedNumber
                          value={Number(
                            transactionsSummaryData?.UsersCount
                              ?.NoProfessionNotSpecifiedCount | 0
                          )}
                        />
                      </Text>
                      <Text className="transactions-summary__p4__s4__text2">
                        {intl.formatMessage({
                          id: "2865",
                          defaultMessage:
                            "The number of customers without a declared profession",
                        })}
                      </Text>
                      <Icon
                        style={{ fontSize: 64 }}
                        component={UnemploymentIconSVG}
                        className="transactions-summary__p4__s4__icon"
                      />
                    </Space>
                  </Col>

                  <Col span={8}>
                    <Space
                      size={16}
                      align="start"
                      direction="vertical"
                      className="transactions-summary__p4__s5"
                    >
                      <Text className="transactions-summary__p4__s5__text1">
                        <FormattedNumber
                          value={Number(
                            transactionsSummaryData?.UsersCount?.StudentCount |
                              0
                          )}
                        />
                      </Text>
                      <Text className="transactions-summary__p4__s5__text2">
                        {intl.formatMessage({
                          id: "2866",
                          defaultMessage:
                            "The number of customers who are students",
                        })}
                      </Text>
                      <Image
                        src={StudentIconImage}
                        className="transactions-summary__p4__s5__icon"
                      />
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 24 }}>
              <Col span={10}>
                <Space direction="vertical">
                  <Text className="transactions-summary__heading">
                    {REACT_APP_NAME === "M2U Money" ? "M2U " : "2U "}
                    {intl.formatMessage({
                      id: "2867",
                      defaultMessage: "Payment Acceptance",
                    })}
                  </Text>
                  <Text className="transactions-summary__description">
                    {intl.formatMessage({
                      id: "2868",
                      defaultMessage: `Find out where our platform users can pay for services and products. Day after day we are partnering with merchants to allow everyone to be able to pay using their preferred method.`,
                    })}
                  </Text>
                </Space>
              </Col>

              <Col span={14}>
                <Row>
                  <Col span={24}>
                    <Row align="middle" gutter={24} style={{ marginTop: 32 }}>
                      <Col>
                        <Image
                          src={
                            REACT_APP_NAME === "M2U Money"
                              ? AcceptedHereSignImage
                              : AcceptedHereSignImage2u
                          }
                        />
                      </Col>
                      <Col>
                        <Space
                          size={4}
                          direction="vertical"
                          className="transactions-summary__p5__s1"
                        >
                          <Text className="transactions-summary__p5__s1__text1">
                            <span>
                              <FormattedNumber
                                value={Number(
                                  transactionsSummaryData?.Store?.TotalStore | 0
                                )}
                              />{" "}
                              {intl.formatMessage({
                                id: "2868",
                                defaultMessage: "Merchants",
                              })}
                            </span>
                            <span className="background"></span>
                          </Text>
                          <Text className="transactions-summary__p5__s1__text2">
                            {intl.formatMessage({
                              id: "2870",
                              defaultMessage: `and growing accept payments using our platform`,
                            })}
                          </Text>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                {/* merchants graph */}
                <GraphLineComponent
                  width={976}
                  dataSource={merchantsGraphData}
                  title={"M2U merchants growth (2020 - 2021)"}
                />
              </Col>

              <Col span={24} className="transactions-summary__p5__s2">
                <Text className="transactions-summary__p5__s2__text1">
                  {/* // TODO: the diversify icon, already downloaded */}
                  <span>
                    {intl.formatMessage({
                      id: "2871",
                      defaultMessage:
                        "Our Merchants are diversified across all categories",
                    })}
                  </span>
                  <span className="background"></span>
                </Text>
              </Col>

              <Col span={24} className="transactions-summary__p5__s3">
                <Row>
                  <Col>
                    <div className="transactions-summary__menu">
                      {transactionsSummaryData?.Store?.Categories.filter(
                        (category: any) => Number(category.CatCount) > 0
                      ).map((category: any) => (
                        <Tag
                          key={category.CatCode}
                        >{`${category.CatName}(${category.CatCount})`}</Tag>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col span={24} className="transactions-summary__p5__s4">
                <Row justify="center" align="middle">
                  <Col>
                    <Space size={24} align="center">
                      <Icon
                        component={StoreFrontIconSVG}
                        style={{ fontSize: 96 }}
                      />
                      <div style={{ maxWidth: 448 }}>
                        <Text className="transactions-summary__p5__s4__text1">
                          {intl.formatMessage({
                            id: "2872",
                            defaultMessage:
                              "Our customers can add money to their wallets, send and receive cash at",
                          })}
                        </Text>{" "}
                        <Text className="transactions-summary__p5__s4__text2">
                          <span className="text">
                            <FormattedNumber
                              value={Number(
                                transactionsSummaryData?.CashPoints
                                  ?.TotalCashPoints | 0
                              )}
                            />{" "}
                            {intl.formatMessage({
                              id: "1278",
                              defaultMessage: "Cashpoints",
                            })}
                          </span>
                          <span className="background"></span>
                        </Text>
                      </div>
                    </Space>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="center" align="middle">
                  <Col span={14} style={{ marginTop: 24 }}>
                    <GraphLineComponent
                      height={424}
                      dataSource={cashpointsGraphData}
                      title={"M2U cashpoints growth (2020 - 2021)"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Spin>
      </PageAccessControl>
    </PageHOCComponent>
  );
};

export default TransactionsSummary;
