import { Button, Checkbox, Modal, ModalProps, Row, Space } from "antd";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

import {
  CashPickup,
  CancelTrans,
  WalletTopUp,
  SendCash,
  Redeem,
  WalletToWallet,
  ToPartner,
  CashDeposit,
  MicroLoan,
  BankToWallet,
  WalletToBank,
  ATM,
  PhoneTopUp,
  Payment,
  InvoicePostPay,
  InvoicePrePay,
  ToPrepaidCard,
  ToMNO,
  BulkPay,
} from "constants/transactionOptions";
import { TransactionsFilters } from "components/TransactionsTable/utils";
import getTransactionTypeText from "utils/getTransactionTypeText";

const transactionsTypeOptions = [
  ATM,
  BulkPay,
  CashPickup,
  CancelTrans,
  WalletTopUp,
  SendCash,
  WalletToWallet,
  ToPartner,
  CashDeposit,
  MicroLoan,
  BankToWallet,
  WalletToBank,
  PhoneTopUp,
  Payment,
  InvoicePostPay,
  InvoicePrePay,
  ToPrepaidCard,
  ToMNO,
  Redeem,
].map((t) => ({ label: getTransactionTypeText(t), value: t }));

interface TransactionFiltersProps {
  onSaveFilters: (v: TransactionsFilters) => void;
  onCancel: () => void;
}
export const TransactionFilters = (props: TransactionFiltersProps) => {
  const intl = useIntl();
  const [checkedList, setCheckedList]: any = useState<CheckboxValueType[]>();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(
      !!list.length && list.length < transactionsTypeOptions.length
    );
    setCheckAll(list.length === transactionsTypeOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(
      e.target.checked ? transactionsTypeOptions?.map((item) => item.value) : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <Row
      wrap={false}
      justify="space-between"
      style={{ flexDirection: "column", height: "100%" }}
    >
      <div style={{ paddingTop: 24, marginBottom: 24 }}>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {intl.formatMessage({
            id: "2782",
            defaultMessage: "All",
          })}
        </Checkbox>

        <Checkbox.Group
          options={transactionsTypeOptions}
          value={checkedList}
          onChange={onChange}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        />
      </div>

      <div style={{ paddingTop: 24, marginBottom: 24 }}>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              props.onSaveFilters({ TransactionType: checkedList });
              props.onCancel();
            }}
          >
            {intl.formatMessage({ id: "dd.new", defaultMessage: "Save" })}
          </Button>
          <Button type="text" onClick={props.onCancel}>
            {intl.formatMessage({ id: "dd.new", defaultMessage: "Cancel" })}
          </Button>
        </Space>
      </div>
    </Row>
  );
};

interface Props extends Omit<ModalProps, "onCancel">, TransactionFiltersProps {}

export const TransactionsFiltersModal = (props: Props) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: "4847",
        defaultMessage: "Transactions filters",
      })}
      width={328}
      {...props}
      footer={null}
      closable={false}
      maskClosable={false}
      wrapClassName="drawer-modal"
    >
      <TransactionFilters
        onCancel={props.onCancel}
        onSaveFilters={props.onSaveFilters}
      />
    </Modal>
  );
};
