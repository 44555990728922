import FinancesInitialStateInterface from 'types/finances/financialInitialState';
import {
  UPDATE_PARTNER_START,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_FAILURE,
  RESTORE_UPDATE_PARTNER,
} from 'redux/actionTypes/finances/partnerTypes';

const updatePartnerReducer = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case UPDATE_PARTNER_START:
      return {
        ...state,
        updatePartner: {
          ...state.updatePartner,
          loading: true,
          error: null,
        },
      };
    case UPDATE_PARTNER_FAILURE:
      return {
        ...state,
        updatePartner: {
          ...state.updatePartner,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case UPDATE_PARTNER_SUCCESS:
      return {
        ...state,
        updatePartner: {
          ...state.updatePartner,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    case RESTORE_UPDATE_PARTNER:
      return {
        ...state,
        updatePartner: {
          ...state.updatePartner,
          error: null,
          loading: false,
          success: false,
        },
      };
    default:
      return null;
  }
};

export default updatePartnerReducer;
