import FinancesInitialStateInterface from 'types/finances/financialInitialState';
import {
  ADD_PARTNER_START,
  ADD_PARTNER_SUCCESS,
  ADD_PARTNER_FAILURE,
  RESTORE_ADD_NEW_PARTNER,
} from 'redux/actionTypes/finances/partnerTypes';

const newPartnerReducer = (
  state: FinancesInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case ADD_PARTNER_START:
      return {
        ...state,
        newPartner: {
          ...state.newPartner,
          loading: true,
          error: null,
        },
      };
    case ADD_PARTNER_FAILURE:
      return {
        ...state,
        newPartner: {
          ...state.newPartner,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case RESTORE_ADD_NEW_PARTNER:
      return {
        ...state,
        newPartner: {
          ...state.newPartner,
          error: null,
          loading: false,
          success: false,
        },
      };
    case ADD_PARTNER_SUCCESS:
      return {
        ...state,
        newPartner: {
          ...state.newPartner,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
        partners: {
          ...state.partners,
          data: [
            ...state.partners.data,
            { ...payload, Status: '1', TwoWayPartner: 'NO' },
          ],
        },
      };
    default:
      return null;
  }
};

export default newPartnerReducer;
