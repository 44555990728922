
import { 
    GET_VARIOUS_LIST_START, 
    GET_VARIOUS_LIST_SUCCESS, 
    GET_VARIOUS_LIST_FAILURE } 
from 'redux/actionTypes/operators';
import { variousList } from 'types/operator';

  
  const variousLists = (
    state: variousList,
    { type, payload }: any
  ) => {
    switch (type) {
      case GET_VARIOUS_LIST_START:
        return {
          ...state,
          variousList: {
            data: null,
            loading: true,
            error: null,
          },
        };
      case GET_VARIOUS_LIST_SUCCESS: {
        return {
          ...state,
          variousList: {
            data: payload,
            loading: false,
            error: null,
          },
        };
      }
      case GET_VARIOUS_LIST_FAILURE:
        return {
          ...state,
          variousList: {
            error: payload,
            loading: false,
          },
        };
      default:
        return null;
    }
  };
  
  export default variousLists;
  