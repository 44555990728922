import {
  SAVE_USER_DATA_START,
  SAVE_USER_DATA_SUCCESS,
  SAVE_USER_DATA_FAILURE,
} from "redux/actionTypes/customers/saveUserDataActionTypes";
import CustomersInitialStateInterface from "types/Customer/CustomerInitialState";

const saveUserData = (
  state: CustomersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case SAVE_USER_DATA_START: {
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          saveUserData: {
            message: null,
            loading: true,
            error: null,
          },
        },
      };
    }
    case SAVE_USER_DATA_SUCCESS: {
      let { selectedCustomer } = state;

      return {
        ...state,
        selectedCustomer: {
          ...selectedCustomer,
          data: {
            ...selectedCustomer.data,
            ...payload.data,
            ...payload.requestData,
          },
          saveUserData: {
            message: payload.message,
            loading: false,
            error: null,
            success: true,
          },
        },
      };
    }

    case SAVE_USER_DATA_FAILURE:
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          saveUserData: {
            ...state.selectedCustomer.saveUserData,
            error: payload,
            loading: false,
            success: false,
          },
        },
      };
  }
};
export default saveUserData;
