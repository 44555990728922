import { EventsInitialStateInterface } from 'types/initialState';
import {
  CREATE_UPDATE_EVENT_START,
  CREATE_UPDATE_EVENT_SUCCESS,
  CREATE_UPDATE_EVENT_FAILURE,
} from '../../actionTypes/events/updateCreate';

const updateCreateEventReducer = (
  state: EventsInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case CREATE_UPDATE_EVENT_START:
      return {
        ...state,
        allEvents: {
          ...state.allEvents,
        },
        updateCreate: {
          error: null,
          loading: true,
        },
      };
    case CREATE_UPDATE_EVENT_SUCCESS:
      let allEventsData;
      const { message, data, isDelete } = payload;
      if (isDelete) {
        allEventsData = state.allEvents.data.filter(
          (event) => event.EventID !== data.EventID
        );
      } else {
        allEventsData = state.allEvents.data.filter(
          (event) => event.EventID !== data.EventID
        );
        allEventsData.push(data);
      }
      return {
        ...state,
        allEvents: {
          ...state.allEvents,
          data: allEventsData,
        },
        updateCreate: {
          error: null,
          loading: false,
          message: message,
        },
      };
    case CREATE_UPDATE_EVENT_FAILURE:
      return {
        ...state,
        allEvents: {
          ...state.allEvents,
        },
        updateCreate: {
          error: payload,
          loading: false,
        },
      };
  }
};

export default updateCreateEventReducer;
