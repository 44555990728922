import {
  GET_TRANSACTIONS_SUMMARY_START,
  GET_TRANSACTIONS_SUMMARY_SUCCESS,
  GET_TRANSACTIONS_SUMMARY_FAILURE,
} from 'redux/actionTypes/transactionReports';
import TransactionsReportInterface from 'types/transactionsReport';

const transactionsSummaryReducer = (
  state: TransactionsReportInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_TRANSACTIONS_SUMMARY_START:
      return {
        ...state,
        transactionsSummary: {
          data: [],
          loading: true,
          error: null,
        },
      };
    case GET_TRANSACTIONS_SUMMARY_SUCCESS: {
      delete payload.OK;
      delete payload.Result;
      delete payload.DataFound;
      return {
        ...state,
        transactionsSummary: {
          data: payload,
          loading: false,
          error: null,
        },
      };
    }
    case GET_TRANSACTIONS_SUMMARY_FAILURE:
      return {
        ...state,
        transactionsSummary: {
          ...state.transactionsSummary,
          error: payload,
          loading: false,
        },
      };
    default:
      return null;
  }
};

export default transactionsSummaryReducer;
