// GET OPERATOR LIST
export const GET_OPERATORS_START = 'GET_OPERATORS_START';
export const GET_OPERATORS_END = 'GET_OPERATORS_END';
export const GET_OPERATORS_SUCCESS = 'GET_OPERATORS_SUCCESS';
export const GET_OPERATORS_FAILURE = 'GET_OPERATORS_FAILURE';

// CREATE OPERATOR
export const ADD_UPDATE_OPERATOR_START = 'ADD_UPDATE_OPERATOR_START';
export const ADD_UPDATE_OPERATOR_END = 'ADD_UPDATE_OPERATOR_END';
export const ADD_UPDATE_OPERATOR_SUCCESS = 'ADD_UPDATE_OPERATOR_SUCCESS';
export const ADD_UPDATE_OPERATOR_FAILURE = 'ADD_UPDATE_OPERATOR_FAILURE';


// GET VARIOUS LISTS
export const GET_VARIOUS_LIST_START = 'GET_VARIOUS_LIST_START';
export const GET_VARIOUS_LIST_END = 'GET_VARIOUS_LIST_END';
export const GET_VARIOUS_LIST_SUCCESS = 'GET_VARIOUS_LIST_SUCCESS';
export const GET_VARIOUS_LIST_FAILURE = 'GET_VARIOUS_LIST_FAILURE';
