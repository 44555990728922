import { GlobalSettingsInitialInterface } from 'types/initialState';
import {
  GET_BUILTIN_CONFIG_DATA_START,
  GET_BUILTIN_CONFIG_DATA_FAILURE,
  GET_BUILTIN_CONFIG_DATA_SUCCESS,
} from 'redux/actionTypes/globalSettings/getBuiltinConfigData';

const getGlobalSettingsReducer = (
  state: GlobalSettingsInitialInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_BUILTIN_CONFIG_DATA_START:
      return {
        ...state,
        globalBuiltinConfigData: {
          ...state.globalBuiltinConfigData,
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_BUILTIN_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        globalBuiltinConfigData: {
          ...state.globalBuiltinConfigData,
          loading: false,
          data: payload,
          error: null,
        },
      };

    case GET_BUILTIN_CONFIG_DATA_FAILURE:
      return {
        ...state,
        globalBuiltinConfigData: {
          ...state.globalBuiltinConfigData,
          loading: false,
          error: payload,
          data: [],
        },
      };

    default:
      return null;
  }
};

export default getGlobalSettingsReducer;
