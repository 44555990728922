import createBulkOrganizationReducer from "./createBulkOrganization";
import bulkOrganizationListReducer from "./organizationList";

const bulkOrganizationReducer = (
    state: any,
    action: any
  ) => {
    return {
      ...state,
      ...bulkOrganizationListReducer(state, action),
      ...createBulkOrganizationReducer(state, action)
    };
  };
  
  export default bulkOrganizationReducer;