import { Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import FormattedNumber from "components/FormattedNumber";

const { Text } = Typography;

const GraphBarComponent: React.FC<{
  direction?: "vertical" | "horizontal";
  label: string;
  value: number;
  percent: number;
  color?: string;
}> = ({ direction = "vertical", label, value, percent, color = "#F35627" }) => {
  const [width, setWidth] = useState(0);

  const horizontalStyles: React.CSSProperties = {
    height: 224,
    width: 64,
    borderColor: color,
    backgroundColor: color,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 32,
    overflow: "hidden",
  };

  useEffect(() => {
    if (percent) {
      if (percent && percent < 0.5) {
        setWidth(224 * percent + 112);
      } else {
        setWidth(224 * percent);
      }
    }
  }, [percent]);

  return (
    <>
      <Space
        direction="vertical"
        align={direction === "horizontal" ? "center" : "start"}
        style={{
          flexFlow: direction === "horizontal" ? "column-reverse" : "column",
        }}
      >
        <Text>{label}</Text>
        <Space
          size={direction === "horizontal" ? 0 : 8}
          style={{
            flexFlow: direction === "horizontal" ? "column-reverse" : "row",
          }}
        >
          <div>
            {direction === "vertical" ? (
              <div style={{ width: 224, height: 24 }}>
                <div
                  style={{
                    backgroundColor: color,
                    height: "100%",
                    borderRadius: 2,
                    overflow: "hidden",
                    width,
                  }}
                ></div>
              </div>
            ) : (
              <div style={{ ...horizontalStyles }}>
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    overflow: "hidden",
                    height: 224 - width,
                  }}
                ></div>
              </div>
            )}
          </div>
          <Space
            style={{
              flexFlow: direction === "horizontal" ? "column" : "row",
            }}
            align={direction === "horizontal" ? "center" : "start"}
            size={direction === "horizontal" ? 0 : 8}
          >
            <Text>
              <FormattedNumber value={value} />
            </Text>
            <Text>
              {"("}
              <FormattedNumber
                value={percent}
                style="percent"
                maximumFractionDigits={2}
              />
              {")"}
            </Text>
          </Space>
        </Space>
      </Space>
    </>
  );
};

export default GraphBarComponent;
