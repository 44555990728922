export const GET_REPORT_LIST_START = 'GET_REPORT_LIST_START';
export const GET_REPORT_LIST_SUCCESS = 'GET_REPORT_LIST_SUCCESS';
export const GET_REPORT_LIST_FAILURE = 'GET_ALL_REPORTS_FAILURE';

export const REJECT_REPORT_START = 'REJECT_REPORT_START';
export const REJECT_REPORT_SUCCESS = 'REJECT_REPORT_SUCCESS';
export const REJECT_REPORT_FAILURE = 'REJECT_REPORT_FAILURE';

// PEER SERVICE ACTIONS
export const DELETE_PS_START = 'DELETE_PS_START';
export const DELETE_PS_SUCCESS = 'DELETE_PS_SUCCESS';
export const DELETE_PS_FAILURE = 'DELETE_PS_FAILURE';

export const UPDATE_REPORTED_PS_START = 'UPDATE_REPORTED_PS_START';
export const UPDATE_REPORTED_PS_SUCCESS = 'UPDATE_REPORTED_PS_SUCCESS';
export const UPDATE_REPORTED_PS_FAILURE = 'UPDATE_REPORTED_PS_FAILURE';

export const DELETE_REPORTED_PS_START = 'DELETE_REPORTED_PS_START';
export const DELETE_REPORTED_PS_SUCCESS = 'DELETE_REPORTED_PS_SUCCESS';
export const DELETE_REPORTED_PS_FAILURE = 'DELETE_REPORTED_PS_FAILURE';

// PEER SERVICE COMMENT ACTIONS
export const DELETE_COMMENT_START = 'DELETE_COMMENT_START';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';

export const UPDATE_COMMENT_START = 'UPDATE_COMMENT_START';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAILURE = 'UPDATE_PS_COMMENT_FAILURE';

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

// NEW POSTS
export const GET_NEW_POST_START = 'GET_NEW_POST_START';
export const GET_NEW_POST_SUCCESS = 'GET_NEW_POST_SUCCESS';
export const GET_NEW_POST_FAILURE = 'GET_NEW_POST_FAILURE';
export const INITIATE_NEW_POSTS = 'INITIATE_NEW_POSTS';

export const UPDATE_PS_START = 'UPDATE_PS_START';
export const UPDATE_PS_SUCCESS = 'UPDATE_PS_SUCCESS';
export const UPDATE_PS_FAILURE = 'UPDATE_PS_FAILURE';

// GET NEW COMMENTS
export const GET_NEW_COMMENT_START = 'GET_NEW_COMMENT_START';
export const GET_NEW_COMMENT_SUCCESS = 'GET_NEW_COMMENT_SUCCESS';
export const GET_NEW_COMMENT_FAILURE = 'GET_NEW_COMMENT_FAILURE';

// DELETE COMMENT

export const DELETE_NEW_COMMENT_START = 'DELETE_NEW_COMMENT_START';
export const DELETE_NEW_COMMENT_SUCCESS = 'DELETE_NEW_COMMENT_SUCCESS';
export const DELETE_NEW_COMMENT_FAILURE = 'DELETE_NEW_COMMENT_FAILURE';
