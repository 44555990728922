export const GET_TRANSACTIONS_SUMMARY_START = 'GET_TRANSACTIONS_SUMMARY_START';
export const GET_TRANSACTIONS_SUMMARY_SUCCESS =
  'GET_TRANSACTIONS_SUMMARY_SUCCESS';
export const GET_TRANSACTIONS_SUMMARY_FAILURE =
  'GET_TRANSACTIONS_SUMMARY_FAILURE';
export const GET_TRANSACTIONS_SUMMARY_END = 'GET_TRANSACTIONS_SUMMARY_END';

export const TRANSACTIONS_REPORT_SEARCH_START =
  'TRANSACTIONS_REPORT_SEARCH_START';
export const TRANSACTIONS_REPORT_SEARCH_SUCCESS =
  'TRANSACTIONS_REPORT_SEARCH_SUCCESS';
export const TRANSACTIONS_REPORT_SEARCH_FAILURE =
  'TRANSACTIONS_REPORT_SEARCH_FAILURE';
export const TRANSACTIONS_REPORT_SEARCH_END = 'TRANSACTIONS_REPORT_SEARCH_END';

export const TRANSACTIONS_FOR_EXPORT_RESET =
  'TRANSACTIONS_FOR_EXPORT_RESET';
export const TRANSACTIONS_FOR_EXPORT_START =
  'TRANSACTIONS_FOR_EXPORT_START';
export const TRANSACTIONS_FOR_EXPORT_SUCCESS =
  'TRANSACTIONS_FOR_EXPORT_SUCCESS';
export const TRANSACTIONS_FOR_EXPORT_FAILURE =
  'TRANSACTIONS_FOR_EXPORT_FAILURE';
export const TRANSACTIONS_FOR_EXPORT_END = 'TRANSACTIONS_REPORT_SEARCH_END';
