import UsersInitialStateInterface from 'types/users/userInitialState';
import {
  GET_LANGUAGE_START,
  GET_LANGUAGE_FAILURE,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_END,
} from 'redux/actionTypes/users/language';

const languageReducer = (
  state: UsersInitialStateInterface,
  { type, payload }: any
) => {
  switch (type) {
    case GET_LANGUAGE_START:
      return {
        ...state,
        language: {
          ...state.language,
          loading: true,
          error: null,
        },
      };
    case GET_LANGUAGE_FAILURE:
      return {
        ...state,
        language: {
          ...state.language,
          error: payload,
          loading: false,
        },
      };
    case GET_LANGUAGE_SUCCESS:
      return {
        ...state,
        language: {
          ...state.language,
          ...payload,
          error: null,
          loading: false,
        },
      };
    case GET_LANGUAGE_END:
      return {
        ...state,
        language: {
          ...state.language,
          loading: false,
          error: null,
        },
      };
    default:
      return null;
  }
};

export default languageReducer;
